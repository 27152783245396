import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import Leaflet from '../../../components/leaflet';
import Datepickers from '../../../components/Datepicker';
import { API_URL } from '../../../config';
//circularpro
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Card, Container, Row, Col, Dropdown } from 'react-bootstrap';

// img
import product1 from '../../../assets/images/products/1.jpg'
import product2 from '../../../assets/images/products/2.jpg'
import product13 from '../../../assets/images/products/13.jpg'
import product4 from '../../../assets/images/products/4.jpg'
import user1 from '../../../assets/images/user/1.jpg'
import user5 from '../../../assets/images/user/5.jpg'
import user2 from '../../../assets/images/user/2.jpg'
import user3 from '../../../assets/images/user/3.jpg'


const Dashbord = () => {
    const history = useHistory();

    const [dashboardDetails, setDashboardDetails] = useState({
        totalOrders: 0,
        totalEmployees: 0,
        lastFourCompletedOrders: [],
        totalRevenue: 0,
        upcomingInstallations: [],
        detailedUpcomingInstallations: [],
        customerDetailsForUpcomingInstallations: [],
    });


    const fetchDashboardDetails = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            Swal.fire({
                title: 'Session expired',
                text: 'Your session has expired. Please log in again.',
                icon: 'warning',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.removeItem('token');
                    history.push('/');
                }
            });
            return;
        }

        try {
            const response = await axios.get(`${API_URL}/api/dashboard-details`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setDashboardDetails(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                console.error("Error fetching dashboard details: ", error);
            }
        }
    };
    useEffect(() => {
        fetchDashboardDetails();
    }, []);


    const chart1 = {
        options: {

            colors: ['#1f1f7a', '#2e2eb8'],
            chart: {
                fontFamily: 'DM Sans',
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "vertical",
                    shadeIntensity: 0.5,
                    // gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                    inverseColors: false,
                    opacityFrom: .8,
                    opacityTo: .2,
                    stops: [0, 50, 100],
                    colorStops: []
                }
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            yaxis: {
                labels: {
                    offsetY: 0,
                    minWidth: 20,
                    maxWidth: 20
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    minHeight: 20,
                    maxHeight: 20
                },
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        },
        series: [{
            name: 'Incomes',
            data: [90, 105, 72, 90, 65, 109, 130]
        }, {
            name: 'Expenses',
            data: [115, 93, 75, 102, 144, 52, 71]
        }]
    }


    const chart2 = {
        options: {

            colors: ['#b3cccc', '#04237D', '#4d4dff'],
            chart: {

                toolbar: {
                    show: false,
                },
                sparkline: {
                    enabled: true,
                },
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '40%',
                    distributed: true,
                    borderRadius: 5,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },

            xaxis: {
                categories: [
                    '30 Jan',
                    '25 Feb',
                    '28 Mar',
                ],
                labels: {
                    minHeight: 20,
                    maxHeight: 20,
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        },
        series: [{
            data: [55, 42, 19, 30, 20, 65, 21, 23, 45, 60, 30, 20]
        }]

    }
    const chart3 = {
        options: {

            chart: {
                height: 330,
                type: 'donut',

            },

            labels: ["Mobile", "Electronics", "Laptop", "Others"],
            colors: ['#ffbb33', '#04237D', '#e60000', '#8080ff'],
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270,
                    donut: {
                        size: '80%',
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                color: '#BCC1C8',
                                fontSize: '18px',
                                fontFamily: 'DM Sans',
                                fontWeight: 600,
                            },
                            value: {
                                show: true,
                                fontSize: '25px',
                                fontFamily: 'DM Sans',
                                fontWeight: 700,
                                color: '#8F9FBC',
                            },
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                lineCap: 'round'
            },
            grid: {
                padding: {

                    bottom: 0,
                }
            },
            legend: {
                position: 'bottom',
                offsetY: 8,
                show: true,
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        height: 268
                    }
                }
            }]
        },
        series: [43, 58, 20, 35]
    }
    return (
        <Container fluid>
            <Row>
                <Col md="12" className="mb-4 mt-1">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <h4 className="font-weight-bold">Overview</h4>
                        <div className="form-group mb-0 vanila-daterangepicker d-flex flex-row">
                            <div className="date-icon-set">
                                <Datepickers className="vanila-datepicker" names="start" placeholder="From Date" />
                                <span className="search-link">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                </span>
                            </div>
                            <span className="flex-grow-0">
                                <span className="btn">To</span>
                            </span>
                            <div className="date-icon-set">
                                <Datepickers names="end" placeholder="To Date" />
                                <span className="search-link">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                </span>
                            </div>


                        </div>
                    </div>
                </Col>
                <Col lg="8" md="12">
                    <Row>
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <p className="mb-2 text-secondary">Total Orders</p>
                                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                <h5 className="mb-0 font-weight-bold"></h5>
                                                <h5 className="mb-0 font-weight-bold">{dashboardDetails.totalOrders}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <p className="mb-2 text-secondary">Total Employees</p>
                                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                <h5 className="mb-0 font-weight-bold">{dashboardDetails.totalEmployees}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <p className="mb-2 text-secondary">Users</p>
                                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                <h5 className="mb-0 font-weight-bold">13</h5>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <h4 className="font-weight-bold">Sales Report</h4>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div><svg width="24" height="24" viewBox="0 0 24 24" fill="primary" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="3" y="3" width="18" height="18" rx="2" fill="#3378FF" />
                                            </svg>
                                                <span>Incomes</span>
                                            </div>
                                            <div className="ml-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="3" y="3" width="18" height="18" rx="2" fill="#19b3b3" />
                                            </svg>
                                                <span>Expenses</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Chart className="custom-chart" options={chart1.options} series={chart1.series} type="area" height="265" />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col lg="4" md="8">
                    <Card className="card-block card-stretch card-height">
                        <Card.Header className="card-header-border d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Completed Orders</h4>
                            </div>
                        </Card.Header>
                        <div className="card-body-list">
                            <ul className="list-style-3 mb-0">
                                {dashboardDetails.lastFourCompletedOrders.map((order) => (
                                    <li key={order._id} className="p-3 list-item d-flex justify-content-start align-items-center">
                                        <div className="list-style-detail ml-3 mr-2">
                                            <p className="mb-0">{order.orderId}</p>
                                        </div>
                                        <div className="list-style-action d-flex justify-content-end ml-auto">
                                            <h6 className="font-weight-bold">₹{order.totals.total}</h6>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Card>

                </Col>
                <Col md="4">
                    <Row>
                        <Col md="12">
                            <Card className="bg-primary">
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <div className="fit-icon-2 text-info text-center">
                                            <div style={{ width: 75, height: 75 }}>
                                                <CircularProgressbar styles={buildStyles({ textColor: '#c7cbd3', textSize: '23px', pathColor: `#c7cbd3`, trailColor: '#fff' })} value={62} text={'62%'} />
                                            </div>
                                        </div>
                                        <div className="ml-3">
                                            <h5 className="text-white font-weight-bold">1,860 <small> /3k Target</small></h5>
                                            <small className="mb-0">Order In Period</small>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="font-weight-bold">Active Users</h6>
                                        <div className="d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                            </svg>
                                            <span className=" font-weight-bold">200</span>
                                        </div>
                                    </div>
                                    <p className="mb-0">Pages views per day</p>
                                    <Chart className="custom-chart" options={chart2.options} series={chart2.series} type="bar" height="183" />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="mb-0 pt-3 ">25 June</p>
                                        <p className="mb-0 pt-3 ">26 June</p>
                                        <p className="mb-0 pt-3 ">27 June</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col lg="8" md="6">
                    <Card className="card-block card-stretch card-height">
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Total Installations</h4>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="table-responsive">
                                <table className="table table-spacing mb-0">
                                    <thead className="table-color-heading">
                                        <tr className="text-secondary">
                                            <th>Order ID</th>
                                            <th>Organization Name</th>
                                            <th>Branch Address</th>
                                            <th>PO Mailed Date</th>
                                            <th>PO Number</th>
                                            <th>Subtotal</th>
                                            <th>Total</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dashboardDetails.customerDetailsForUpcomingInstallations.map((installation) => (
                                            <tr className="white-space-no-wrap" key={installation._id}>
                                                <td>{installation.orderId || "NA"}</td>
                                                <td>{installation.customer.organizationId ? installation.customer.organizationId.organizationName : "NA"}</td>
                                                <td>{installation.customer.organizationId && installation.customer.organizationId.branches.find(branch => branch._id === installation.customer.branch)?.branchAddress || "NA"}</td>
                                                <td>{installation.customer.poMailedDate ? new Date(installation.customer.poMailedDate).toLocaleDateString() : "NA"}</td>
                                                <td>{installation.customer.poNo || "NA"}</td>
                                                <td>{installation.totals.subtotal.toLocaleString() || "NA"}</td>
                                                <td>{installation.totals.total.toLocaleString() || "NA"}</td>
                                                <td>{installation.customer.remarks || "NA"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                            <div className="d-flex justify-content-end align-items-center border-top-table p-3">
                                <button className="btn btn-secondary btn-sm">See All</button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="4" md="6">
                    <Card>

                    </Card>
                </Col>
                <Col lg="12" md="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Order Summary</h4>
                            </div>

                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="table-color-heading">
                                        <tr className="text-secondary">
                                            <th scope="col">Company Name</th>
                                            <th scope="col">PO Number</th>
                                            <th scope="col">Branch</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Qty</th>
                                            <th scope="col">ETA</th>
                                            <th scope="col">Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dashboardDetails.customerDetailsForUpcomingInstallations.map((installation, index) => (
                                            <tr className="white-space-no-wrap" key={installation._id}>
                                                <td>{installation.customer.organizationId ? installation.customer.organizationId.organizationName : "NA"}</td>
                                                <td>{installation.customer.poNo || "NA"}</td>
                                                <td>{installation.customer.organizationId && installation.customer.organizationId.branches.length > 0 ? installation.customer.organizationId.branches[0].branchAddress : "NA"}</td>
                                                <td>{dashboardDetails.detailedUpcomingInstallations[index].productDetails.length > 0 ? dashboardDetails.detailedUpcomingInstallations[index].productDetails.map(({ productName }) => productName).join(', ') : "NA"}</td>
                                                <td>{installation.items.length > 0 ? installation.items[0].quantity : "NA"}</td>
                                                <td>{installation.customer.poMailedDate ? new Date(installation.customer.poMailedDate).toLocaleDateString() : "NA"}</td>
                                                <td>{installation.customer.remarks || "NA"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-end align-items-center border-top-table p-3">
                                    <button className="btn btn-secondary btn-sm">See All</button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Dashbord;