import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useParams, useHistory } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import Swal from 'sweetalert2';

const Customeredit = () => {
    const [customerType, setCustomerType] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [registeredOfficeAddress, setRegisteredOfficeAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [branches, setBranches] = useState([]);

    const { id } = useParams();
    const history = useHistory();
    const fetchCustomer = async () => {
        if (!id) {
            console.error('No ID provided');
            return;
        }
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/customers/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setCustomerType(response.data.customerType || '');
            setOrganizationName(response.data.organizationName || '');
            setRegisteredOfficeAddress(response.data.registeredOfficeAddress || '');
            setPhoneNumber(response.data.phoneNumber || '');
            setEmail(response.data.email || '');
            setBranches(response.data.branches || []);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire(
                    'Error!',
                    'There was an error fetching the customer.',
                    'error'
                )
            }
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Data validation and security checks go here

        const formData = new FormData();
        formData.append('customerType', customerType);
        formData.append('organizationName', organizationName);
        formData.append('registeredOfficeAddress', registeredOfficeAddress);
        formData.append('phoneNumber', phoneNumber);
        formData.append('email', email);

        branches.forEach((branch, index) => {
            for (const key in branch) {
                formData.append(`branches[${index}][${key}]`, branch[key]);
            }
        });

        const token = localStorage.getItem('token');
        try {
            await axios.put(`${API_URL}/api/customers/${id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            Swal.fire({
                title: 'Updated!',
                text: 'The customer has been updated.',
                icon: 'success'
            }).then((result) => {
                if (result.isConfirmed) {
                    history.push('/customer');
                }
            });
            //history.push('/customer');
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire(
                    'Error!',
                    'There was an error updating the customer.',
                    'error'
                )
            }
        }
    };

    useEffect(() => {
        fetchCustomer();
    }, []);


    const addBranch = () => {
        setBranches([...branches, {
            branchName: '',
            branchAddress: '',
            branchPincode: '',
            branchPhoneNumber: '',
            branchEmail: '',
            branchContactPerson: ''
        }]);
    };
    const handleBranchChange = (index, field, value) => {
        const newBranches = [...branches];
        newBranches[index] = { ...newBranches[index], [field]: value };
        setBranches(newBranches);
    };

    const handleRemoveBranch = (indexToRemove) => {
        const updatedBranches = branches.filter((_, index) => index !== indexToRemove);
        setBranches(updatedBranches);
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/customer">Customers</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Edit Customer</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/customer" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Edit Customer</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="12">
                                        <Form className="row g-3 date-icon-set-modal" onSubmit={handleSubmit}>
                                            {/* Radio buttons for Customer Type */}
                                            <div className="col-md-12 mb-3">

                                                <Form.Label>
                                                    Customer Type <span style={{ color: 'red' }}>*</span>
                                                </Form.Label>
                                                <Form.Check type="radio" label="Individual" name="customerType" id="individual" value="individual" checked={customerType === 'individual'} onChange={() => setCustomerType('individual')} />
                                                <Form.Check type="radio" label="Company" name="customerType" id="company" value="company" checked={customerType === 'company'} onChange={() => setCustomerType('company')} />

                                            </div>
                                            {/* Organization Name */}
                                            <div className="col-md-6 mb-3">

                                                <Form.Label>{customerType === 'individual' ? 'Name' : 'Organization Name'} <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control type="text" placeholder={customerType === 'individual' ? 'Enter Name' : 'Enter Organization Name'} value={organizationName} onChange={e => setOrganizationName(e.target.value)} />

                                            </div>
                                            {/* Registered Office Address */}



                                            {/* Phone Number */}
                                            <div className="col-md-6 mb-3">
                                                <Form.Group>
                                                    <Form.Label>Phone Number <span style={{ color: 'red' }}>*</span></Form.Label>
                                                    <Form.Control type="tel" placeholder="Enter Phone Number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                                                </Form.Group>
                                            </div>

                                            {/* Email */}
                                            <div className="col-md-6 mb-3">
                                                <Form.Group>
                                                    <Form.Label>Email <span style={{ color: 'red' }}>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="Enter Email" value={email} onChange={e => setEmail(e.target.value)} />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-12 mb-3">

                                                <Form.Label>{customerType === 'individual' ? 'Address' : 'Registered Office Address'} <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder={customerType === 'individual' ? 'Enter Address' : 'Enter Registered Office Address'} value={registeredOfficeAddress} onChange={e => setRegisteredOfficeAddress(e.target.value)} />

                                            </div>
                                            {customerType !== 'individual' && (
                                                <div className="col-md-12 mb-3">
                                                    <Button variant="secondary" onClick={addBranch} style={{ marginBottom: '10px' }}>
                                                        Add Branch
                                                    </Button>
                                                </div>
                                            )}
                                            {branches.map((branch, index) => (
                                                <div key={index} className="col-md-6 mb-3">
                                                    <div className="col-md-12 mb-3">
                                                        <h5>Branch {index + 1}</h5>
                                                        <Form.Group>
                                                            <Form.Label>Branch Name <span style={{ color: 'red' }}>*</span></Form.Label>
                                                            <Form.Control type="text" placeholder="Enter Branch Name" value={branch.branchName} onChange={(e) => handleBranchChange(index, 'branchName', e.target.value)} />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Branch Address <span style={{ color: 'red' }}>*</span></Form.Label>
                                                            <Form.Control type="text" placeholder="Enter Branch Address" value={branch.branchAddress} onChange={(e) => handleBranchChange(index, 'branchAddress', e.target.value)} />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-12 mb-3" >
                                                        <Form.Group>
                                                            <Form.Label>Pincode <span style={{ color: 'red' }}>*</span></Form.Label>
                                                            <Form.Control type="text" placeholder="Enter Pincode" value={branch.branchPincode} onChange={(e) => handleBranchChange(index, 'branchPincode', e.target.value)} />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-12 mb-3" >
                                                        <Form.Group>
                                                            <Form.Label>Phone Number <span style={{ color: 'red' }}>*</span></Form.Label>
                                                            <Form.Control type="tel" placeholder="Enter Phone Number" value={branch.branchPhoneNumber} onChange={(e) => handleBranchChange(index, 'branchPhoneNumber', e.target.value)} />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <Form.Group>
                                                            <Form.Label>Email <span style={{ color: 'red' }}>*</span></Form.Label>
                                                            <Form.Control type="email" placeholder="Enter Email" value={branch.branchEmail} onChange={(e) => handleBranchChange(index, 'branchEmail', e.target.value)} />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Button variant="danger" onClick={() => handleRemoveBranch(index)}>
                                                            Remove Branch
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="col-md-12 mb-3">
                                                <Button variant="primary" type="submit">
                                                    Update Customer
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Customeredit;
