import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import axios from 'axios';
import { API_URL } from '../../../config';

const DeliveryData = () => {
    const [deliveryName, setDeliveryName] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [filename, setFile] = useState(null);
    const { id, orderid } = useParams();
    const history = useHistory();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!orderid) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Order ID is required',
            });
            return;
        }
        if (!deliveryDate) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Delivery date is required',
            });
            return;
        }
        if (!filename) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'File is required',
            });
            return;
        }

        const formData = new FormData();
        formData.append('orderId', id);
        formData.append('deliveryName', orderid);
        formData.append('deliveryDate', deliveryDate);

        const file = document.getElementById('customFile').files[0];
        formData.append('filename', file);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.post(`${API_URL}/api/delivery-data`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.message === 'Delivery data added successfully') {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Delivery data added successfully!',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message,
                });
            }
        }

    };

    return (
        <Container fluid>
            <Col lg="12">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb p-0 mb-0">
                                <li className="breadcrumb-item"><Link to="/customer">Delivery Data</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Add Delivery Data</li>
                            </ol>
                        </nav>
                    </div>
                    <Link to="/delivery-list" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2">Back</span>
                    </Link>
                </div>
            </Col>
            <Col lg="12" className="mb-3 d-flex justify-content-between">
                <h4 className="font-weight-bold0 d-flex align-items-center">Add New Data</h4>
            </Col>
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="6" className="mb-3">
                                        <Form.Label className="font-weight-bold text-muted text-uppercase">Order ID</Form.Label>
                                        <Form.Group controlId="orderId">
                                            <Form.Control type="text" value={orderid} />
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" className="mb-3">
                                        <Form.Group controlId="deliveryDate">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Delivery Date</Form.Label>
                                            <Form.Control type="date" value={deliveryDate} onChange={e => setDeliveryDate(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" className="mb-3">
                                        <Form.Label htmlFor="customFile" className="font-weight-bold text-muted text-uppercase">Upload File</Form.Label>
                                        <Form.File custom className="mb-3">
                                            <Form.File.Input id="customFile" name="filename" onChange={e => setFile(e.target.files[0])} />
                                            <Form.File.Label htmlFor="customFile">Choose file</Form.File.Label>
                                        </Form.File>
                                    </Col>
                                    <Col md="12" className="mb-3">
                                        <Button variant="primary" type="submit">Submit</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default DeliveryData;
