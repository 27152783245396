import React, { useState } from 'react'
import { Container, Row, Col, Button, ListGroup } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
// img
import darklogo from '../../../assets/images/logo-dark.png'

const Invoiceview = () => {
    const data = [{
        "id": 1,
        "Name": "Gio Metric",
        "Age": "25",
        "Company": "Deepends",
        "Country": "Spain",
        "City": "Madrid",
    },

    ]
    const [Add, setAdd] = useState(data)
    const [addFormData] = useState({
        id: "",
        Name: "",
        Age: "",
        Company: "",
        Country: "",
        City: "",

    })

    const handleAddFormChanges = (event) => {
        event.preventDefault();
        const id = event.target.closest('tr').getAttribute('dataid')
        const tdElem = event.target.closest('tr').querySelectorAll('td')
        const obj = {
            id: Number(id)
        }
        Array.from(tdElem, (elem) => {
            if (elem.getAttribute('name') !== null) {
                obj[elem.getAttribute('name')] = elem.innerText
            }
            return null
        })
        const newArrIndex = Add.findIndex(item => {
            if (item.id === Number(id)) {
                return item
            } else {
                return null
            }
        })
        Add[newArrIndex] = obj
        setAdd(Add)

    };

    const handleAddFormSubmit = (event) => {
        event.preventDefault();

        let newContact = {
            id: Add.length + 1,
            Name: addFormData.Name,
            Age: addFormData.Age,
            Company: addFormData.Company,
            Country: addFormData.Country,
            City: addFormData.City
        };
        // Add.push(newContact)
        const newContacts = [...Add, newContact];
        setAdd(newContacts);

    };
    const handleDeleteClick = (addId) => {
        const newContacts = [...Add];
        const index = Add.findIndex((adds) => adds.id === addId);
        newContacts.splice(index, 1);
        setAdd(newContacts);

    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                            <h4 className="font-weight-bold">Invoice View</h4>
                            <Link to="/pages-invoice" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <div id="table" className="table-editable">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ alignSelf: 'flex-end' }}>
                                            <span className="table-add float-right mb-3 mr-2">
                                                <button onClick={handleAddFormSubmit} className="btn btn-sm bg-primary"><i
                                                    className="ri-add-fill"><span className="pl-1">Add New</span></i>
                                                </button>
                                            </span>
                                        </div>
                                        <div style={{ overflowX: 'auto' }}>
                                            <table className="table table-bordered table-responsive-md table-striped text-center">
                                                <thead>
                                                    <tr>
                                                        <th>Client/Company Name</th>
                                                        <th>PO Number</th>
                                                        <th>Company Name</th>
                                                        <th>Invoice ID</th>
                                                        <th>Date</th>
                                                        <th>Product</th>
                                                        <th>Price</th>
                                                        <th>GST</th>
                                                        <th>Total</th>
                                                        <th>Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Add.map((adds, props) => (

                                                        <tr key={props} dataid={adds.id}>

                                                            <td name="Name" contentEditable={true} suppressContentEditableWarning={true} onBlur={handleAddFormChanges}>{adds.Name}</td>
                                                            <td name="Age" contentEditable={true} suppressContentEditableWarning={true} onBlur={handleAddFormChanges}>{adds.Age}</td>
                                                            <td name="Company" contentEditable={true} suppressContentEditableWarning={true} onBlur={handleAddFormChanges}>{adds.Company}</td>
                                                            <td name="Country" contentEditable={true} suppressContentEditableWarning={true} onBlur={handleAddFormChanges}>{adds.Country}</td>
                                                            <td name="City" contentEditable={true} suppressContentEditableWarning={true} onBlur={handleAddFormChanges}>{adds.City}</td>
                                                            <td name="City" contentEditable={true} suppressContentEditableWarning={true} onBlur={handleAddFormChanges}>{adds.City}</td>
                                                            <td name="City" contentEditable={true} suppressContentEditableWarning={true} onBlur={handleAddFormChanges}>{adds.City}</td>
                                                            <td name="City" contentEditable={true} suppressContentEditableWarning={true} onBlur={handleAddFormChanges}>{adds.City}</td>
                                                            <td name="City" contentEditable={true} suppressContentEditableWarning={true} onBlur={handleAddFormChanges}>{adds.City}</td>

                                                            <td>
                                                                <span className="table-remove"><button onClick={() => handleDeleteClick(adds.id)} type="button"
                                                                    className="btn bg-danger-light btn-rounded btn-sm my-0">Remove</button></span>
                                                            </td>
                                                        </tr>

                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Invoiceview;