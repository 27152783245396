import React, { useState, useEffect } from 'react';
import { FaPlus } from "react-icons/fa6";
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import Card from '../../../components/Card';
import { API_URL } from '../../../config';
import { decode } from 'jsonwebtoken';

function CreateInstallation() {
    const [Installations, setInstallations] = useState([]);
    const [selectedInstallation, setSelectedInstallation] = useState({});
    const [status, setStatus] = useState('');
    const [remark, setRemark] = useState('');
    const [orderData, setOrderData] = useState(null);

    const { id, orderid } = useParams();
    const history = useHistory();

    const token = localStorage.getItem('token');
    let role = '';
    if (token) {
        const decodedToken = decode(token);
        role = decodedToken.role;
    }

    const fetchInstallations = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/installations`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setInstallations(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            }
        }
    };

    const handleInstallationChange = (event) => {
        const selectedId = event.target.value;
        const installation = Installations.find(inst => inst._id === selectedId);
        //console.log(installation); // Debug: Log the found installation
        setSelectedInstallation(installation || {});
    };

    const submitInstallation = async () => {
        if (!selectedInstallation || !selectedInstallation._id) {
            Swal.fire({
                icon: 'error',
                title: 'No Installation Selected',
                text: 'Please select an installation before submitting.',
                confirmButtonText: 'OK'
            });
            return;
        }
        const installationData = {
            orderId: id,
            installationId: selectedInstallation._id,
            orderNumber: orderid,
            status: status,
            remark: remark,
        };

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${API_URL}/api/create-installation`, installationData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.data.message === 'Installation created successfully' || response.data.message === 'Installation updated successfully') {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message,
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        history.push('/to-be-installed');
                    }
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            }
        }
    };

    useEffect(() => {
        fetchInstallations();
    }, []);

    const fetchOrders = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/view-order/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setOrderData(response.data);
            console.log("Order data fetched successfully:", response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            }
        }
    };
    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchInstallationData = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/view-installation/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const installationData = response.data;
            setStatus(installationData.status || '');
            setRemark(installationData.remark || '');
            if (installationData && installationData.selectedInstallationId) {
                const selectedInstallationIndex = Installations.findIndex(installation => installation._id === installationData.selectedInstallationId);
                if (selectedInstallationIndex !== -1) {
                    setSelectedInstallation(Installations[selectedInstallationIndex]);
                }
            }

            if (installationData && installationData.installationId) {
                const installationSelect = document.querySelector("select[class='form-control']");
                if (installationSelect) {
                    const options = installationSelect.options;
                    for (let i = 0; i < options.length; i++) {
                        if (options[i].value === installationData.installationId) {
                            installationSelect.selectedIndex = i;
                            break;
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching installation data: ", error);
        }
    };

    useEffect(() => {
        fetchInstallationData();
    }, [id]);



    useEffect(() => {
        if (Installations.length > 0) {
            // Set to first installation or a specific one based on some logic
            setSelectedInstallation(Installations[0]);
        }
    }, [Installations]);
    return (
        <Container fluid>
            <Row>
                <Col lg="12">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb p-0 mb-0">
                                    <li className="breadcrumb-item"><a href="#">Installation</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Create Installation Order</li>
                                </ol>
                            </nav>
                        </div>
                        {role === 'admin' ? (
                            <a href="/order" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </a>
                        ) : (
                            <a href="/employee-dashboard" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </a>
                        )}
                    </div>
                </Col>

                <Col lg="12" className="mb-3 d-flex justify-content-between">
                    <h4 className="font-weight-bold0 d-flex align-items-center">Order Details for {orderid}</h4>
                </Col>
                <Col lg="12">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md="12" className="mb-3">
                                    <Form className="row g-3" >
                                        <Col md="6" className="mb-3">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Company Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter company name" value={orderData ? orderData.customerData.organizationName : ''} readOnly />

                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Branch</Form.Label>
                                            <Form.Control type="text" placeholder="Enter branch" value={orderData ? (orderData.customer.branch === 'NA' ? 'NA' : (orderData.customerData.branches.find(branch => branch._id === orderData.customer.branch) || { branchAddress: orderData.customer.branch }).branchAddress || 'Branch not found') : ''} readOnly />
                                        </Col>
                                        <Col md="12" className="mb-3">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Delivery Address</Form.Label>
                                            <Form.Control as="textarea" rows={3} placeholder="Enter delivery address" value={orderData ? orderData.customer.deliveryAddress : ''} readOnly />
                                        </Col>

                                        <Col md="6" className="mb-3">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">PO No</Form.Label>
                                            <Form.Control type="text" placeholder="Enter PO No" value={orderData ? orderData.customer.poNo : ''} readOnly />
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">PO Date</Form.Label>
                                            <Form.Control type="text" placeholder="Enter PO Date" value={orderData ? orderData.customer.poDate.split('T')[0] : ''} readOnly />
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Mail Date</Form.Label>
                                            <Form.Control type="text" placeholder="Enter PO Mail Date" value={orderData ? orderData.customer.mailDate.split('T')[0] : ''} readOnly />
                                        </Col>


                                        <Col md="6" className="mb-3">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Reference Number</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Reference Number" value={orderData ? orderData.customer.referenceNumber : ''} readOnly />
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Username</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Username" value={orderData ? orderData.customer.username : ''} readOnly />
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Contact Person</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Contact Person's Name" value={orderData ? orderData.customer.contactPerson : ''} readOnly />
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                            <Form.Control type="text" placeholder="Enter phone number" value={orderData ? orderData.customerData.phoneNumber : ''} readOnly />
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Remarks</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Remarks" value={orderData ? orderData.customer.remarks : ''} readOnly />
                                        </Col>
                                        <Col md="6" className="mb-3 d-flex justify-content-between">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase mb-6">Need Installation?</Form.Label>
                                            <div className="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline">
                                                <div className="custom-switch-inner">
                                                    <Form.Check.Input bsPrefix="custom-control-input bg-success" id="customSwitch-22" checked={orderData ? orderData.customer.needInstallation : false} readOnly />
                                                    <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch-22" data-on-label="Yes" data-off-label="No"></Form.Check.Label>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md="6" className="mb-3 d-flex justify-content-between">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Need Delivery?</Form.Label>
                                            <div className="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline">
                                                <div className="custom-switch-inner">
                                                    <Form.Check.Input bsPrefix="custom-control-input bg-success" id="customSwitch-23" checked={orderData ? orderData.customer.needDelivery : false} readOnly />
                                                    <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch-23" data-on-label="Yes" data-off-label="No"></Form.Check.Label>
                                                </div>
                                            </div>
                                        </Col>

                                    </Form>

                                    <div className='wrapper clearfix 2'>
                                        <div className="wizard-content-item text-center mb-4">
                                            <h4>Ordered Items</h4>
                                        </div>
                                        <table className="wojo small segment responsive table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>SL No</th>
                                                    <th>Product Name</th>
                                                    <th>Quantity</th>
                                                    <th>Stock Status</th>
                                                    <th>Tax Rate</th>
                                                    <th>Item Cost</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody id='ivTable'>
                                                {orderData && orderData.items && orderData.items.map((data, i) => {
                                                    return (
                                                        <tr key={i} data-id={i + 1} className={i % 2 === 0 ? "table-active" : "table-success"}>
                                                            <td style={{ padding: '23px 10px', fontStyle: 'italic', fontWeight: '600' }}>
                                                                {i + 1}
                                                            </td>
                                                            <td>
                                                                {data.productData && data.productData.productName}
                                                            </td>
                                                            <td>
                                                                {data.quantity}
                                                            </td>
                                                            <td>
                                                                <span className={`badge ${data.productData && data.productData.stockStatus === 'In Stock' ? 'bg-success' : 'bg-danger'}`}>
                                                                    {data.productData && data.productData.stockStatus}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {data.productData && `${data.productData.taxRate}%`}
                                                            </td>
                                                            <td>
                                                                {data.price}
                                                            </td>
                                                            <td>
                                                                {data.total}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr>
                                                    <td colSpan="6" style={{ textAlign: "right", fontWeight: "bold" }}>Sub Total</td>
                                                    <td>{orderData ? orderData.totals.subtotal : 0}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="6" style={{ textAlign: "right", fontWeight: "bold" }}>Taxes</td>
                                                    <td>{orderData ? orderData.totals.taxes : 0}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="6" style={{ textAlign: "right", fontWeight: "bold" }}>Total Amount</td>
                                                    <td className='wojo demi text positive'>{orderData ? orderData.totals.total : 0}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="12" className="mb-3 d-flex justify-content-between">
                    <h4 className="font-weight-bold0 d-flex align-items-center">Create New Installation Order</h4>
                </Col>
                <Col lg="12">
                    <div className="card">
                        <div className="card-body">
                            <Row>
                                <Col lg="12">
                                    <form className="row g-3">
                                        <Col md="6" className="mb-3">
                                            <label className="font-weight-bold text-muted text-uppercase">Installation Name</label>
                                            <select className="form-control" value={selectedInstallation._id || ''} onChange={handleInstallationChange}>
                                                <option value="">Select Installation Candidate</option>
                                                {Installations.map((installation, index) => (
                                                    <option key={index} value={installation._id}>{installation.vendorName}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <label className="font-weight-bold text-muted text-uppercase">Order Number</label>
                                            <input type="text" className="form-control" placeholder="Enter Order Number" value={orderid} readOnly />
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <label className="font-weight-bold text-muted text-uppercase">Address</label>
                                            <input type="text" className="form-control" placeholder="Enter Address" value={selectedInstallation.vendorAddress || ''} readOnly />
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <label className="font-weight-bold text-muted text-uppercase">Phone</label>
                                            <input type="text" className="form-control" placeholder="Enter Phone Number" value={selectedInstallation.vendorPhoneNumber || ''} readOnly />
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <label className="font-weight-bold text-muted text-uppercase">Mail Id</label>
                                            <div className="input-group mb-3">
                                                <input type="email" className="form-control" placeholder="Enter Mail Id" value={selectedInstallation.vendorEmailId || ''} readOnly />
                                            </div>
                                        </Col>
                                        <Col md="12" className="mb-3">
                                            <label className="font-weight-bold text-muted text-uppercase">Remark</label>
                                            <textarea className="form-control" placeholder="Enter Remark" value={remark} onChange={(e) => setRemark(e.target.value)}></textarea>
                                        </Col>
                                    </form>
                                </Col>
                                <div className="d-flex justify-content-start mt-1">
                                    <button className="btn btn-primary" onClick={submitInstallation}>
                                        Create Installation
                                    </button>
                                </div>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default CreateInstallation;
