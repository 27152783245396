import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { decode } from 'jsonwebtoken';

const PrivateRoute = ({ component: Component, roles = [], ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                const token = localStorage.getItem('token');
                if (!token) {
                    return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
                }

                let decodedToken;
                try {
                    decodedToken = decode(token);
                } catch (error) {
                    // If token is expired or invalid, redirect to login
                    return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
                }

                if (!roles.includes(decodedToken.role) && decodedToken.role !== 'admin') {
                    return <Redirect to="/unauthorized" />;
                }

                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;