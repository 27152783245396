import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';
//datepicker
import Datepickers from '../../../components/Datepicker';
import axios from 'axios';
import { API_URL } from '../../../config';




const DeliveryAdd = () => {
    const [deliveryName, setDeliveryName] = useState('');
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [deliveryPincode, setDeliveryPincode] = useState('');
    const [deliveryCity, setDeliveryCity] = useState('');
    const [deliveryState, setDeliveryState] = useState('');
    const [deliveryPhoneNumber, setDeliveryPhoneNumber] = useState('');
    const [deliveryEmailId, setDeliveryEmailId] = useState('');
    const [deliveryWhatsappNo, setDeliveryWhatsappNo] = useState('');
    const history = useHistory();

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Data validation
        if (!deliveryName || !deliveryEmailId || !deliveryPhoneNumber || !deliveryAddress || !deliveryPincode || !deliveryCity || !deliveryState || !deliveryWhatsappNo) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All fields are required',
            });
            return;
        }

        if (!/^\d{10}$/.test(deliveryPhoneNumber) || !/^\d{10}$/.test(deliveryWhatsappNo)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Contact person number should be a 10 digit number',
            });
            return;
        }

        if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(deliveryEmailId)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid email format',
            });
            return;
        }

        const formData = new FormData();
        formData.append('deliveryName', deliveryName);
        formData.append('deliveryEmailId', deliveryEmailId);
        formData.append('deliveryPhoneNumber', deliveryPhoneNumber);
        formData.append('deliveryAddress', deliveryAddress);
        formData.append('deliveryPincode', deliveryPincode);
        formData.append('deliveryCity', deliveryCity);
        formData.append('deliveryState', deliveryState);
        formData.append('deliveryWhatsappNo', deliveryWhatsappNo);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.post(`${API_URL}/api/delivery-add`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.message === 'Delivery data saved successfully') {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Delivery data added successfully!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        history.push('/delivery');
                    }
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message,
                });
            }
        }
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/customer">Delivery</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Delivery Data</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/delivery" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold0 d-flex align-items-center">Add New Delivery Data</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="12">
                                        <Form className="row g-3 date-icon-set-modal" onSubmit={handleSubmit}>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Delivery/Courier Compnay Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter delivery name" value={deliveryName} onChange={e => setDeliveryName(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Delivery address</Form.Label>
                                                <Form.Control type="text" placeholder="Enter delivery address" value={deliveryAddress} onChange={e => setDeliveryAddress(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Pincode</Form.Label>
                                                <Form.Control type="text" placeholder="Enter pincode" value={deliveryPincode} onChange={e => setDeliveryPincode(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">City</Form.Label>
                                                <Form.Control type="text" placeholder="Enter city" value={deliveryCity} onChange={e => setDeliveryCity(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">State</Form.Label>
                                                <select defaultValue="" id="inputState" className="form-select form-control choicesjs" value={deliveryState} onChange={e => setDeliveryState(e.target.value)}>
                                                    <option value="">Select State</option>
                                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                    <option value="Assam">Assam</option>
                                                    <option value="Bihar">Bihar</option>
                                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                                    <option value="Goa">Goa</option>
                                                    <option value="Gujarat">Gujarat</option>
                                                    <option value="Haryana">Haryana</option>
                                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                    <option value="Jharkhand">Jharkhand</option>
                                                    <option value="Karnataka">Karnataka</option>
                                                    <option value="Kerala">Kerala</option>
                                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                    <option value="Maharashtra">Maharashtra</option>
                                                    <option value="Manipur">Manipur</option>
                                                    <option value="Meghalaya">Meghalaya</option>
                                                    <option value="Mizoram">Mizoram</option>
                                                    <option value="Nagaland">Nagaland</option>
                                                    <option value="Odisha">Odisha</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Rajasthan">Rajasthan</option>
                                                    <option value="Sikkim">Sikkim</option>
                                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                                    <option value="Telangana">Telangana</option>
                                                    <option value="Tripura">Tripura</option>
                                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                    <option value="Uttarakhand">Uttarakhand</option>
                                                    <option value="West Bengal">West Bengal</option>
                                                </select>
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Delivery phone number</Form.Label>
                                                <Form.Control type="tel" placeholder="Enter phone number" value={deliveryPhoneNumber} onChange={e => setDeliveryPhoneNumber(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Delivery email id</Form.Label>
                                                <Form.Control type="email" placeholder="Enter email" value={deliveryEmailId} onChange={e => setDeliveryEmailId(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Delivery whatsapp no</Form.Label>
                                                <Form.Control type="tel" placeholder="Enter WhatsApp number" value={deliveryWhatsappNo} onChange={e => setDeliveryWhatsappNo(e.target.value)} />
                                            </Col>
                                            <div className="d-flex justify-content-end mt-3">
                                                <Button variant="btn btn-primary" onClick={handleSubmit}>
                                                    Save
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >

        </>
    )
}

export default DeliveryAdd;