import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import Leaflet from '../../../components/leaflet';
import Datepickers from '../../../components/Datepicker';
import { API_URL } from '../../../config';
//circularpro
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Card, Container, Row, Col, Dropdown } from 'react-bootstrap';

// img



const ShortReport = () => {
    const history = useHistory();

    const [dashboardDetails, setDashboardDetails] = useState({
        totalOrders: 0,
        totalEmployees: 0,
        lastFourCompletedOrders: [],
        totalRevenue: 0,
        upcomingInstallations: [],
        detailedUpcomingInstallations: [],
        customerDetailsForUpcomingInstallations: [],
    });


    const fetchDashboardDetails = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            Swal.fire({
                title: 'Session expired',
                text: 'Your session has expired. Please log in again.',
                icon: 'warning',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.removeItem('token');
                    history.push('/');
                }
            });
            return;
        }

        try {
            const response = await axios.get(`${API_URL}/api/dashboard-details`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setDashboardDetails(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                console.error("Error fetching dashboard details: ", error);
            }
        }
    };
    useEffect(() => {
        fetchDashboardDetails();
    }, []);



    return (
        <Container fluid>
            <Row>
                <Col md="12" className="mb-4 mt-1">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <h4 className="font-weight-bold">Overview</h4>
                        <div className="form-group mb-0 vanila-daterangepicker d-flex flex-row">
                            <div className="date-icon-set">
                                <Datepickers className="vanila-datepicker" names="start" placeholder="From Date" />
                                <span className="search-link">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                </span>
                            </div>
                            <span className="flex-grow-0">
                                <span className="btn">To</span>
                            </span>
                            <div className="date-icon-set">
                                <Datepickers names="end" placeholder="To Date" />
                                <span className="search-link">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                </span>
                            </div>


                        </div>
                    </div>
                </Col>
                <Col lg="12" md="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Order Summary</h4>
                            </div>

                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="table-color-heading">
                                        <tr className="text-secondary">
                                            <th scope="col">Company Name</th>
                                            <th scope="col">PO Number</th>
                                            <th scope="col">Branch</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Stock Status</th>
                                            <th scope="col">Product Description</th>

                                            <th scope="col">Qty</th>
                                            <th scope="col">ETA</th>
                                            <th scope="col">Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dashboardDetails.customerDetailsForUpcomingInstallations && dashboardDetails.customerDetailsForUpcomingInstallations.map((installation, index) => (
                                            <tr className="white-space-no-wrap" key={installation?._id || index}>
                                                <td>{installation?.customer?.organizationId ? installation.customer.organizationId.organizationName : "NA"}</td>
                                                <td>{installation?.customer?.poNo || "NA"}</td>
                                                <td>{installation?.customer?.organizationId && installation.customer.organizationId.branches && installation.customer.organizationId.branches.length > 0 ? installation.customer.organizationId.branches[0].branchAddress : "NA"}</td>
                                                <td>{dashboardDetails.detailedUpcomingInstallations && dashboardDetails.detailedUpcomingInstallations[index]?.productDetails && dashboardDetails.detailedUpcomingInstallations[index].productDetails.length > 0 ? dashboardDetails.detailedUpcomingInstallations[index].productDetails.map(({ productName }) => productName).join(', ') : "NA"}</td>
                                                <td>
                                                    {dashboardDetails.detailedUpcomingInstallations && dashboardDetails.detailedUpcomingInstallations[index]?.productDetails && dashboardDetails.detailedUpcomingInstallations[index].productDetails.map((product, productIndex) => product.stockStatus !== "NA" && (
                                                        <span key={productIndex} className={`badge ${product.stockStatus === 'In Stock' ? 'bg-success' : 'bg-danger'}`}>
                                                            {product.stockStatus}
                                                        </span>
                                                    )).filter(Boolean).reduce((prev, curr, productIndex) => [prev, <span key={`separator-${productIndex}`}> </span>, curr], [])}
                                                </td>
                                                <td>{dashboardDetails.detailedUpcomingInstallations && dashboardDetails.detailedUpcomingInstallations[index]?.productDetails && dashboardDetails.detailedUpcomingInstallations[index].productDetails.map(({ productDescription }) => productDescription || "NA").join(', ')}</td>
                                                <td>{installation?.items && installation.items.length > 0 ? installation.items[0].quantity : "NA"}</td>
                                                <td>{installation?.customer?.poMailedDate ? new Date(installation.customer.poMailedDate).toLocaleDateString() : "NA"}</td>
                                                <td>{installation?.customer?.remarks || "NA"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default ShortReport;

