import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useParams, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';
import { API_URL } from '../../../config';
import axios from 'axios';

const Employeeedit = () => {
    const [gender, setGender] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [address, setAddress] = useState('');
    const [role, setRole] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [password, setPassword] = useState('');
    const [bio, setBio] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [level, setLevel] = useState('');
    const [filename, setFile] = useState('');

    const { id } = useParams();
    const history = useHistory();

    const fetchEmployee = async () => {
        if (!id) {
            console.error('No ID provided');
            return;
        }
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/employees/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setFirstName(response.data.firstName || '');
            setLastName(response.data.lastName || '');
            setGender(response.data.gender || '');
            setCompany(response.data.company || '');
            setEmail(response.data.email || '');
            setPhone(response.data.phone || '');
            setCountry(response.data.country || '');
            setState(response.data.state || '');
            setAddress(response.data.address || '');
            setZipcode(response.data.zipcode || '');
            setBio(response.data.bio || '');
            setFile(response.data.filename || '');
            setEmployeeId(response.data.employeeId || '');
            setRole(response.data.role || '');
            setLevel(response.data.level || '');
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire(
                    'Error!',
                    'There was an error fetching the employee.',
                    'error'
                )
            }
        }
    };

    useEffect(() => {
        fetchEmployee();
    }, [id]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('gender', gender);
        formData.append('company', company);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('country', country);
        formData.append('state', state);
        formData.append('address', address);
        formData.append('zipcode', zipcode);
        formData.append('password', password);
        formData.append('role', role);
        formData.append('employeeId', employeeId);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('level', level);
        if (filename) {
            formData.append('filename', filename);
        }

        const token = localStorage.getItem('token');

        try {
            const response = await axios.put(`${API_URL}/api/employees/${id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                Swal.fire(
                    'Updated!',
                    'Employee has been updated.',
                    'success'
                ).then((result) => {
                    if (result.isConfirmed) {
                        history.push('/employee');
                    }
                });
                //history.push('/employee');
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire(
                    'Error!',
                    'There was an error updating the employee.',
                    'error'
                )
            }
        }
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/customer">Employees</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Edit Employee</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/employee" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Edit Employee</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="3" className="mb-3">
                                        <Card.Body className="rounded bg-light">
                                            <div className="d-flex justify-content-center">
                                                <img src={`${API_URL}/uploads/${filename}`} className="img-fluid" alt="profile" />
                                            </div>
                                            <div className="d-flex justify-content-center mt-2 mb-3">


                                            </div>
                                        </Card.Body>
                                    </Col>
                                    <Col md="9">
                                        <Form className="row g-3 date-icon-set-modal" onSubmit={handleSubmit}>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="employeeId" className="font-weight-bold text-muted text-uppercase">Employee ID</Form.Label>
                                                <Form.Control type="text" id="employeeId" placeholder="Enter Employee ID" defaultValue={employeeId} onChange={e => setEmployeeId(e.target.value)} readOnly />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="firstName" className="font-weight-bold text-muted text-uppercase">First Name</Form.Label>
                                                <Form.Control type="text" id="firstName" placeholder="Enter First Name" defaultValue={firstName} onChange={e => setFirstName(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="lastName" className="font-weight-bold text-muted text-uppercase">Last Name</Form.Label>
                                                <Form.Control type="text" id="lastName" placeholder="Enter Last Name" defaultValue={lastName} onChange={e => setLastName(e.target.value)} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Gender</Form.Label><br />
                                                <div className="form-check form-check-inline">
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <Form.Control type="radio" id="inlineRadio1" name="customRadio-1" className="custom-control-input" value="Male" checked={gender === 'Male'} onChange={e => setGender(e.target.value)} />
                                                        <Form.Label className="custom-control-label" htmlFor="inlineRadio1"> Male </Form.Label>
                                                    </div>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <Form.Control type="radio" id="inlineRadio2" name="customRadio-1" className="custom-control-input" value="Female" checked={gender === 'Female'} onChange={e => setGender(e.target.value)} />
                                                        <Form.Label className="custom-control-label" htmlFor="inlineRadio2"> Female </Form.Label>
                                                    </div>
                                                </div>
                                                <div className="form-check form-check-inline mt-2">
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <Form.Control type="radio" id="inlineRadio3" name="customRadio-1" className="custom-control-input" value="Other" checked={gender === 'Other'} onChange={e => setGender(e.target.value)} />
                                                        <Form.Label className="custom-control-label" htmlFor="inlineRadio3"> Other </Form.Label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                <Form.Control type="text" id="Text4" placeholder="Enter Email" defaultValue={email} onChange={e => setEmail(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control type="text" isd="Text5" placeholder="Enter Phone" defaultValue={phone} onChange={e => setPhone(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputcountry" className="font-weight-bold text-muted text-uppercase">Country</Form.Label>
                                                <select id="inputcountry" className="form-select form-control choicesjs" value={country} onChange={e => setCountry(e.target.value)} >
                                                    <option value="" hidden disabled>Select Country</option>
                                                    <option value="India">INDIA</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputcountry" className="font-weight-bold text-muted text-uppercase">Role</Form.Label>
                                                <select id="role" className="form-select form-control choicesjs" value={role} onChange={e => setRole(e.target.value)} >
                                                    <option value="" hidden disabled>Select Role</option>
                                                    <option value="employee" >Employee</option>
                                                    <option value="accountant">Accountant</option>
                                                    <option value="delivery">Delivery</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">State/Region</Form.Label>
                                                <select id="inputState" className="form-select form-control choicesjs" value={state} onChange={e => setState(e.target.value)}>
                                                    <option value="" hidden disabled>Select State/Region</option>
                                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                    <option value="Assam">Assam</option>
                                                    <option value="Bihar">Bihar</option>
                                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                                    <option value="Goa">Goa</option>
                                                    <option value="Gujarat">Gujarat</option>
                                                    <option value="Haryana">Haryana</option>
                                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                    <option value="Jharkhand">Jharkhand</option>
                                                    <option value="Karnataka">Karnataka</option>
                                                    <option value="Kerala">Kerala</option>
                                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                    <option value="Maharashtra">Maharashtra</option>
                                                    <option value="Manipur">Manipur</option>
                                                    <option value="Meghalaya">Meghalaya</option>
                                                    <option value="Mizoram">Mizoram</option>
                                                    <option value="Nagaland">Nagaland</option>
                                                    <option value="Odisha">Odisha</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Rajasthan">Rajasthan</option>
                                                    <option value="Sikkim">Sikkim</option>
                                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                                    <option value="Telangana">Telangana</option>
                                                    <option value="Tripura">Tripura</option>
                                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                    <option value="Uttarakhand">Uttarakhand</option>
                                                    <option value="West Bengal">West Bengal</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">Zipcode</Form.Label>
                                                <Form.Control type="text" id="Text7" placeholder="Enter Zipcode" defaultValue={zipcode} onChange={e => setZipcode(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">Password</Form.Label>
                                                <Form.Control type="password" id="Text7" placeholder="Enter Password" defaultValue={password} onChange={e => setPassword(e.target.value)} />
                                            </div>


                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="level" className="font-weight-bold text-muted text-uppercase">Level</Form.Label>
                                                <select id="level" className="form-select form-control choicesjs" value={level} onChange={e => setLevel(e.target.value)}>
                                                    <option value="" hidden disabled>Select Level</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>

                                            <div className="col-md-12 mb-3">
                                                <Form.Label htmlFor="Text9" className="font-weight-bold text-muted text-uppercase">Address</Form.Label>
                                                <Form.Control as="textarea" id="Text9" rows="2" placeholder="Enter Address" defaultValue={address} onChange={e => setAddress(e.target.value)} />
                                            </div>


                                            <div className="col-md-12 mb-3">
                                                <Form.Label htmlFor="Text6" className="font-weight-bold text-muted text-uppercase">Upload Photo</Form.Label>
                                                <Form.File custom className="mb-3">
                                                    <Form.File.Input id="customFile" name="filename" onChange={e => setFile(e.target.files[0])} />
                                                    <Form.File.Label htmlFor="customFile">Choose file</Form.File.Label>
                                                </Form.File>
                                            </div>


                                            <div className="d-flex justify-content-end mt-3">
                                                <Button variant="btn btn-primary" onClick={handleSubmit}>
                                                    Update Employee
                                                </Button>
                                            </div>
                                        </Form>

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Employeeedit;
