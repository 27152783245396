//router
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LayoutsRoute from './router/layouts-route';
import SignIn from './views/backend/Auth/SignIn'
//scss files
import './assets/scss/backend.scss';
import './assets/css/custom.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route component={LayoutsRoute} />
      </Switch>
    </Router>
  );
}

export default App;
