import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';
//datepicker
import Datepickers from '../../../components/Datepicker';
import axios from 'axios';
import { API_URL } from '../../../config';




const InstallationAdd = () => {
    const [vendorName, setVendorName] = useState('');
    const [vendorAddress, setVendorAddress] = useState('');
    const [pincode, setPincode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [vendorPhoneNumber, setVendorPhoneNumber] = useState('');
    const [vendorEmailId, setVendorEmailId] = useState('');
    const [vendorCode, setVendorCode] = useState('');
    const [vendorWhatsappNo, setVendorWhatsappNo] = useState('');
    const [vendorGstNumber, setVendorGstNumber] = useState('');
    const [vendorPanNumber, setVendorPanNumber] = useState('');
    const history = useHistory();

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Data validation
        if (!vendorName || !vendorAddress || !pincode || !city || !state || !vendorPhoneNumber || !vendorEmailId || !vendorCode || !vendorWhatsappNo) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All fields are required',
            });
            return;
        }

        if (!/^\d{10}$/.test(vendorPhoneNumber) || !/^\d{10}$/.test(vendorWhatsappNo)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Contact person number should be a 10 digit number',
            });
            return;
        }

        if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(vendorEmailId)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid email format',
            });
            return;
        }

        const formData = new FormData();
        formData.append('vendorName', vendorName);
        formData.append('vendorAddress', vendorAddress);
        formData.append('pincode', pincode);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('vendorPhoneNumber', vendorPhoneNumber);
        formData.append('vendorEmailId', vendorEmailId);
        formData.append('vendorCode', vendorCode);
        formData.append('vendorWhatsappNo', vendorWhatsappNo);
        formData.append('vendorGstNumber', vendorGstNumber);
        formData.append('vendorPanNumber', vendorPanNumber);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.post(`${API_URL}/api/installation-add`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.message === 'Installation data saved successfully') {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Installation data added successfully!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        history.push('/installation');
                    }
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message,
                });
            }
        }
    };

    useEffect(() => {
        const randomNumber = Math.floor(Math.random() * 1000000); // Generates a random number up to 6 digits
        const vendorId = `VNDR${randomNumber.toString().padStart(6, '0')}`; // Ensures the string is at least 6 characters long
        setVendorCode(vendorId);
    }, []);
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/customer">Installation</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Installation Data</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/installation" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold0 d-flex align-items-center">Add New Installation Data</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>

                                    <Col md="12">
                                        <Form className="row g-3 date-icon-set-modal" onSubmit={handleSubmit}>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Generate vendor code</Form.Label>
                                                <Form.Control type="text" placeholder="Code will be generated" value={vendorCode} onChange={e => setVendorCode(e.target.value)} disabled />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Vendor name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter vendor name" value={vendorName} onChange={e => setVendorName(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Vendor address</Form.Label>
                                                <Form.Control type="text" placeholder="Enter vendor address" value={vendorAddress} onChange={e => setVendorAddress(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Pincode</Form.Label>
                                                <Form.Control type="text" placeholder="Enter pincode" value={pincode} onChange={e => setPincode(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">City</Form.Label>
                                                <Form.Control type="text" placeholder="Enter city" value={city} onChange={e => setCity(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">State</Form.Label>
                                                <select defaultValue="" id="inputState" className="form-select form-control choicesjs" value={state} onChange={e => setState(e.target.value)}>
                                                    <option value="">Select State</option>
                                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                    <option value="Assam">Assam</option>
                                                    <option value="Bihar">Bihar</option>
                                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                                    <option value="Goa">Goa</option>
                                                    <option value="Gujarat">Gujarat</option>
                                                    <option value="Haryana">Haryana</option>
                                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                    <option value="Jharkhand">Jharkhand</option>
                                                    <option value="Karnataka">Karnataka</option>
                                                    <option value="Kerala">Kerala</option>
                                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                    <option value="Maharashtra">Maharashtra</option>
                                                    <option value="Manipur">Manipur</option>
                                                    <option value="Meghalaya">Meghalaya</option>
                                                    <option value="Mizoram">Mizoram</option>
                                                    <option value="Nagaland">Nagaland</option>
                                                    <option value="Odisha">Odisha</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Rajasthan">Rajasthan</option>
                                                    <option value="Sikkim">Sikkim</option>
                                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                                    <option value="Telangana">Telangana</option>
                                                    <option value="Tripura">Tripura</option>
                                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                    <option value="Uttarakhand">Uttarakhand</option>
                                                    <option value="West Bengal">West Bengal</option>
                                                </select>
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Vendor phone number</Form.Label>
                                                <Form.Control type="tel" placeholder="Enter phone number" value={vendorPhoneNumber} onChange={e => setVendorPhoneNumber(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Vendor email id</Form.Label>
                                                <Form.Control type="email" placeholder="Enter email" value={vendorEmailId} onChange={e => setVendorEmailId(e.target.value)} />
                                            </Col>

                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Vendor whatsapp no</Form.Label>
                                                <Form.Control type="tel" placeholder="Enter WhatsApp number" value={vendorWhatsappNo} onChange={e => setVendorWhatsappNo(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Vendor GST Number</Form.Label>
                                                <Form.Control type="tel" placeholder="Enter GST number" value={vendorGstNumber} onChange={e => setVendorGstNumber(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Vendor PAN Number</Form.Label>
                                                <Form.Control type="tel" placeholder="Enter PAN number" value={vendorPanNumber} onChange={e => setVendorPanNumber(e.target.value)} />
                                            </Col>
                                            <Col md="12" className="d-flex justify-content-end mt-3">
                                                <Button variant="btn btn-primary" type="submit">
                                                    Save
                                                </Button>
                                            </Col>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >

        </>
    )
}

export default InstallationAdd;