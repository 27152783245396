import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useParams, useHistory } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import Swal from 'sweetalert2';


const Orderdetails = () => {
    const [orderDetails, setOrderDetails] = useState([]);
    const { id } = useParams();
    const history = useHistory();


    const fetchTableData = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/save-tables/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setOrderDetails(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            }
        }
    };

    useEffect(() => {
        fetchTableData();
    }, []);

    return (
        <>
            <Container fluid>
                <Row>

                    <Col lg="12" className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="font-weight-bold">{orderDetails && orderDetails.tableName} Details</h4>

                        </div>
                    </Col>
                </Row>
                <Row>

                    <Col lg="12">
                        <Card>
                            <ListGroup as="ul" className="list-group-flush">
                                <ListGroup.Item as="li" className="p-3">
                                    <h5 className="font-weight-bold">{orderDetails && orderDetails.tableName}</h5>
                                </ListGroup.Item>
                                <ListGroup.Item as="li" className="p-0">
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr className="text-muted">
                                                    {orderDetails && orderDetails.headers && orderDetails.headers.map((header, index) => (
                                                        <th scope="col" key={index}>{header}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderDetails && orderDetails.rows && orderDetails.rows.map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Orderdetails;
