import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import Swal from 'sweetalert2';

const DeliveryList = () => {

    const [Orders, setOrders] = useState([]);
    const history = useHistory();

    const fetchOrders = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/accountant-orders`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const fetchedOrders = response.data;
            // Initialize an array to hold promises from checking deliveries
            const deliveryChecks = fetchedOrders.map(order =>
                checkDeliveryCreated(order._id).then(deliveryStatus => ({
                    ...order,
                    deliveryAdded: deliveryStatus
                }))
            );
            // Wait for all delivery checks to complete
            const ordersWithDeliveryStatus = await Promise.all(deliveryChecks);
            setOrders(ordersWithDeliveryStatus);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            }
        }
    };
    useEffect(() => {
        fetchOrders();
    }, []);

    const checkDeliveryCreated = async (orderId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/check-delivery/${orderId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            // Update the order's delivery status in state based on the response
            return response.data && response.data.deliveryDetails ? 'Added' : 'Not Added';
        } catch (error) {
            console.error("Error checking delivery: ", error);
            return 'Not Added'; // Return 'Error' or handle as needed
        }
    };
    return (
        <>
            <Container fluid>
                <Row>

                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Delivery</h4>
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">
                                        <Form className="mr-3 position-relative">
                                            <div className="form-group mb-0">
                                                <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search Product" />
                                                <Link className="search-link" to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                    </svg>
                                                </Link>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Delivery List</h5>
                                            <Button variant="btn btn-secondary btn-sm">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                                </svg>
                                                Export
                                            </Button>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table data-table mb-0">
                                                <thead className="table-color-heading">
                                                    <tr className="text-light">
                                                        <th scope="col">
                                                            <label className="text-muted m-0" >Order ID</label>
                                                        </th>
                                                        <th scope="col">
                                                            <label className="text-muted m-0">Organization Name</label>
                                                        </th>
                                                        <th scope="col">
                                                            <label className="text-muted m-0">PO Number</label>
                                                        </th>
                                                        <th scope="col">
                                                            <label className="text-muted m-0">Delivery Created</label>
                                                        </th>
                                                        <th scope="col">
                                                            <span className="text-muted" >Action</span>
                                                            {/* <p className="text-muted"></p>  */}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        Orders.filter(item => item.statusInstall === 'completed' && item.statusDelivery === 'completed').map((item) => (
                                                            <tr key={item._id} className="white-space-no-wrap">

                                                                <td>
                                                                    {item.orderId}
                                                                </td>
                                                                <td>
                                                                    {item.organizationName}
                                                                </td>
                                                                <td>
                                                                    {item.customer.poNo}
                                                                </td>
                                                                <td>
                                                                    {item.deliveryAdded === 'Added' ? (
                                                                        <span className="badge badge-success">Added</span>
                                                                    ) : item.deliveryAdded === 'Not Added' ? (
                                                                        <span className="badge badge-warning">Not Added</span>
                                                                    ) : (
                                                                        <span className="badge badge-danger">Error</span>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <div className="card-header-toolbar d-flex">
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle href="#" variant="text-muted pl-3" id="dropdownMenuButton-event" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ zIndex: '1001' }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" stroke="currentColor" strokeWidth="2" aria-hidden="true" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                                                                    <g fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                                        <circle cx="12" cy="12" r="1" />
                                                                                        <circle cx="19" cy="12" r="1" />
                                                                                        <circle cx="5" cy="12" r="1" /></g>
                                                                                </svg>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu popperConfig={{ strategy: 'fixed' }}>
                                                                                <Dropdown.Item as={Link} to={`/delivery-data/${item._id}/${item.orderId}`}>
                                                                                    <svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 13h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM5 9h14a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2z" />
                                                                                    </svg>
                                                                                    Add Delivery Data
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default DeliveryList;
