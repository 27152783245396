import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import Card from '../../../components/Card';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL } from '../../../config';

const AssignBarcode = () => {
	const { id } = useParams();
	const [barcodes, setBarcodes] = useState([]);

	useEffect(() => {
		const fetchBarcodes = async () => {
			const token = localStorage.getItem('token');
			try {
				const response = await axios.get(`${API_URL}/api/barcodes/product/${id}`, {
					headers: {
						'Authorization': `Bearer ${token}`
					}
				});
				const initialBarcodes = response.data.map(barcode => ({
					...barcode,
					codes: barcode.codes.map(code => ({
						id: code._id,
						code: code.code,
						serialNumber: code.serialNumber // Assuming initial serial number is same for all codes
					}))
				}));
				setBarcodes(initialBarcodes);
			} catch (error) {
				console.error('Failed to fetch barcodes:', error);
			}
		};

		fetchBarcodes();
	}, [id]);

	const handleSerialNumberChange = (barcodeIndex, codeIndex, newSerialNumber) => {
		const cleanedSerialNumber = newSerialNumber.replace(/\n/g, '').trim();

		if (cleanedSerialNumber === "") {
			return;
		}

		const updatedBarcodes = [...barcodes];
		updatedBarcodes[barcodeIndex].codes[codeIndex].serialNumber = cleanedSerialNumber;
		setBarcodes(updatedBarcodes);
	};

	const handleSave = async () => {
		const token = localStorage.getItem('token');
		const updates = barcodes.flatMap(barcode =>
			barcode.codes.map(code => ({
				id: code.id,
				serialNumber: code.serialNumber
			}))
		);

		try {
			const response = await axios.post(`${API_URL}/api/barcodes/update/${id}`, {
				updates
			}, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			if (response.status === 200) {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: 'Barcodes updated successfully!',
				});
			}
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							window.location.reload();
						}
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							window.location.reload();
						}
					});
				}
			} else {
				console.error('Failed to save barcodes:', error);
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Failed to save barcodes!',
				});
			}
		}
	};

	return (
		<Container fluid>
			<Row>
				<Col lg="12">
					<div className="d-flex flex-wrap align-items-center justify-content-between">
						<div className="d-flex align-items-center justify-content-between">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb p-0 mb-0">
									<li className="breadcrumb-item"><Link to="/product">Products</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Assign Serial Number to Barcode</li>
								</ol>
							</nav>
						</div>
						<Link to="/product" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
							</svg>
							<span className="ml-2">Back</span>
						</Link>
					</div>
				</Col>
				<Col lg="12" className="mb-3 d-flex justify-content-between">
					<h4 className="font-weight-bold d-flex align-items-center">Assign Serial Number to Barcode</h4>
				</Col>
				<Col sm="12">
					<Card>

						<Card.Body>

							<div id="table" className="table-editable">
								<span className="table-add float-right mb-3 mr-2">
									<Button onClick={handleSave} className="btn btn-sm bg-primary">
										<i className="ri-save-line"><span className="pl-1">Save Changes</span></i>
									</Button>
								</span>
								<table className="table table-bordered table-responsive-md table-striped text-center">
									<thead>
										<tr>
											<th>Barcode</th>
											<th>Serial Number</th>
										</tr>
									</thead>
									<tbody>
										{barcodes.map((barcode, barcodeIndex) => (
											barcode.codes.map((code, codeIndex) => (
												<tr key={`${barcodeIndex}-${codeIndex}`}>
													<td>{code.code}</td>
													<td
														contentEditable={true}
														suppressContentEditableWarning={true}
														onBlur={(e) => handleSerialNumberChange(barcodeIndex, codeIndex, e.target.innerText)}
													>
														{code.serialNumber || ""}  {/* Render serial number if it exists, otherwise render an empty string */}
													</td>
												</tr>
											))
										))}
									</tbody>
								</table>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default AssignBarcode;