import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Dropdown, Pagination } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import Swal from 'sweetalert2';

const OrderList = () => {
    const [Orders, setOrders] = useState([]);
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const totalRows = Orders.length;
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    const fetchTables = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/accountant-orders`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const fetchedOrders = response.data;
            // Initialize an array to hold promises from checking invoices
            const invoiceChecks = fetchedOrders.map(order =>
                checkInvoiceCreated(order._id).then(invoiceStatus => ({
                    ...order,
                    invoiceGenerated: invoiceStatus
                }))
            );
            // Wait for all invoice checks to complete
            const ordersWithInvoiceStatus = await Promise.all(invoiceChecks);
            setOrders(ordersWithInvoiceStatus);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            }
        }
    };
    useEffect(() => {
        fetchTables();
    }, []);
    const checkInvoiceCreated = async (orderId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/check-invoice/${orderId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.status === 404 ? 'Not Generated' : 'Generated';
        } catch (error) {
            handleErrors(error);
            console.error("Error checking invoice: ", error);
            return 'Not Generated'; // Return 'Error' or handle as needed
        }
    };
    const handleErrors = (error) => {
        if (error.response && error.response.status === 403) {
            Swal.fire({
                title: error.response.data.error === 'Token expired' ? 'Session expired' : 'Invalid token',
                text: 'Please log in again.',
                icon: 'warning',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.removeItem('token');
                    history.push('/');
                }
            }).catch((error) => {
                console.error("Error during session expiration handling: ", error);
            });
        } else {
            console.error("Error fetching orders: ", error);
        }
    };
    return (
        <Container fluid>
            <Row>
                <Col lg="12">
                    <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="font-weight-bold">Completed Orders</h4>
                        </div>
                        <div className="create-workform">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="modal-product-search d-flex">
                                    <Form className="mr-3 position-relative">
                                        <Form.Group className="mb-0">
                                            <input type="text" className="form-control" id="exampleInputText" placeholder="Search Order" />
                                            <Link className="search-link" to="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                </svg>
                                            </Link>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg="12">
                    <Card className="card-block card-stretch">
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h5>Completed Orders</h5>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="table-color-heading">
                                        <tr className="text-secondary">
                                            <th scope="col">
                                                <label className="text-muted m-0" >Order ID</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Organization Name</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Status</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">PO Number</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Invoice</label>
                                            </th>
                                            <th scope="col">
                                                <span className="text-muted" >Action</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Orders.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage).map((order) => (
                                                <tr key={order._id} className="white-space-no-wrap">
                                                    <td>
                                                        {order.orderId}
                                                    </td>
                                                    <td>
                                                        {order.organizationName}
                                                    </td>
                                                    <td>
                                                        {order.statusInstall}
                                                    </td>
                                                    <td>
                                                        {order.customer.poNo}
                                                    </td>
                                                    <td>
                                                        {order.invoiceGenerated === 'Generated' ? (
                                                            <span className="badge badge-success">Generated</span>
                                                        ) : order.invoiceGenerated === 'Not Generated' ? (
                                                            <span className="badge badge-warning">Not Generated</span>
                                                        ) : (
                                                            <span className="badge badge-danger">Error</span> // Handle error or unknown status
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="card-header-toolbar d-flex">
                                                            <Dropdown >
                                                                <Dropdown.Toggle href="#" variant="text-muted pl-3" id="dropdownMenuButton-event" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ zIndex: '1001' }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" stroke="currentColor" strokeWidth="2" aria-hidden="true" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                                                        <g fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="19" cy="12" r="1" />
                                                                            <circle cx="5" cy="12" r="1" /></g>
                                                                    </svg>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} >
                                                                    <Dropdown.Item as={Link} to={`/view-details/${order._id}/${order.orderId}`}>
                                                                        <svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0zm-9 4v.01M12 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
                                                                        </svg>
                                                                        Add Invoice
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item as={Link} to={`/view-invoice/${order._id}/${order.orderId}`}>
                                                                        <svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14v-4m0 0V8a4 4 0 013.464-3.95 4 4 0 00-7.928 0A4 4 0 0112 8v2zm4 4h-1v4h-2v-4H8a4 4 0 118 0z" />
                                                                        </svg>
                                                                        View Invoice
                                                                    </Dropdown.Item>

                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-center">
                                    <Pagination className="mb-0">
                                        <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
                                        {[...Array(totalPages).keys()].map(pageNumber => (
                                            <Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === currentPage} onClick={() => setCurrentPage(pageNumber + 1)}>
                                                {pageNumber + 1}
                                            </Pagination.Item>
                                        ))}
                                        <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
                                    </Pagination>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
export default OrderList;

