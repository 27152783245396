import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Dropdown, Pagination } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import Swal from 'sweetalert2';

const CompletedDeliveries = () => {
	const [Orders, setOrders] = useState([]);
	const history = useHistory();
	const [currentPage, setCurrentPage] = useState(1);

	const rowsPerPage = 10;
	const totalRows = Orders.filter(order => order.statusDelivery === "completed").length;
	const totalPages = Math.ceil(totalRows / rowsPerPage);

	const fetchTables = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/employee-orders`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setOrders(response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			}
		}
	};
	useEffect(() => {
		fetchTables();
	}, []);
	return (
		<Container fluid>
			<Row>
				<Col lg="12">
					<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
						<div className="d-flex align-items-center justify-content-between">
							<h4 className="font-weight-bold">Completed Deliveries</h4>
						</div>
						<div className="create-workform">
							<div className="d-flex flex-wrap align-items-center justify-content-between">
								<div className="modal-product-search d-flex">
									<Form className="mr-3 position-relative">
										<Form.Group className="mb-0">
											<input type="text" className="form-control" id="exampleInputText" placeholder="Search Order" />
											<Link className="search-link" to="#">
												<svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
												</svg>
											</Link>
										</Form.Group>
									</Form>

								</div>
							</div>
						</div>
					</div>
				</Col>
				<Col lg="12">
					<Card className="card-block card-stretch">
						<Card.Header className="d-flex justify-content-between">
							<div className="header-title">
								<h5>Completed Deliveries</h5>
							</div>
						</Card.Header>
						<Card.Body className="p-0">
							<div className="table-responsive">
								<table className="table mb-0">
									<thead className="table-color-heading">
										<tr className="text-secondary">
											<th scope="col">
												<label className="text-muted m-0" >Order ID</label>
											</th>
											<th scope="col">
												<label className="text-muted m-0">Organization Name</label>
											</th>
											<th scope="col">
												<label className="text-muted m-0">Status</label>
											</th>
											<th scope="col">
												<label className="text-muted m-0">PO Number</label>
											</th>
											<th scope="col">
												<label className="text-muted m-0">Challan</label>
											</th>
											{/*  <th scope="col">
                                                <span className="text-muted" >Action</span>
                                            </th> */}
										</tr>
									</thead>
									<tbody>
										{
											Orders.filter(item => item.statusDelivery === 'completed').slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage).map((item) => (
												<tr key={item._id} className="white-space-no-wrap">

													<td>
														{item.orderId ? item.orderId : 'N/A'}
													</td>
													<td>
														{item.customerData ? item.customerData.organizationName : 'N/A'}
													</td>
													<td>
														<strong style={{ color: item.statusDelivery === 'completed' ? 'green' : 'red' }}>
															{item.statusDelivery}
														</strong>
													</td>
													<td>
														{item.customer.poNo}
													</td>
													<td>
														{item.pdfPath ?
															<span className="badge bg-success">
																<a href={`${API_URL}/${item.pdfPath}`} target="_blank" rel="noopener noreferrer" className="text-white" style={{ textDecoration: 'none' }}>View Challan</a>
															</span>
															:
															<span className="badge bg-danger">
																Not Generated
															</span>
														}
													</td>
												</tr>
											))

										}
									</tbody>
								</table>
								<div className="d-flex justify-content-center">
									<Pagination className="mb-0">
										<Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
										{[...Array(totalPages).keys()].map(pageNumber => (
											<Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === currentPage} onClick={() => setCurrentPage(pageNumber + 1)}>
												{pageNumber + 1}
											</Pagination.Item>
										))}
										<Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
									</Pagination>
								</div>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}
export default CompletedDeliveries;

