import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';
import { API_URL } from '../../../config';

const Productadd = () => {
	const [productName, setProductName] = useState('');
	const [category, setCategory] = useState('');
	const [productCode, setProductCode] = useState('');
	const [productsku, setProductSku] = useState('');
	const [manufacturer, setManufacturer] = useState('');
	const [quantity, setQuantity] = useState('');
	const [price, setPrice] = useState('');
	const [taxRate, setTaxRate] = useState('');
	const [stockStatus, setStockStatus] = useState('');
	const [productDescription, setProductDescription] = useState('');
	const [additionalInfos, setAdditionalInfos] = useState([{ purchaseDate: '', supplierDetails: '', quantityOfItems: '', amount: '' }]);
	const [filename, setFile] = useState(null);
	const history = useHistory();

	const handleAdditionalInfoChange = (index, field, value) => {
		const newAdditionalInfos = [...additionalInfos];
		newAdditionalInfos[index][field] = value;
		setAdditionalInfos(newAdditionalInfos);
	};

	const addAdditionalInfo = () => {
		setAdditionalInfos([...additionalInfos, { purchaseDate: '', supplierDetails: '', quantityOfItems: '', amount: '' }]);
	};

	const removeAdditionalInfo = (index) => {
		setAdditionalInfos(additionalInfos.filter((_, i) => i !== index));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!productName) {
			Swal.fire('Validation Error', 'Product Name is required', 'error');
			return;
		}
		if (!category) {
			Swal.fire('Validation Error', 'Category is required', 'error');
			return;
		}
		if (!productCode) {
			Swal.fire('Validation Error', 'Product Code is required', 'error');
			return;
		}
		if (!productsku) {
			Swal.fire('Validation Error', 'Product SKU is required', 'error');
			return;
		}
		if (!manufacturer) {
			Swal.fire('Validation Error', 'Manufacturer is required', 'error');
			return;
		}
		if (!quantity || isNaN(quantity)) {
			Swal.fire('Validation Error', 'Valid Quantity is required', 'error');
			return;
		}
		if (parseInt(quantity) <= 0) {
			Swal.fire('Validation Error', 'Quantity must be greater than zero', 'error');
			return;
		}
		if (!price || isNaN(price) || parseFloat(price) <= 0) {
			Swal.fire('Validation Error', 'Valid Price is required', 'error');
			return;
		}
		if (!taxRate || isNaN(taxRate) || parseFloat(taxRate) < 0) {
			Swal.fire('Validation Error', 'Valid Tax Rate is required', 'error');
			return;
		}
		if (!stockStatus) {
			Swal.fire('Validation Error', 'Stock Status is required', 'error');
			return;
		}
		if (!productDescription) {
			Swal.fire('Validation Error', 'Product Description is required', 'error');
			return;
		}

		const formData = new FormData();
		formData.append('productName', productName);
		formData.append('category', category);
		formData.append('productCode', productCode);
		formData.append('productsku', productsku);
		formData.append('manufacturer', manufacturer);
		formData.append('quantity', quantity);
		formData.append('price', price);
		formData.append('taxRate', taxRate);
		formData.append('stockStatus', stockStatus);
		formData.append('productDescription', productDescription);
		formData.append('additionalInfos', JSON.stringify(additionalInfos));
		const file = document.getElementById('customFile').files[0];
		formData.append('filename', file);

		try {
			const token = localStorage.getItem('token');
			const response = await axios.post(`${API_URL}/api/product-add`, formData, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			console.log(response.data);
			Swal.fire({
				icon: 'success',
				title: 'Success',
				text: response.data.message,
			}).then((result) => {
				if (result.isConfirmed) {
					history.push('/product');
				}
			});
		} catch (error) {
			console.error('Error uploading file', error);
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Something went wrong!',
			});
		}
	};

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item"><Link to="/product">Products</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Add Product</li>
									</ol>
								</nav>
							</div>
							<Link to="/product" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold d-flex align-items-center">New Product</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<h5 className="font-weight-bold mb-3">Basic Information</h5>
								<Form className="row g-3" onSubmit={handleSubmit}>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Product Name</Form.Label>
										<Form.Control type="text" className="form-control" id="Text1" placeholder="Enter Product Name" value={productName} onChange={e => setProductName(e.target.value)} />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="inputState" className="form-label font-weight-bold text-muted text-uppercase">Category</Form.Label>
										<select id="inputState" className="form-select form-control choicesjs" value={category} onChange={e => setCategory(e.target.value)}>
											<option value="selected">Select Category</option>
											<option value="Product">Product</option>
											<option value="Parts">Parts</option>
										</select>
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text2" className="form-label font-weight-bold text-muted text-uppercase">Product Code</Form.Label>
										<Form.Control type="text" className="form-control" id="Text2" placeholder="Enter Product Code" value={productCode} onChange={e => setProductCode(e.target.value)} />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text3" className="form-label font-weight-bold text-muted text-uppercase">Serial Number</Form.Label>
										<Form.Control type="text" className="form-control" id="Text3" placeholder="Enter Serial Number" value={productsku} onChange={e => setProductSku(e.target.value)} />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text4" className="form-label font-weight-bold text-muted text-uppercase">manufacturer</Form.Label>
										<Form.Control type="text" className="form-control" id="Text4" placeholder="Enter Manufacturer" value={manufacturer} onChange={e => setManufacturer(e.target.value)} />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text5" className="form-label font-weight-bold text-muted text-uppercase">Quantity</Form.Label>
										<Form.Control type="text" className="form-control" id="Text5" placeholder="Enter Quantity" value={quantity} onChange={e => setQuantity(e.target.value)} />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text6" className="form-label font-weight-bold text-muted text-uppercase">Price</Form.Label>
										<Form.Control type="text" className="form-control" id="Text6" placeholder="Enter Price" value={price} onChange={e => setPrice(e.target.value)} />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text7" className="form-label font-weight-bold text-muted text-uppercase">Tax Rate (%)</Form.Label>
										<Form.Control type="text" className="form-control" id="Text7" placeholder="Enter Tax Rate" value={taxRate} onChange={e => setTaxRate(e.target.value)} />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="stockStatus" className="form-label font-weight-bold text-muted text-uppercase">Stock Status</Form.Label>
										<select id="stockStatus" className="form-select form-control" value={stockStatus} onChange={e => setStockStatus(e.target.value)} required>
											<option value="">Select</option>
											<option value="In Stock">In Stock</option>
											<option value="Preorder">Preorder</option>
										</select>
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label htmlFor="Text8" className="form-label font-weight-bold text-muted text-uppercase">Product Description</Form.Label>
										<Form.Control as="textarea" rows={3} className="form-control" id="Text8" placeholder="Enter Product Description" value={productDescription} onChange={e => setProductDescription(e.target.value)} />
									</div>
									<div className="col-md-12 mb-3">
										{additionalInfos.map((info, index) => (
											<div key={index} className="mb-3">
												<Form.Label className="form-label font-weight-bold text-muted text-uppercase">Additional Information #{index + 1}</Form.Label>
												<Form.Control type="date" placeholder="Purchase Date" value={info.purchaseDate} onChange={(e) => handleAdditionalInfoChange(index, 'purchaseDate', e.target.value)} />
												<Form.Control type="text" placeholder="Supplier Details" value={info.supplierDetails} onChange={(e) => handleAdditionalInfoChange(index, 'supplierDetails', e.target.value)} />
												<Form.Control type="number" placeholder="Quantity of Items" value={info.quantityOfItems} onChange={(e) => handleAdditionalInfoChange(index, 'quantityOfItems', e.target.value)} />
												<Form.Control type="number" placeholder="Amount" value={info.amount} onChange={(e) => handleAdditionalInfoChange(index, 'amount', e.target.value)} />
												<Button variant="danger" onClick={() => removeAdditionalInfo(index)} className="mt-2">Remove</Button>
											</div>
										))}
										<Button onClick={addAdditionalInfo} className="mt-2">Add More Info</Button>
									</div>
								</Form>
							</Card.Body>
						</Card>
						<Card>
							<Card.Body>
								<h5 className="font-weight-bold mb-3">Product Image</h5>
								<Form.File custom className="mb-3">
									<Form.File.Input id="customFile" name="filename" onChange={e => setFile(e.target.files[0])} />
									<Form.File.Label htmlFor="customFile">Choose file</Form.File.Label>
								</Form.File>
								<div className="d-flex justify-content-end mt-3">
									<Button variant="btn btn-primary" onClick={handleSubmit}>
										Create Product
									</Button>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}
export default Productadd;