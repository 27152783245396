import React, { useState, useEffect } from 'react';
import { FaPlus } from "react-icons/fa6";
import { Container, Row, Col, Form, Button, InputGroup, Popover, OverlayTrigger, Modal } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import Card from '../../../components/Card';
import { API_URL } from '../../../config';
function CreateOrder() {
	const [customers, setCustomers] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState({});
	const [totals, setTotals] = useState({ subtotal: 0, taxes: 0, total: 0 });
	const [Products, setProducts] = useState([]);
	const [val, setVal] = useState([{}]);
	const [branch, setBranch] = useState('');
	const [deliveryAddress, setDeliveryAddress] = useState('');
	const [poNo, setPoNo] = useState('');
	const [poDate, setPoDate] = useState('');
	const [mailDate, setMailDate] = useState('');
	const [remarks, setRemarks] = useState('');
	const [needInstallation, setNeedInstallation] = useState(false);
	const [needDelivery, setNeedDelivery] = useState(true);

	const [phoneNumber, setPhoneNumber] = useState('');
	const [referenceNumber, setReferenceNumber] = useState('');
	const [username, setUsername] = useState('');
	const [contactPerson, setContactPerson] = useState('');

	const [showModal, setShowModal] = useState(false);
	const handleOpenModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);

	const [productName, setProductName] = useState('');
	const [category, setCategory] = useState('');
	const [productCode, setProductCode] = useState('');
	const [productsku, setProductSku] = useState('');
	const [manufacturer, setManufacturer] = useState('');
	const [quantity, setQuantity] = useState('');
	const [price, setPrice] = useState('');
	const [taxRate, setTaxRate] = useState('');
	const [stockStatus, setStockStatus] = useState('Preorder');
	const [productDescription, setProductDescription] = useState('');

	const history = useHistory();

	const handleAdd = () => {
		setVal([...val, {}]);
	}

	const handleRemove = (index) => {
		const updatedVal = val.filter((_, i) => i !== index);
		setVal(updatedVal);
	};

	const fetchCustomers = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/customers`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setCustomers(response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			} else {
				Swal.fire(
					'Error!',
					'There was an error fetching the customers.',
					'error'
				)
			}
		}
	};

	const fetchProducts = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/products`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setProducts(response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			}
		}
	};

	const handlePriceChange = (price, index) => {
		const updatedVal = [...val];
		const item = updatedVal[index];
		item.price = price;

		if (item.gst) {
			item.total = item.price * item.quantity * (1 + (item.gst / 100));
		}

		setVal(updatedVal);
		calculateTotals();
	};

	const handleCompanyChange = (event) => {
		const companyName = event.target.value;
		const companyDetails = customers.find(customer => customer.organizationName === companyName);
		//console.log("Selected company details:", companyDetails);
		setSelectedCompany(companyDetails || {});
	};

	const handleBranchChange = (e) => {
		const selectedBranchId = e.target.value;

		// Check if the selectedBranchId is valid
		if (!selectedBranchId || selectedBranchId === "Select Branch") {
			setBranch('');
			setDeliveryAddress('');
			return; // Exit the function early if no valid branch ID is selected
		}

		setBranch(selectedBranchId);

		const selectedBranch = selectedCompany.branches.find(branch => branch._id === selectedBranchId);

		console.log("Branch", selectedBranch ? selectedBranch.branchAddress : "No branch found");
		if (selectedBranch) {
			setDeliveryAddress(selectedBranch.branchAddress || '');
		} else {
			setDeliveryAddress('');
		}
	};

	const handleProductChange = (productId, index) => {
		const selectedProduct = Products.find(product => product._id === productId);
		const updatedVal = [...val];

		/* if (!selectedProduct.barcodeDetails || selectedProduct.barcodeDetails === null || selectedProduct.barcodeDetails.codes.length === 0) {
			Swal.fire({
				title: 'No Barcode Details',
				text: 'Please generate codes for this product.',
				icon: 'warning',
				confirmButtonText: 'OK'
			});
			return;
		} */

		updatedVal[index] = {
			...updatedVal[index],
			productId: productId,
			gst: selectedProduct ? selectedProduct.taxRate : '',
			stockStatus: selectedProduct ? selectedProduct.stockStatus : '',
			productDescription: selectedProduct ? selectedProduct.productDescription : '',
			//barcodeDetails: selectedProduct ? selectedProduct.barcodeDetails.codes.filter(code => !code.used) : [],
		};
		setVal(updatedVal);
	};

	const handleQuantityChange = (quantity, index) => {
		const updatedVal = [...val];
		const item = updatedVal[index];
		const numericQuantity = Math.max(0, parseInt(quantity, 10) || 0);

		const product = Products.find(p => p._id === item.productId);
		if (product) {
			// Check if product is out of stock
			if (product.quantity === 0 && product.stockStatus === 'In Stock') {
				Swal.fire({
					title: 'Out of Stock!',
					text: 'This product is currently out of stock.',
					icon: 'warning',
					confirmButtonText: 'OK'
				});
				return; // Exit the function to prevent further processing
			}
			// Check if entered quantity exceeds available quantity
			console.log(product.quantity);
			if (product.stockStatus === 'In Stock' && numericQuantity > product.quantity) {
				// Display SweetAlert message
				Swal.fire({
					title: 'Error!',
					text: `The quantity cannot exceed the available quantity of ${product.quantity}.`,
					icon: 'error',
					confirmButtonText: 'OK'
				});
				// Do not update the quantity in the item
				// Optionally, you could reset the quantity to a valid value here
				return; // Exit the function to prevent further processing
			}

			if (product.barcodeDetails && Array.isArray(product.barcodeDetails.codes)) {
				item.barcodeDetails = {
					...item.barcodeDetails, // Preserve other properties if needed
					codes: product.barcodeDetails.codes.slice(0, numericQuantity)
				};
			} else {
				item.barcodeDetails = {
					...item.barcodeDetails,
					codes: []
				};
			}
		}
		item.quantity = numericQuantity;
		if (item.price && item.gst) {
			item.total = item.price * item.quantity * (1 + (item.gst / 100));
		}

		setVal(updatedVal);
		calculateTotals();
	};



	const calculateTotals = () => {
		let subtotal = 0;
		let taxes = 0;
		val.forEach(item => {
			if (item.price && item.gst) {
				const itemTotal = item.price * item.quantity;
				const itemTaxes = itemTotal * (item.gst / 100);
				subtotal += itemTotal;
				taxes += itemTaxes;
			}
		});
		const total = subtotal + taxes;
		setTotals({ subtotal, taxes, total });
	};

	const handleSubmit = async () => {
		if (!selectedCompany || !selectedCompany._id) {
			Swal.fire({
				title: 'Error!',
				text: 'No company selected. Please select a company to proceed.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}

		if (!branch) {
			Swal.fire({
				title: 'Error!',
				text: 'Branch is required.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}


		if (!deliveryAddress) {
			Swal.fire({
				title: 'Error!',
				text: 'Delivery address is required.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}

		if (!poNo) {
			Swal.fire({
				title: 'Error!',
				text: 'Purchase Order Number is required.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}

		if (!poDate) {
			Swal.fire({
				title: 'Error!',
				text: 'Purchase Order Date is required.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}

		if (!mailDate) {
			Swal.fire({
				title: 'Error!',
				text: 'Mail date is required.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}

		if (!referenceNumber) {
			Swal.fire({
				title: 'Error!',
				text: 'Reference number is required.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}

		if (!username) {
			Swal.fire({
				title: 'Error!',
				text: 'Username is required.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}

		if (!contactPerson) {
			Swal.fire({
				title: 'Error!',
				text: 'Contact person is required.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}


		if (!phoneNumber || phoneNumber.length !== 10) {
			Swal.fire({
				title: 'Error!',
				text: 'Phone number is required and must be exactly 10 digits.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}

		if (val.some(item => !item.productId)) {
			Swal.fire({
				title: 'Error!',
				text: 'All items must have a valid product ID.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}

		if (val.some(item => item.quantity <= 0)) {
			Swal.fire({
				title: 'Error!',
				text: 'Quantity for all items must be greater than zero.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}
		const itemsWithSelectedCodes = val.map(item => ({
			...item,
			/* barcodeDetails: {
				codes: item.barcodeDetails.codes // Keep only the 'codes' array
			} */
		}));
		const token = localStorage.getItem('token');
		const customerData = {
			organizationId: selectedCompany._id, // Assuming you need the ID to identify the customer on the server
			organizationName: selectedCompany.organizationName,
			phoneNumber: phoneNumber,
			branch: branch,
			deliveryAddress: deliveryAddress,
			poNo: poNo,
			poDate: poDate,
			mailDate: mailDate,
			remarks: remarks,
			needInstallation: needInstallation,
			needDelivery: needDelivery,
			referenceNumber: referenceNumber,
			username: username,
			contactPerson: contactPerson,
		};

		const orderData = {
			customer: customerData,
			items: itemsWithSelectedCodes,
			totals: totals
		};

		try {
			const response = await axios.post(`${API_URL}/api/create-order`, orderData, {
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				}
			});
			// Handle success            
			Swal.fire(
				'Success!',
				'Order has been created successfully.',
				'success'
			).then((result) => {
				if (result.isConfirmed) {
					history.push('/order');
				}
			});
			// Optionally, redirect or clear form
		} catch (error) {
			// Handle error
			console.error("Error creating order: ", error);
			Swal.fire(
				'Error!',
				'There was an error creating the order.',
				'error'
			);
		}
	};


	useEffect(() => {
		fetchCustomers();
		fetchProducts();
	}, []);

	useEffect(() => {
		calculateTotals();
	}, [val]);

	const handleAddOutOfStockProduct = async (e) => {
		if (!productName) {
			Swal.fire('Error', 'Product Name is required', 'error');
			return;
		}

		if (!quantity || isNaN(quantity) || parseInt(quantity) <= 0) {
			Swal.fire('Error', 'Valid Quantity is required', 'error');
			return;
		}

		if (!taxRate || isNaN(taxRate) || parseInt(taxRate) <= 0) {
			Swal.fire('Error', 'Valid Tax Rate is required', 'error');
			return;
		}

		if (!stockStatus) {
			Swal.fire('Error', 'Stock Status is required', 'error');
			return;
		}
		e.preventDefault();

		const formData = new FormData();
		formData.append('productName', productName);
		formData.append('category', category);
		formData.append('productCode', productCode);
		formData.append('productsku', productsku);
		formData.append('manufacturer', manufacturer);
		formData.append('quantity', quantity);
		formData.append('price', price);
		formData.append('taxRate', taxRate);
		formData.append('stockStatus', stockStatus);
		formData.append('productDescription', productDescription);
		try {
			const token = localStorage.getItem('token');
			const response = await axios.post(`${API_URL}/api/product-add`, formData, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			console.log(response.data);
			Swal.fire({
				icon: 'success',
				title: 'Success',
				text: response.data.message,
			}).then((result) => {
				if (result.isConfirmed) {
					handleCloseModal();
					fetchProducts();
				}
			});
		} catch (error) {
			console.error('Error uploading file', error);
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Something went wrong!',
			});
		}
	};
	return (
		<Container fluid>
			<Row>
				<Col lg="12">
					<div className="d-flex flex-wrap align-items-center justify-content-between">
						<div className="d-flex align-items-center justify-content-between">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb p-0 mb-0">
									<li className="breadcrumb-item"><Link to="/customer">Order</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Create Order</li>
								</ol>
							</nav>
						</div>
						<Link to="/order" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
							</svg>
							<span className="ml-2">Back</span>
						</Link>
					</div>
				</Col>
				<Col lg="12" className="mb-3 d-flex justify-content-between">
					<h4 className="font-weight-bold0 d-flex align-items-center">Create New Order</h4>
				</Col>
				<Col lg="12">
					<Card>
						<Card.Body>
							<Row>
								<Col md="12" className="mb-3">
									<Form className="row g-3" >
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Company Name</Form.Label>
											<Form.Control as="select" onChange={handleCompanyChange}>
												<option value="">Select Company</option>
												{customers.map((customer, index) => (
													<option key={index} value={customer.organizationName}>{customer.organizationName}</option>
												))}
											</Form.Control>
										</Col>


										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Branch</Form.Label>
											{selectedCompany.branches && selectedCompany.branches.length > 0 ? (
												<Form.Control as="select" value={branch} onChange={(e) => handleBranchChange(e)}>
													<option>Select Branch</option>
													{selectedCompany.branches.map((branch, index) => (
														<option key={index} value={branch._id}>{branch.branchName}</option>
													))}
												</Form.Control>
											) : (
												<Form.Control type="text" placeholder="Enter Branch" value={branch} onChange={(e) => setBranch(e.target.value)} />
											)}
										</Col>
										<Col md="12" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Delivery Address</Form.Label>
											<Form.Control as="textarea" placeholder="Enter Delivery Address" value={deliveryAddress} onChange={(e) => setDeliveryAddress(e.target.value)} />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">PO No</Form.Label>
											<Form.Control type="text" placeholder="Enter PO No" value={poNo} onChange={(e) => setPoNo(e.target.value)} />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">PO Date</Form.Label>
											<InputGroup className="mb-4">
												<Form.Control type="date" placeholder="Select PO Date" value={poDate} onChange={(e) => setPoDate(e.target.value)} aria-label="PO Date" />
											</InputGroup>
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Mail Date</Form.Label>
											<InputGroup className="mb-4">
												<Form.Control type="date" placeholder="Select Mail Date" value={mailDate} onChange={(e) => setMailDate(e.target.value)} aria-label="Mail Date" />
											</InputGroup>
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Reference Number</Form.Label>
											<Form.Control type="text" placeholder="Enter Reference Number" value={referenceNumber} onChange={(e) => setReferenceNumber(e.target.value)} />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Username</Form.Label>
											<Form.Control type="text" placeholder="Enter Username" value={username} onChange={(e) => setUsername(e.target.value)} />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Contact Person</Form.Label>
											<Form.Control type="text" placeholder="Enter Contact Person's Name" value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
											<Form.Control type="text" placeholder="Enter phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Remarks</Form.Label>
											<Form.Control type="text" placeholder="Enter Remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
										</Col>
										<Col md="6" className="mb-3 d-flex justify-content-between">
											<Form.Label className="font-weight-bold text-muted text-uppercase mb-6">Need Installation?</Form.Label>
											<div className="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline">
												<div className="custom-switch-inner">
													<Form.Check.Input bsPrefix="custom-control-input bg-success" id="customSwitch-22" checked={needInstallation} onChange={(e) => setNeedInstallation(e.target.checked)} />
													<Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch-22" data-on-label="Yes" data-off-label="No"></Form.Check.Label>
												</div>
											</div>
										</Col>

										<Col md="6" className="mb-3 d-flex justify-content-between">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Need Delivery?</Form.Label>
											<div className="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline">
												<div className="custom-switch-inner">
													<Form.Check.Input
														bsPrefix="custom-control-input bg-success"
														id="customSwitch-23"
														checked={needDelivery}  // Use state to control checked value
														onChange={(e) => setNeedDelivery(e.target.checked)}
														disabled={true}  // Keep the input disabled
													/>
													<Form.Check.Label
														bsPrefix="custom-control-label"
														htmlFor="customSwitch-23"
														data-on-label="Yes"
														data-off-label="No"
													></Form.Check.Label>
												</div>
											</div>
										</Col>

									</Form>

									<div className='wrapper clearfix 2'>
										<div className="wizard-content-item text-center mb-4">
											<h4>Item to be Billed</h4>
										</div>
										<table className="wojo small segment responsive table">
											<thead className="thead-dark">
												<tr>
													<th>SL No</th>
													<th>Product Name</th>
													<th>Quantity</th>
													<th>Stock Status</th>
													<th>Tax Rate</th>
													<th>Item Cost</th>
													<th>Total</th>
													<th>Remove</th>
												</tr>
											</thead>
											<tbody id='ivTable'>
												{val.map((data, i) => {
													return (
														<tr key={i} data-id={i + 1} className={i % 2 === 0 ? "table-active" : "table-success"}>
															<td style={{ padding: '23px 10px', fontStyle: 'italic', fontWeight: '600' }}>
																{i + 1}
															</td>
															<td>
																<div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select3-option ">
																	<Form.Control as="select" size="sm" value={data.productId || ''} onChange={(e) => handleProductChange(e.target.value, i)}>
																		<option>Select</option>
																		{Products.map((product, index) => (
																			<option key={index} value={product._id}>{product.productName}</option>
																		))}
																	</Form.Control>
																</div>
															</td>

															<td>
																<div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select5-option ">
																	<Form.Control type="text" size="sm" value={data.quantity || ''} onChange={(e) => { if (!isNaN(e.target.value)) { handleQuantityChange(e.target.value, i); } else { Swal.fire('Invalid Input', 'Only numbers are allowed', 'error'); } }} placeholder="Enter quantity" />
																</div>
															</td>
															{/* <td>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        trigger="click"
                                                                        placement="top"
                                                                        overlay={
                                                                            <Popover id={`popover-positioned-top-${i}`}>
                                                                                <Popover.Title as="h3">Barcodes</Popover.Title>
                                                                                <Popover.Content>
                                                                                    {data.barcodeDetails && data.barcodeDetails.codes ?
                                                                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                                                            {data.barcodeDetails.codes.filter(barcode => !barcode.used).map((barcode, index) => (
                                                                                                <span key={index} style={{ flex: '1 0 calc(20% - 10px)' }}>{barcode.code}</span>
                                                                                            ))}
                                                                                        </div>
                                                                                        : 'No barcodes'}
                                                                                </Popover.Content>
                                                                            </Popover>
                                                                        }
                                                                    >
                                                                        <Button variant="secondary" size="sm">View</Button>
                                                                    </OverlayTrigger>
                                                                </td>
                                                            </td> */}
															<td className="align-middle">
																<span className={`badge ${data.stockStatus === 'In Stock' ? 'bg-success' : 'bg-danger'}`}>
																	{data.stockStatus}
																</span>
															</td>
															<td>
																<div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select6-option ">
																	<Form.Control type="text" size="sm" value={data.gst || ''} readOnly />
																</div>
															</td>
															<td>
																<Form.Group controlId={`formPrice${i}`} size="sm">
																	<Form.Control type="text" name="price" value={data.price || ''} placeholder="Enter price" size="sm" onChange={(e) => { if (!isNaN(e.target.value)) { handlePriceChange(e.target.value, i); } else { Swal.fire('Invalid Input', 'Only numbers are allowed', 'error'); } }} />
																</Form.Group>
															</td>
															<td>
																{data.total || 0}
															</td>
															<td>
																<Button variant="danger" size="sm" onClick={() => handleRemove(i)}>
																	<i className="fa fa-trash"></i>
																</Button>
															</td>
														</tr>
													)
												})}
												<tr>
													<td colSpan="6">Sub Total</td>
													<td>{Number.isNaN(totals.subtotal) ? "Select Quantity" : totals.subtotal.toFixed(2)}</td>
												</tr>
												<tr>
													<td colSpan="6">Taxes</td>
													<td>{Number.isNaN(totals.taxes) ? "Select Taxes" : totals.taxes.toFixed(2)}</td>
												</tr>
												<tr>
													<td colSpan="6">Total Amount</td>
													<td className='wojo demi text positive'>{Number.isNaN(totals.total) ? "Select" : totals.total.toFixed(2)}</td>
												</tr>

												<tr>
													<td className='text-left'>
														<Button variant="warning" size="sm" onClick={handleOpenModal}>
															<i className='icon plus'><FaPlus /></i>
															Add Out of Stock Product
														</Button>
													</td>
													<td colSpan="7" className='text-right'>
														<Button onClick={handleAdd} size="sm" variant="primary">
															<i className='icon plus alt'><FaPlus /></i>
															Add Items
														</Button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="d-flex justify-content-start mt-1">
										<Button variant="btn btn-primary" onClick={handleSubmit}>
											Create Order
										</Button>
									</div>
								</Col>
							</Row>
							<Modal show={showModal} onHide={handleCloseModal} size="lg">
								<Modal.Header closeButton>
									<Modal.Title>Add Out of Stock Product</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Form className="row g-3" onSubmit={handleSubmit}>
										<div className="col-md-6 mb-3">
											<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Product Name</Form.Label>
											<Form.Control type="text" className="form-control" id="Text1" placeholder="Enter Product Name" value={productName} onChange={e => setProductName(e.target.value)} />
										</div>
										<div className="col-md-6 mb-3">
											<Form.Label htmlFor="Text5" className="form-label font-weight-bold text-muted text-uppercase">Quantity</Form.Label>
											<Form.Control type="text" className="form-control" id="Text5" placeholder="Enter Quantity" value={quantity} onChange={e => setQuantity(e.target.value)} />
										</div>

										<div className="col-md-6 mb-3">
											<Form.Label htmlFor="Text7" className="form-label font-weight-bold text-muted text-uppercase">Tax Rate (%)</Form.Label>
											<Form.Control type="text" className="form-control" id="Text7" placeholder="Enter Tax Rate" value={taxRate} onChange={e => setTaxRate(e.target.value)} />
										</div>
										<div className="col-md-6 mb-3">
											<Form.Label htmlFor="stockStatus" className="form-label font-weight-bold text-muted text-uppercase">Stock Status</Form.Label>
											<Form.Control type="text" className="form-control" id="stockStatus" placeholder="Enter Stock Status" value="Preorder" required />
										</div>

									</Form>
								</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" onClick={handleCloseModal}>
										Close
									</Button>
									<Button variant="primary" onClick={handleAddOutOfStockProduct}>
										Add
									</Button>
								</Modal.Footer>
							</Modal>
						</Card.Body>
					</Card>
				</Col>
			</Row >
		</Container >

	)
}

export default CreateOrder;
