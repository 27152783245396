import React, { useState, useEffect } from 'react';
import { FaPlus } from "react-icons/fa6";
//import { Container, Row, Col, Form, Button, Popover, OverlayTrigger, InputGroup } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import Card from '../../../components/Card';
import { API_URL } from '../../../config';
import { decode } from 'jsonwebtoken';
import { Container, Row, Col, Collapse, OverlayTrigger, Button, Popover, Form, Modal } from 'react-bootstrap'
function CreateDelivery() {
	const [deliveries, setDeliveries] = useState([]);
	const [selectedCourier, setSelectedCourier] = useState({});
	const [orderData, setOrderData] = useState(null);
	const [installationData, setInstallationData] = useState(null);

	const [podNumber, setPodNumber] = useState('');
	const [challanNumber, setChallanNumber] = useState('');
	const [challanDate, setChallanDate] = useState('');
	const [materialBatchNo, setMaterialBatchNo] = useState('');
	const [deliveryNote, setDeliveryNote] = useState('');
	const [orderType, setOrderType] = useState('Internal');
	const [selectedCourierId, setSelectedCourierId] = useState('');
	const [courierPersonName, setCourierPersonName] = useState('');

	const [lastValidMaterialBatchNos, setLastValidMaterialBatchNos] = useState([]);
	const [materialBatchNos, setMaterialBatchNos] = useState([]);
	const [barcodes, setBarcodes] = useState([]);
	const [barcodeIds, setBarcodeIds] = useState([]);
	const [selectedBatchNos, setSelectedBatchNos] = useState({});
	const [orderDelivery, setOrderDelivery] = useState('Complete');
	const [partialQuantity, setPartialQuantity] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState([]);
	const [deliveryStatus, setDeliveryStatus] = useState('Pending');


	const { id, orderid } = useParams();
	const history = useHistory();

	const token = localStorage.getItem('token');
	let roles = '';
	if (token) {
		const decodedToken = decode(token);
		roles = decodedToken.role;
	}


	const fetchDeliveries = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/deliveries`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setDeliveries(response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			}
		}
	};

	const fetchOrders = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/view-order/${id}`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setOrderData(response.data);
			const productIds = response.data.items.map(item => item.productId);
			fetchBarcodes(productIds);
			console.log("Order data fetched successfully:", response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			}
		}
	};

	const handleCourierChange = (event) => {
		const selectedId = event.target.value;
		const courier = deliveries.find(delivery => delivery._id === selectedId);
		setSelectedCourier(courier || {});
		console.log("Selected Courier ID:", selectedId);
	};

	const handleMaterialBatchChange = (event, index) => {
		const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
		const totalQuantity = orderData.items.reduce((acc, item) => acc + item.quantity, 0);

		if (selectedOptions.length > totalQuantity) {
			// Alert the user and do not update the selection state
			Swal.fire({
				icon: 'error',
				title: 'Too Many Barcodes Selected',
				text: `Please select no more than ${totalQuantity} barcodes.`,
				confirmButtonText: 'OK'
			}).then((result) => {
				// Force the select element to revert to the last valid selection
				setSelectedBatchNos(prev => ({
					...prev,
					[index]: lastValidMaterialBatchNos[index] || []
				}));
			});
		} else {
			// Update the selection state and record this as the last valid selection
			setSelectedBatchNos(prev => ({
				...prev,
				[index]: selectedOptions
			}));
			setLastValidMaterialBatchNos(prev => ({
				...prev,
				[index]: selectedOptions
			}));
		}
	};

	const openModal = (barcode, index) => {
		const content = selectedBatchNos[index].map(selectedId => {
			const selectedCode = barcode.codes.find(code => code._id === selectedId);
			return selectedCode ? `${selectedCode.code}` : 'Code not found';
		});
		setModalContent(content);
		setShowModal(true);
	};

	useEffect(() => {
		const initialBatchNos = {};
		barcodes.forEach((barcode, index) => {
			initialBatchNos[index] = []; // Initialize with empty array or existing values
		});
		setSelectedBatchNos(initialBatchNos);
	}, [barcodes]);

	const submitDelivery = async () => {
		if (!podNumber && orderType === "External") {
			Swal.fire({
				icon: 'error',
				title: 'Missing POD Number',
				text: 'Please enter a POD number for external orders.',
			});
			return;
		}

		if (!challanNumber) {
			Swal.fire({
				icon: 'error',
				title: 'Missing Challan Number',
				text: 'Please enter a challan number.',
			});
			return;
		}

		if (!challanDate) {
			Swal.fire({
				icon: 'error',
				title: 'Missing Challan Date',
				text: 'Please enter a challan date.',
			});
			return;
		}

		const allSelected = barcodes.length === Object.keys(selectedBatchNos).length &&
			Object.values(selectedBatchNos).every(batchNos => batchNos.length > 0);

		/* if (!allSelected) {
			Swal.fire({
				icon: 'error',
				title: 'Missing Material Batch Numbers',
				text: 'Please select at least one valid material batch number for each product.',
			});
			return;
		} */



		if (!orderDelivery) {
			Swal.fire({
				icon: 'error',
				title: 'Missing Order Delivery Type',
				text: 'Please select an order delivery type.',
			});
			return;
		}
		if (!deliveryNote || deliveryNote.trim() === "") {
			Swal.fire({
				icon: 'error',
				title: 'Missing Delivery Note',
				text: 'Please enter a delivery note.',
			});
			return;
		}
		const formData = new FormData();
		formData.append('orderId', id);
		formData.append('courierId', selectedCourier._id);
		formData.append('orderNumber', orderid);
		formData.append('podNumber', podNumber);
		formData.append('courierPersonName', courierPersonName);
		formData.append('challanNumber', challanNumber);
		formData.append('challanDate', challanDate);
		formData.append('materialBatchNo', materialBatchNo);
		formData.append('deliveryNote', deliveryNote);
		formData.append('orderType', orderType);
		formData.append('orderDelivery', orderDelivery);
		formData.append('partialQuantity', partialQuantity);
		formData.append('deliveryStatus', deliveryStatus);
		Object.values(selectedBatchNos).forEach(batchNos => {
			batchNos.forEach(batchNo => {
				formData.append('barcodeIds', batchNo);
			});
		});


		try {
			const response = await axios.post(`${API_URL}/api/create-delivery`, formData, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('token')}`,
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response.data.message === 'Delivery created successfully' || response.data.message === 'Delivery updated successfully') {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: response.data.message,
				}).then((result) => {
					if (result.isConfirmed) {
						history.push('/to-be-delivered');
					}
				});
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: response.data.message,
				});
			}
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			}
		}
	};


	useEffect(() => {
		fetchDeliveries();
	}, []);


	useEffect(() => {
		fetchOrders();
	}, [id]);

	const fetchInstallations = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/view-installation/${id}`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setInstallationData(response.data);
			console.log("Installation data fetched successfully:", response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			} else {
				console.error("Error fetching installation data: ", error);
			}
		}
	};
	useEffect(() => {
		fetchInstallations();
	}, []);

	const formatDateForInput = (dateString) => {
		const date = new Date(dateString);
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-indexed
		const day = date.getDate().toString().padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	const fetchDeliveryData = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/view-delivery/${id}`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			const deliveryData = response.data;
			console.log("Fetched Delivery Data:", deliveryData); // Log to check the structure

			const matchingCourier = deliveries.find(delivery => delivery._id === deliveryData.courierId);
			console.log("Matching Courier:", matchingCourier); // Verify the correct courier is found

			if (matchingCourier) {
				setSelectedCourier(matchingCourier);
			} else {
				console.error("No matching courier found for ID:", deliveryData.courierId);
			}

			// Set other delivery data
			setMaterialBatchNo(deliveryData.materialBatchNo || '');
			setDeliveryNote(deliveryData.deliveryNote || '');
			setCourierPersonName(deliveryData.courierPersonName || '');
			setPodNumber(deliveryData.podNumber || '');
			setChallanNumber(deliveryData.challanNumber || '');
			setChallanDate(formatDateForInput(deliveryData.challanDate) || '');
			setOrderType(deliveryData.orderType || '');
			setBarcodeIds(deliveryData.barcodeIds || []);

			console.log("Barcode IDs:", deliveryData.barcodeIds);

		} catch (error) {
			console.error("Error fetching delivery data: ", error);
		}
	};

	const fetchBarcodes = async (productIds) => {
		if (!productIds || productIds.length === 0) {
			console.error('No product IDs provided');
			return; // Exit the function if no product IDs are provided
		}
		//console.log('Product IDs:', productIds);
		const token = localStorage.getItem('token');
		const idsParam = productIds.join(',');
		try {
			const response = await axios.get(`${API_URL}/api/barcodes/${idsParam}`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setBarcodes(response.data);
			console.log('Barcodes fetched successfully:', response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.message === 'Forbidden - User not authenticated' || error.response.data.message === 'Forbidden - Insufficient permissions') {
					Swal.fire({
						title: 'Session expired or Invalid Permissions',
						text: 'Your session has expired or you do not have permission to view this data. Please log in again or contact your administrator.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/login');
						}
					}).catch((error) => {
						console.error("Error during session expiration or permission handling: ", error);
					});
				}
			} else {
				console.error('Error fetching barcodes:', error);
			}
		}
	};
	useEffect(() => {
		fetchDeliveryData();
	}, []);

	useEffect(() => {
		fetchBarcodes();
	}, []);

	useEffect(() => {
		if (deliveries.length > 0 && id) {
			fetchDeliveryData();
		}
	}, [deliveries, id]);
	return (
		<Container fluid>
			<Row>
				<Col lg="12">
					<div className="d-flex flex-wrap align-items-center justify-content-between">
						<div className="d-flex align-items-center justify-content-between">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb p-0 mb-0">
									<li className="breadcrumb-item"><Link to="/delivery">Delivery</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Create Delivery Order</li>
								</ol>
							</nav>
						</div>
						{roles === 'admin' ? (
							<a href="/order" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
								</svg>
								<span className="ml-2">Back</span>
							</a>
						) : (
							<a href="/employee-dashboard" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
								</svg>
								<span className="ml-2">Back</span>
							</a>
						)}
					</div>
				</Col>
				<Col lg="12" className="mb-3 d-flex justify-content-between">
					<h4 className="font-weight-bold0 d-flex align-items-center">Order Details for {orderid}</h4>
				</Col>
				<Col lg="12">
					<Card>
						<Card.Body>
							<Row>
								<Col md="12" className="mb-3">
									<Form className="row g-3" >
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Company Name</Form.Label>
											<Form.Control type="text" placeholder="Enter company name" value={orderData ? orderData.customerData.organizationName : ''} readOnly />

										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Branch</Form.Label>
											<Form.Control type="text" placeholder="Enter branch" value={orderData ? (orderData.customer.branch === 'NA' ? 'NA' : (orderData.customerData.branches.find(branch => branch._id === orderData.customer.branch) || { branchAddress: orderData.customer.branch }).branchAddress || 'Branch not found') : ''} readOnly />
										</Col>
										<Col md="12" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Delivery Address</Form.Label>
											<Form.Control as="textarea" rows={3} placeholder="Enter delivery address" value={orderData ? orderData.customer.deliveryAddress : ''} readOnly />
										</Col>

										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">PO No</Form.Label>
											<Form.Control type="text" placeholder="Enter PO No" value={orderData ? orderData.customer.poNo : ''} readOnly />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">PO Date</Form.Label>
											<Form.Control type="text" placeholder="Enter PO Date" value={orderData ? orderData.customer.poDate.split('T')[0] : ''} readOnly />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Mail Date</Form.Label>
											<Form.Control type="text" placeholder="Enter PO Mail Date" value={orderData ? orderData.customer.mailDate.split('T')[0] : ''} readOnly />
										</Col>


										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Reference Number</Form.Label>
											<Form.Control type="text" placeholder="Enter Reference Number" value={orderData ? orderData.customer.referenceNumber : ''} readOnly />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Username</Form.Label>
											<Form.Control type="text" placeholder="Enter Username" value={orderData ? orderData.customer.username : ''} readOnly />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Contact Person</Form.Label>
											<Form.Control type="text" placeholder="Enter Contact Person's Name" value={orderData ? orderData.customer.contactPerson : ''} readOnly />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
											<Form.Control type="text" placeholder="Enter phone number" value={orderData ? orderData.customerData.phoneNumber : ''} readOnly />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Remarks</Form.Label>
											<Form.Control type="text" placeholder="Enter Remarks" value={orderData ? orderData.customer.remarks : ''} readOnly />
										</Col>
										<Col md="6" className="mb-3 d-flex justify-content-between">
											<Form.Label className="font-weight-bold text-muted text-uppercase mb-6">Need Installation?</Form.Label>
											<div className="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline">
												<div className="custom-switch-inner">
													<Form.Check.Input bsPrefix="custom-control-input bg-success" id="customSwitch-22" checked={orderData ? orderData.customer.needInstallation : false} readOnly />
													<Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch-22" data-on-label="Yes" data-off-label="No"></Form.Check.Label>
												</div>
											</div>
										</Col>

										<Col md="6" className="mb-3 d-flex justify-content-between">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Need Delivery?</Form.Label>
											<div className="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline">
												<div className="custom-switch-inner">
													<Form.Check.Input bsPrefix="custom-control-input bg-success" id="customSwitch-23" checked={orderData ? orderData.customer.needDelivery : false} readOnly />
													<Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch-23" data-on-label="Yes" data-off-label="No"></Form.Check.Label>
												</div>
											</div>
										</Col>

									</Form>
									<div className='wrapper clearfix 2'>
										<div className="wizard-content-item text-center mb-4">
											<h4>Ordered Items</h4>
										</div>
										<table className="wojo small segment responsive table">
											<thead className="thead-dark">
												<tr>
													<th>SL No</th>
													<th>Product Name</th>
													<th>Quantity</th>
													<th>Stock Status</th>
													<th>Tax Rate</th>
													<th>Item Cost</th>
													<th>Total</th>
												</tr>
											</thead>
											<tbody id='ivTable'>
												{orderData && orderData.items && orderData.items.map((data, i) => {
													return (
														<tr key={i} data-id={i + 1} className={i % 2 === 0 ? "table-active" : "table-success"}>
															<td style={{ padding: '23px 10px', fontStyle: 'italic', fontWeight: '600' }}>
																{i + 1}
															</td>
															<td>
																{data.productData && data.productData.productName}
															</td>
															<td>
																{data.quantity}
															</td>
															<td>
																<span className={`badge ${data.productData && data.productData.stockStatus === 'In Stock' ? 'bg-success' : 'bg-danger'}`}>
																	{data.productData && data.productData.stockStatus}
																</span>
															</td>
															<td>
																{data.productData && `${data.productData.taxRate}%`}
															</td>
															<td>
																{data.price}
															</td>

															<td>
																{data.total}
															</td>
														</tr>
													)
												})}
												<tr>
													<td colSpan="6" style={{ textAlign: "right", fontWeight: "bold" }}>Sub Total</td>
													<td>{orderData ? orderData.totals.subtotal : 0}</td>
												</tr>
												<tr>
													<td colSpan="6" style={{ textAlign: "right", fontWeight: "bold" }}>Taxes</td>
													<td>{orderData ? orderData.totals.taxes : 0}</td>
												</tr>
												<tr>
													<td colSpan="6" style={{ textAlign: "right", fontWeight: "bold" }}>Total Amount</td>
													<td className='wojo demi text positive'>{orderData ? orderData.totals.total : 0}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col lg="12" className="mb-3 d-flex justify-content-between">
					<h4 className="font-weight-bold0 d-flex align-items-center">Installation Details for {orderid}</h4>
				</Col>
				<Col lg="12">
					<div className="card">
						<div className="card-body">
							<Row>
								<Col lg="12">
									{installationData ? (
										<form className="row g-3">
											<Col md="6" className="mb-3">
												<label className="font-weight-bold text-muted text-uppercase">Installation Name</label>
												<input type="text" className="form-control" value={installationData.installationDetails.vendorName} readOnly />
											</Col>
											<Col md="6" className="mb-3">
												<label className="font-weight-bold text-muted text-uppercase">Order Number</label>
												<input type="text" className="form-control" value={installationData.orderNumber} readOnly />
											</Col>
											<Col md="6" className="mb-3">
												<label className="font-weight-bold text-muted text-uppercase">Address</label>
												<input type="text" className="form-control" value={installationData.installationDetails.vendorAddress} readOnly />
											</Col>
											<Col md="6" className="mb-3">
												<label className="font-weight-bold text-muted text-uppercase">Phone</label>
												<input type="text" className="form-control" value={installationData.installationDetails.vendorPhoneNumber} readOnly />
											</Col>
											<Col md="6" className="mb-3">
												<label className="font-weight-bold text-muted text-uppercase">Mail Id</label>
												<input type="email" className="form-control" value={installationData.installationDetails.vendorEmailId} readOnly />
											</Col>
											<Col md="12" className="mb-3">
												<label className="font-weight-bold text-muted text-uppercase">Remark</label>
												<textarea className="form-control" value={installationData.remark} readOnly></textarea>
											</Col>
										</form>
									) : (
										<div>No data found</div>
									)}
								</Col>
							</Row>
						</div>
					</div>
				</Col>
				<Col lg="12" className="mb-3 d-flex justify-content-between">
					<h4 className="font-weight-bold0 d-flex align-items-center">Create Dispatch Delivery</h4>
				</Col>
				<Col lg="12">
					<Card>
						<Card.Body>
							<Row>
								<Col lg="12">
									<Form className="row g-3">
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Type</Form.Label>
											<Form.Control as="select" value={orderType} onChange={(e) => setOrderType(e.target.value)}>
												<option value="Internal">Internal</option>
												<option value="External">External</option>
											</Form.Control>
										</Col>
										{orderType === "External" && (
											<Col md="6" className="mb-3">
												<Form.Label className="font-weight-bold text-muted text-uppercase">Courier Name</Form.Label>
												<Form.Control as="select" value={selectedCourier._id || ''} onChange={handleCourierChange}>
													<option value="">Select</option>
													{deliveries.map((delivery) => (
														<option key={delivery._id} value={delivery._id}>
															{delivery.deliveryName}
														</option>
													))}
												</Form.Control>
											</Col>
										)}
										{orderType === "Internal" && (
											<Col md="6" className="mb-3">
												<Form.Label className="font-weight-bold text-muted text-uppercase">Courier Person Name</Form.Label>
												<Form.Control type="text" placeholder="Enter Courier Person Name" value={courierPersonName} onChange={(e) => setCourierPersonName(e.target.value)} />
											</Col>
										)}
										{orderType === "External" && (
											<Col md="6" className="mb-3">
												<Form.Label className="font-weight-bold text-muted text-uppercase">POD Number</Form.Label>
												<Form.Control type="text" placeholder="Enter POD Number" value={podNumber} onChange={(e) => setPodNumber(e.target.value)} />
											</Col>
										)}
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Challan Number</Form.Label>
											<Form.Control type="text" placeholder="Enter Challan Number" value={challanNumber} onChange={(e) => setChallanNumber(e.target.value)} />
										</Col>
										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Challan Date</Form.Label>
											<Form.Control type="date" value={challanDate} onChange={(e) => setChallanDate(e.target.value)} />
										</Col>
										<Col md="12" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Material Batch Number & Serial Number</Form.Label>
											{barcodes.length > 0 ? (
												barcodes.map((barcode, index) => (
													<div key={index} className="mb-3 d-flex align-items-center">
														<h5 className="font-weight-bold mr-2">{barcode.productName}</h5>
														<Form.Control as="select" multiple value={selectedBatchNos[index] || []} onChange={(e) => handleMaterialBatchChange(e, index)}>
															<option value="">Select Barcode</option>
															{barcode.codes.map((code) => (
																<option key={code._id} value={code._id}>
																	{barcode.productName} - {code.code} | {code.serialNumber}
																</option>
															))}
														</Form.Control>
														{selectedBatchNos[index] && selectedBatchNos[index].length > 0 && (
															<Button variant="info" size="sm" className="ml-2" onClick={() => openModal(barcode, index)}>Show Selected</Button>
														)}
													</div>
												))
											) : (
												<div className="alert alert-danger" role="alert">
													Error: No barcodes or serial numbers generated.
												</div>
											)}

											<Modal show={showModal} onHide={() => setShowModal(false)}>
												<Modal.Header closeButton>
													<Modal.Title>Selected Codes</Modal.Title>
												</Modal.Header>
												<Modal.Body>
													{modalContent.map((content, idx) => (
														<div key={idx}>{content}</div>
													))}
												</Modal.Body>
												<Modal.Footer>
													<Button variant="secondary" onClick={() => setShowModal(false)}>
														Close
													</Button>
												</Modal.Footer>
											</Modal>
										</Col>

										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Order Delivery</Form.Label>
											<Form.Control as="select" value={orderDelivery} onChange={(e) => setOrderDelivery(e.target.value)}>
												<option value="Partial">Partial</option>
												<option value="Complete">Complete</option>
											</Form.Control>
										</Col>
										{orderDelivery === "Partial" && (
											<Col md="6" className="mb-3">
												<Form.Label className="font-weight-bold text-muted text-uppercase">Partial Quantity</Form.Label>
												<Form.Control type="number" placeholder="Enter Partial Quantity" value={partialQuantity} onChange={(e) => setPartialQuantity(e.target.value)} />
											</Col>
										)}

										<Col md="6" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Delivery Status</Form.Label>
											<Form.Control as="select" value={deliveryStatus} onChange={(e) => setDeliveryStatus(e.target.value)}>
												<option value="pending">Pending</option>
												<option value="shipped">Shipped</option>
												<option value="delivered">Delivered</option>
											</Form.Control>
										</Col>
										<Col md="12" className="mb-3">
											<Form.Label className="font-weight-bold text-muted text-uppercase">Delivery Note</Form.Label>
											<Form.Control as="textarea" rows={3} placeholder="Enter Delivery Note" value={deliveryNote} onChange={(e) => setDeliveryNote(e.target.value)} />
										</Col>
									</Form>
								</Col>
								<div className="d-flex justify-content-start mt-1">
									<Button variant="btn btn-primary" onClick={submitDelivery}>
										Create Delivery Order
									</Button>
								</div>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container >
	)
}

export default CreateDelivery;
