import { Switch, Route, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PrivateRoute from '../components/PrivateRoute';
//main
import Dashbord from '../views/backend/Main/Dashbord'
import Employeedashboard from '../views/backend/Main/Employeedashboard'
// Calendar
import Calendar from '../views/backend/Main/Calendar';

// Customer
import Customer from '../views/backend/Main/Customer';
import Customeradd from '../views/backend/Main/Customeradd';
import Customerview from '../views/backend/Main/Cutomerview';
import Customeredit from '../views/backend/Main/Customeredit';
import DeliveryData from '../views/backend/Main/DeliveryData';
import DeliveryList from '../views/backend/Main/DeliveryList';
import DeliveryDataEdit from '../views/backend/Main/DeliveryDataEdit';

import CreateOrder from '../views/backend/Main/CreateOrder';
import CreateDelivery from '../views/backend/Main/CreateDelivery';
import CreateInstallation from '../views/backend/Main/CreateInstallation';




//Employee
import Employee from '../views/backend/Main/Employee';
import Employeeadd from '../views/backend/Main/Employeeadd';
import Employeeview from '../views/backend/Main/Employeeview';
import Employeeedit from '../views/backend/Main/Employeeedit';

//Delivery
import Delivery from '../views/backend/Main/Delivery';
import DeliveryAdd from '../views/backend/Main/Deliveryadd';
import DeliveryEdit from '../views/backend/Main/Deliveryedit';

//Table
import DynamicTable from '../views/backend/Main/Tableadd';
// Product
import Product from '../views/backend/Main/Product';
import Productadd from '../views/backend/Main/Productadd';
import Productedit from '../views/backend/Main/Productedit';

// order
import Order from '../views/backend/Main/Order';
import Ordernew from '../views/backend/Main/Ordernew';
import Orderdetails from '../views/backend/Main/Orderdetails';

//Installation
import Installation from '../views/backend/Main/Installation';
import InstallationAdd from '../views/backend/Main/InstallationAdd';
import InstallationEdit from '../views/backend/Main/InstallationEdit';

//Tables
import Tables from '../views/backend/Main/Tables';
import TableAdd from '../views/backend/Main/Tableadd';

//App
import UserProfile from '../views/backend/App/UserManagement/UserProfile'
import UserAdd from '../views/backend/App/UserManagement/UserAdd'
import UserList from '../views/backend/App/UserManagement/UserList'
import UserProfileEdit from '../views/backend/App/UserManagement/UserProfileEdit'
import Chat from '../views/backend/App/Chat'
import Todo from '../views/backend/App/Todo'

//Chart
import ApexChart from '../views/backend/Chart/ApexChart'

//From
import Checkbox from '../views/backend/Forms/FormControls/Checkbox'
import Elements from '../views/backend/Forms/FormControls/Elements'
import Inputs from '../views/backend/Forms/FormControls/Inputs'
import Radio from '../views/backend/Forms/FormControls/Radio'
import FromSwitch from '../views/backend/Forms/FormControls/Switch'
import TextArea from '../views/backend/Forms/FormControls/TextArea'
import Validations from '../views/backend/Forms/FormControls/Validations'
import Datepicker from '../views/backend/Forms/Formwidget/Datepicker'
import Fileupload from '../views/backend/Forms/Formwidget/Fileupload'
import FormQuill from '../views/backend/Forms/Formwidget/FormQuill'
import Select from '../views/backend/Forms/Formwidget/Select'
import Invoiceview from '../views/backend/pages/Invoiceview';


//Extrapages
import Timeline1 from '../views/backend/pages/Timeline/Timeline1'
import Pricing1 from '../views/backend/pages/Pricing/Pricing1'
import Invoice from '../views/backend/pages/Invoice'
import FAQ from '../views/backend/pages/FAQ'
import BlankPage from '../views/backend/pages/BlankPage'
import TermsOfUse from '../views/backend/pages/Extrapages/TermsOfUse'
import PrivacyPolicy from '../views/backend/pages/Extrapages/PrivacyPolicy'

//Table
import BasicTable from '../views/backend/Table/BasicTable'
import DataTable from '../views/backend/Table/DataTable'
import EditTable from '../views/backend/Table/EditTable'

//ui
import UiAlerts from '../views/backend/ui/UiAlerts'
import UiAvatars from '../views/backend/ui/UiAvatars'
import UiBadges from '../views/backend/ui/UiBadges'
import UiBoxShadows from '../views/backend/ui/UiBoxShadows'
import UiBreadcrumbs from '../views/backend/ui/UiBreadcrumbs'
import UiButtonGroups from '../views/backend/ui/UiButtonGroups'
import UiButtons from '../views/backend/ui/UiButtons'
import UiCards from '../views/backend/ui/UiCards'
import UiCarousels from '../views/backend/ui/UiCarousels'
import UiColors from '../views/backend/ui/UiColors'
import UiEmbed from '../views/backend/ui/UiEmbed'
import UiGrids from '../views/backend/ui/UiGrids'
import UiImages from '../views/backend/ui/UiImages'
import UiListGroups from '../views/backend/ui/UiListGroups'
import UiMediaObjects from '../views/backend/ui/UiMediaObjects'
import UiModals from '../views/backend/ui/UiModals'
import UiNotifications from '../views/backend/ui/UiNotifications'
import UiPaginations from '../views/backend/ui/UiPaginations'
import UiPopOvers from '../views/backend/ui/UiPopOvers'
import UiProgressBars from '../views/backend/ui/UiProgressBars'
import UiTabs from '../views/backend/ui/UiTabs'
import UiTooltips from '../views/backend/ui/UiTooltips'
import UiTypography from '../views/backend/ui/UiTypography'

//Sign In
import SignIn from '../views/backend/Auth/SignIn'
import Report from '../views/backend/Main/Report';

import TobeInstalled from '../views/backend/Main/TobeInstalled';
import CompletedInstallation from '../views/backend/Main/CompletedInstallation';
import TobeDelivered from '../views/backend/Main/TobeDelivered';
import CompletedDeliveries from '../views/backend/Main/CompletedDeliveries';

import ViewDetails from '../views/backend/Main/ViewDetails';
import OrderList from '../views/backend/Main/OrderList';
import ViewInvoice from '../views/backend/Main/ViewInvoice';
import CompletedOrders from '../views/backend/Main/CompletedOrders';
import EmpReport from '../views/backend/Main/EmpReport';
import ShortReport from '../views/backend/Main/ShortReport';

import AssignMultiple from '../views/backend/Main/AssignMultiple';


import CustomerUnassign from '../views/backend/Main/CustomerUnassign';
import AssignedCustomer from '../views/backend/Main/AssignedCustomer';

// icon-heroicon
import Heroicons from '../views/backend/Icons/Heroicons ';
import AssignBarcode from '../views/backend/Main/AssignBarcode';
import PartialOrders from '../views/backend/Main/PartialOrders';

const Layout1Route = () => {

    let location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={300}
            >
                <Switch location={location}>
                    <Route exact path="/" component={SignIn} />
                    <PrivateRoute path="/dashboard" exact component={Dashbord} roles="admin" />
                    <PrivateRoute path="/employee-dashboard" exact component={Employeedashboard} roles="employee" />

                    {/* App */}
                    <PrivateRoute path="/user-profile" component={UserProfile} roles="admin" />
                    <PrivateRoute path="/user-add" component={UserAdd} roles="admin" />
                    <PrivateRoute path="/user-list" component={UserList} roles="admin" />
                    <PrivateRoute path="/user-profile-edit" component={UserProfileEdit} />
                    <PrivateRoute path="/chat" component={Chat} roles="employee" />
                    <PrivateRoute path="/todo" component={Todo} roles="employee" />
                    <PrivateRoute path="/delivery" component={Delivery} roles="employee" />

                    {/* Chart */}
                    <PrivateRoute path="/chart-apex" component={ApexChart} />

                    {/* From  */}
                    <PrivateRoute path="/form-chechbox" component={Checkbox} />
                    <PrivateRoute path="/form-layout" component={Elements} />
                    <PrivateRoute path="/form-input-group" component={Inputs} />
                    <PrivateRoute path="/form-radio" component={Radio} />
                    <PrivateRoute path="/form-switch" component={FromSwitch} />
                    <PrivateRoute path="/form-textarea" component={TextArea} />
                    <PrivateRoute path="/form-validation" component={Validations} />
                    <PrivateRoute path="/form-datepicker" component={Datepicker} />
                    <PrivateRoute path="/form-file-uploader" component={Fileupload} />
                    <PrivateRoute path="/form-quill" component={FormQuill} />
                    <PrivateRoute path="/form-select" component={Select} />

                    {/* Icon */}
                    <PrivateRoute path="/icon-heroicon" component={Heroicons} />

                    {/* Extrapages */}
                    <PrivateRoute path="/timeline-1" component={Timeline1} />
                    <PrivateRoute path="/pricing-1" component={Pricing1} />
                    <PrivateRoute path="/pages-invoice" component={Invoice} roles="employee" />
                    <PrivateRoute path="/pages-faq" component={FAQ} />
                    <PrivateRoute path="/pages-blank-page" component={BlankPage} />
                    <PrivateRoute path="/terms-of-service" component={TermsOfUse} />
                    <PrivateRoute path="/privacy-policy" component={PrivacyPolicy} />
                    <PrivateRoute path="/invoice-view" component={Invoiceview} roles="employee" />

                    {/* Table */}
                    <PrivateRoute path="/tables-basic" component={BasicTable} />
                    <PrivateRoute path="/tables-data" component={DataTable} />
                    <PrivateRoute path="/tables-editable" component={EditTable} />

                    {/* Ui */}
                    <PrivateRoute path="/ui-alerts" component={UiAlerts} />
                    <PrivateRoute path="/ui-avatars" component={UiAvatars} />
                    <PrivateRoute path="/ui-badges" component={UiBadges} />
                    <PrivateRoute path="/ui-boxshadow" component={UiBoxShadows} />
                    <PrivateRoute path="/ui-breadcrumb" component={UiBreadcrumbs} />
                    <PrivateRoute path="/ui-buttons-group" component={UiButtonGroups} />
                    <PrivateRoute path="/ui-buttons" component={UiButtons} />
                    <PrivateRoute path="/ui-cards" component={UiCards} />
                    <PrivateRoute path="/ui-carousel" component={UiCarousels} />
                    <PrivateRoute path="/ui-colors" component={UiColors} />
                    <PrivateRoute path="/ui-embed-video" component={UiEmbed} />
                    <PrivateRoute path="/ui-grid" component={UiGrids} />
                    <PrivateRoute path="/ui-images" component={UiImages} />
                    <PrivateRoute path="/ui-list-group" component={UiListGroups} />
                    <PrivateRoute path="/ui-media-object" component={UiMediaObjects} />
                    <PrivateRoute path="/ui-modal" component={UiModals} />
                    <PrivateRoute path="/ui-notifications" component={UiNotifications} />
                    <PrivateRoute path="/ui-pagination" component={UiPaginations} />
                    <PrivateRoute path="/ui-popovers" component={UiPopOvers} />
                    <PrivateRoute path="/ui-progressbars" component={UiProgressBars} />
                    <PrivateRoute path="/ui-tabs" component={UiTabs} />
                    <PrivateRoute path="/ui-tooltips" component={UiTooltips} />
                    <PrivateRoute path="/ui-typography" component={UiTypography} />

                    {/*Customer*/}
                    <PrivateRoute path="/customer" component={Customer} roles="admin" />
                    <PrivateRoute path="/customer-add" component={Customeradd} roles="admin" />
                    <PrivateRoute path="/customer-view" component={Customerview} roles="admin" />
                    <PrivateRoute path="/customer-edit/:id" component={Customeredit} roles="admin" />
                    <PrivateRoute path="/customer-unassign" component={CustomerUnassign} roles="admin" />

                    <PrivateRoute path="/table" component={DynamicTable} roles="admin" />
                    {/*Employee*/}
                    <PrivateRoute path="/employee" component={Employee} roles="admin" />
                    <PrivateRoute path="/employee-add" component={Employeeadd} roles="admin" />
                    <PrivateRoute path="/employee-edit/:id" component={Employeeedit} roles="admin" />
                    <PrivateRoute path="/employee-view" component={Employeeview} roles="admin" />

                    {/* Table */}
                    <PrivateRoute path="/tables" component={Tables} roles="admin" />
                    <PrivateRoute path="/table-add" component={TableAdd} roles="admin" />

                    {/* Installation */}
                    <PrivateRoute path="/installation" component={Installation} roles="admin" />
                    <PrivateRoute path="/installation-add" component={InstallationAdd} roles="admin" />
                    <PrivateRoute path="/installation-edit/:id" component={InstallationEdit} roles="admin" />

                    {/* Delivery */}
                    <PrivateRoute path="/delivery" component={Delivery} roles="admin" />
                    <PrivateRoute path="/delivery-add" component={DeliveryAdd} roles="admin" />
                    <PrivateRoute path="/delivery-edit/:id" component={DeliveryEdit} roles="admin" />



                    {/* Product */}
                    <PrivateRoute path="/product" component={Product} roles="admin" />
                    <PrivateRoute path="/product-add" component={Productadd} roles="admin" />
                    <PrivateRoute path="/product-edit/:id" component={Productedit} roles="admin" />

                    {/* Order */}
                    <PrivateRoute path="/order" component={Order} roles="employee" />
                    <PrivateRoute path="/order-new" component={Ordernew} roles="employee" />
                    <PrivateRoute path="/order-view/:id" component={Orderdetails} />

                    {/* Caldenar */}
                    <PrivateRoute path="/calendar" component={Calendar} roles="employee" />
                    {/* Delivery Data */}
                    <PrivateRoute path="/delivery-data/:id/:orderid" component={DeliveryData} roles="delivery" />
                    <PrivateRoute path="/delivery-list" component={DeliveryList} roles="delivery" />
                    <PrivateRoute path="/delivery-data-edit/:id" component={DeliveryDataEdit} roles="delivery" />

                    <PrivateRoute path="/create-order" component={CreateOrder} roles={['admin', 'employee']} />
                    <PrivateRoute path="/create-installation/:id/:orderid" component={CreateInstallation} roles={['admin', 'employee']} />
                    <PrivateRoute path="/create-delivery/:id/:orderid" component={CreateDelivery} roles={['admin', 'employee']} />

                    {/* Report */}
                    <PrivateRoute path="/reports" component={Report} roles={['admin', 'accountant']} />

                    <PrivateRoute path="/to-be-installed" component={TobeInstalled} roles="employee" />
                    <PrivateRoute path="/completed-installation" component={CompletedInstallation} roles="employee" />
                    <PrivateRoute path="/to-be-delivered" component={TobeDelivered} roles="employee" />
                    <PrivateRoute path="/completed-deliveries" component={CompletedDeliveries} roles="employee" />
                    <PrivateRoute path="/view-details/:id/:orderid" component={ViewDetails} roles={['employee', 'accountant']} />

                    <PrivateRoute path="/order-list" component={OrderList} roles="accountant" />
                    <PrivateRoute path="/view-invoice/:id/:orderId" component={ViewInvoice} roles={['admin', 'accountant', 'employee']} />
                    <PrivateRoute path="/completed-orders" component={CompletedOrders} roles={['admin', 'accountant', 'employee']} />
                    <PrivateRoute path="/report-view" component={EmpReport} roles="employee" />
                    <PrivateRoute path="/short-report" component={ShortReport} roles="admin" />

                    <PrivateRoute path="/assign-multiple" component={AssignMultiple} roles="admin" />
                    <PrivateRoute path="/assigned-customer" component={AssignedCustomer} roles="employee" />
                    <PrivateRoute path="/assign-barcode/:id" component={AssignBarcode} roles="admin" />
                    <PrivateRoute path="/partial-orders" component={PartialOrders} roles="employee" />

                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default Layout1Route