import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import Swal from 'sweetalert2';
import { decode } from 'jsonwebtoken';

const ViewDetails = () => {
    const [orderData, setOrderData] = useState(null);
    const [installationData, setInstallationData] = useState(null);
    const [deliveryDetails, setDeliveryDetails] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [submissionDate, setSubmissionDate] = useState('');
    const [invoiceRef, setInvoiceRef] = useState('');
    const [invoiceAttachment, setInvoiceAttachment] = useState(null);
    const [invoice, setInvoice] = useState(null);

    const { id, orderid } = useParams();
    const history = useHistory();

    const token = localStorage.getItem('token');
    let roles = '';
    if (token) {
        const decodedToken = decode(token);
        roles = decodedToken.role;
    }

    const fetchOrders = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/view-order/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setOrderData(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            }
        }
    };
    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchInstallations = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/view-installation/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data) {
                setInstallationData(response.data);
            } else {
                console.log('No installation data available.');
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 403) {
                    if (error.response.data.error === 'Token expired') {
                        Swal.fire({
                            title: 'Session expired',
                            text: 'Your session has expired. Please log in again.',
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                localStorage.removeItem('token');
                                history.push('/');
                            }
                        }).catch((error) => {
                            console.error("Error during session expiration handling: ", error);
                        });
                    } else if (error.response.data.error === 'Invalid token') {
                        Swal.fire({
                            title: 'Invalid token',
                            text: 'Your token is invalid. Please log in again.',
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                localStorage.removeItem('token');
                                history.push('/');
                            }
                        }).catch((error) => {
                            console.error("Error during session expiration handling: ", error);
                        });
                    }
                } else if (error.response.status === 404) {
                    console.log('Installation data not found for the given ID.');
                } else {
                    console.error("Error fetching installation data: ", error);
                }
            }
        }
    };
    useEffect(() => {
        fetchInstallations();
    }, []);

    const fetchDeliveryDetails = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/view-delivery/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.data) {
                setDeliveryDetails(response.data);
            } else {
                console.log('No delivery details available.');
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 403) {
                    if (error.response.data.error === 'Token expired') {
                        Swal.fire({
                            title: 'Session expired',
                            text: 'Your session has expired. Please log in again.',
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                localStorage.removeItem('token');
                                history.push('/');
                            }
                        }).catch((error) => {
                            console.error("Error during session expiration handling: ", error);
                        });
                    } else if (error.response.data.error === 'Invalid token') {
                        Swal.fire({
                            title: 'Invalid token',
                            text: 'Your token is invalid. Please log in again.',
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                localStorage.removeItem('token');
                                history.push('/');
                            }
                        }).catch((error) => {
                            console.error("Error during session expiration handling: ", error);
                        });
                    }
                } else if (error.response.status === 404) {
                    console.log('Delivery details not found for the given ID.');
                } else {
                    console.error("Error fetching delivery details: ", error);
                }
            }
        }
    };

    useEffect(() => {
        fetchDeliveryDetails();
    }, []);

    const handleSubmit = async (e) => {
        if (!invoiceNumber) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Invoice Number',
                text: 'Please enter an invoice number.',
            });
            return;
        }

        if (!invoiceDate) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Invoice Date',
                text: 'Please enter an invoice date.',
            });
            return;
        }

        if (!submissionDate) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Submission Date',
                text: 'Please enter a submission date.',
            });
            return;
        }

        if (!invoiceRef) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Invoice Reference',
                text: 'Please enter an invoice reference.',
            });
            return;
        }

        if (!invoiceAttachment) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Invoice Attachment',
                text: 'Please attach the invoice file.',
            });
            return;
        }
        e.preventDefault();
        const formData = new FormData();
        formData.append('invoiceNumber', invoiceNumber);
        formData.append('invoiceDate', invoiceDate);
        formData.append('submissionDate', submissionDate);
        formData.append('invoiceRef', invoiceRef);
        formData.append('orderid', id);
        formData.append('invoiceAttachment', invoiceAttachment);

        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/api/invoices`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.message === 'Invoice processed successfully') {
                Swal.fire({
                    icon: 'success',
                    title: 'Invoice Submitted',
                    text: 'Your invoice has been successfully submitted!',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Submission Failed',
                    text: response.data.message,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                console.error("Error submitting invoice: ", error);
            }
        }
    };

    const fetchInvoice = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/invoices/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const invoiceData = response.data.length > 0 ? response.data[0] : null;
            if (invoiceData) {
                setInvoice(invoiceData);
                setInvoiceNumber(invoiceData.invoiceNumber);
                setInvoiceDate(invoiceData.invoiceDate.split('T')[0]);
                setSubmissionDate(invoiceData.submissionDate.split('T')[0]);
                setInvoiceRef(invoiceData.invoiceRef);
                setInvoiceAttachment(invoiceData.invoiceAttachment);
                console.log(invoiceAttachment);
            } else {
                console.error("No invoice data found.");
            }
        } catch (error) {
            if (error.response.data.error === 'Token expired') {
                Swal.fire({
                    title: 'Session expired',
                    text: 'Your session has expired. Please log in again.',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.removeItem('token');
                        history.push('/');
                    }
                }).catch((error) => {
                    console.error("Error during session expiration handling: ", error);
                });
            } else if (error.response.data.error === 'Invalid token') {
                Swal.fire({
                    title: 'Invalid token',
                    text: 'Your token is invalid. Please log in again.',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.removeItem('token');
                        history.push('/');
                    }
                }).catch((error) => {
                    console.error("Error during session expiration handling: ", error);
                });
            } else {
                console.error("Error fetching invoice: ", error);
                Swal.fire(
                    'Error!',
                    'There was an issue fetching the invoice.',
                    'error'
                );
            }
        }
    };
    useEffect(() => {
        fetchInvoice();
    }, [id, history]);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/delivery">Delivery</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Create Delivery Order</li>
                                    </ol>
                                </nav>

                            </div>
                            {roles === 'accountant' ? (
                                <Link to="/order-list" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            ) : roles === 'admin' ? (
                                <Link to="/completed-orders" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            ) : (
                                <Link to="/to-be-installed" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            )}

                        </div>

                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold0 d-flex align-items-center">Order Details for {orderid}</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="12" className="mb-3">
                                        <Form className="row g-3" >
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Company Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter company name" value={orderData ? orderData.customerData.organizationName : ''} readOnly />

                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Branch</Form.Label>
                                                <Form.Control type="text" placeholder="Enter branch" value={orderData ? (orderData.customer.branch === 'NA' ? 'NA' : (orderData.customerData.branches.find(branch => branch._id === orderData.customer.branch) || { branchAddress: orderData.customer.branch }).branchAddress || 'Branch not found') : ''} readOnly />
                                            </Col>
                                            <Col md="12" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Delivery Address</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder="Enter delivery address" value={orderData ? orderData.customer.deliveryAddress : ''} readOnly />
                                            </Col>

                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">PO No</Form.Label>
                                                <Form.Control type="text" placeholder="Enter PO No" value={orderData ? orderData.customer.poNo : ''} readOnly />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">PO Date</Form.Label>
                                                <Form.Control type="text" placeholder="Enter PO Date" value={orderData ? orderData.customer.poDate.split('T')[0] : ''} readOnly />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Mail Date</Form.Label>
                                                <Form.Control type="text" placeholder="Enter PO Mail Date" value={orderData ? orderData.customer.mailDate.split('T')[0] : ''} readOnly />
                                            </Col>


                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Reference Number</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Reference Number" value={orderData ? orderData.customer.referenceNumber : ''} readOnly />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Username</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Username" value={orderData ? orderData.customer.username : ''} readOnly />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Contact Person</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Contact Person's Name" value={orderData ? orderData.customer.contactPerson : ''} readOnly />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control type="text" placeholder="Enter phone number" value={orderData ? orderData.customerData.phoneNumber : ''} readOnly />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Remarks</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Remarks" value={orderData ? orderData.customer.remarks : ''} readOnly />
                                            </Col>
                                            <Col md="6" className="mb-3 d-flex justify-content-between">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase mb-6">Need Installation?</Form.Label>
                                                <div className="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline">
                                                    <div className="custom-switch-inner">
                                                        <Form.Check.Input bsPrefix="custom-control-input bg-success" id="customSwitch-22" checked={orderData ? orderData.customer.needInstallation : false} readOnly />
                                                        <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch-22" data-on-label="Yes" data-off-label="No"></Form.Check.Label>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col md="6" className="mb-3 d-flex justify-content-between">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Need Delivery?</Form.Label>
                                                <div className="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline">
                                                    <div className="custom-switch-inner">
                                                        <Form.Check.Input bsPrefix="custom-control-input bg-success" id="customSwitch-23" checked={orderData ? orderData.customer.needDelivery : false} readOnly />
                                                        <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch-23" data-on-label="Yes" data-off-label="No"></Form.Check.Label>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Form>

                                        <div className='wrapper clearfix 2'>
                                            <div className="wizard-content-item text-center mb-4">
                                                <h4>Ordered Items</h4>
                                            </div>
                                            <table className="wojo small segment responsive table">
                                                <thead className="thead-dark">
                                                    <tr>
                                                        <th>SL No</th>
                                                        <th>Product Name</th>
                                                        <th>Quantity</th>
                                                        <th>Stock Status</th>
                                                        <th>Tax Rate</th>
                                                        <th>Item Cost</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody id='ivTable'>
                                                    {orderData && orderData.items && orderData.items.map((data, i) => {
                                                        return (
                                                            <tr key={i} data-id={i + 1} className={i % 2 === 0 ? "table-active" : "table-success"}>
                                                                <td style={{ padding: '23px 10px', fontStyle: 'italic', fontWeight: '600' }}>
                                                                    {i + 1}
                                                                </td>
                                                                <td>
                                                                    {data.productData && data.productData.productName}
                                                                </td>
                                                                <td>
                                                                    {data.quantity}
                                                                </td>
                                                                <td>
                                                                    <span className={`badge ${data.productData && data.productData.stockStatus === 'In Stock' ? 'bg-success' : 'bg-danger'}`}>
                                                                        {data.productData && data.productData.stockStatus}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {data.productData && `${data.productData.taxRate}%`}
                                                                </td>
                                                                <td>
                                                                    {data.price}
                                                                </td>
                                                                <td>
                                                                    {data.total}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td colSpan="5" style={{ textAlign: "right", fontWeight: "bold" }}>Sub Total</td>
                                                        <td>{orderData ? orderData.totals.subtotal : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="5" style={{ textAlign: "right", fontWeight: "bold" }}>Taxes</td>
                                                        <td>{orderData ? orderData.totals.taxes : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="5" style={{ textAlign: "right", fontWeight: "bold" }}>Total Amount</td>
                                                        <td className='wojo demi text positive'>{orderData ? orderData.totals.total : 0}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold0 d-flex align-items-center">Installation Details for {orderid}</h4>
                    </Col>
                    <Col lg="12">
                        <div className="card">
                            <div className="card-body">
                                <Row>
                                    <Col lg="12">
                                        {installationData ? (
                                            <form className="row g-3">
                                                <Col md="6" className="mb-3">
                                                    <label className="font-weight-bold text-muted text-uppercase">Installation Name</label>
                                                    <input type="text" className="form-control" value={installationData.installationDetails.vendorName} readOnly />
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <label className="font-weight-bold text-muted text-uppercase">Order Number</label>
                                                    <input type="text" className="form-control" value={installationData.orderNumber} readOnly />
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <label className="font-weight-bold text-muted text-uppercase">Address</label>
                                                    <input type="text" className="form-control" value={installationData.installationDetails.vendorAddress} readOnly />
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <label className="font-weight-bold text-muted text-uppercase">Phone</label>
                                                    <input type="text" className="form-control" value={installationData.installationDetails.vendorPhoneNumber} readOnly />
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <label className="font-weight-bold text-muted text-uppercase">Mail Id</label>
                                                    <input type="email" className="form-control" value={installationData.installationDetails.vendorEmailId} readOnly />
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <label className="font-weight-bold text-muted text-uppercase">Status</label>
                                                    <input type="text" className="form-control" value={installationData.status} readOnly />
                                                </Col>
                                                <Col md="12" className="mb-3">
                                                    <label className="font-weight-bold text-muted text-uppercase">Remark</label>
                                                    <textarea className="form-control" value={installationData.remark} readOnly></textarea>
                                                </Col>
                                            </form>
                                        ) : (
                                            <div>No data found</div>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold0 d-flex align-items-center">Delivery Data for {orderid}</h4>
                    </Col>
                    <Col lg="12">
                        <div className="card">
                            <div className="card-body">
                                <Form className="row g-3">
                                    {deliveryDetails ? (
                                        <>
                                            {deliveryDetails.orderType === 'Internal' ? (
                                                <Col md="6" className="mb-3">
                                                    <Form.Label className="font-weight-bold text-muted text-uppercase">Courier Person Name</Form.Label>
                                                    <Form.Control type="text" value={deliveryDetails.courierPersonName || 'No data to display'} readOnly />
                                                </Col>
                                            ) : (
                                                <>
                                                    <Col md="6" className="mb-3">
                                                        <Form.Label className="font-weight-bold text-muted text-uppercase">Courier Name</Form.Label>
                                                        <Form.Control type="text" value={deliveryDetails.courierDetails.deliveryName || 'No data to display'} readOnly />
                                                    </Col>
                                                </>
                                            )}
                                            {deliveryDetails.orderType === 'External' && (
                                                <Col md="6" className="mb-3">
                                                    <Form.Label className="font-weight-bold text-muted text-uppercase">POD Number</Form.Label>
                                                    <Form.Control type="text" value={deliveryDetails.podNumber || 'No data to display'} readOnly />
                                                </Col>
                                            )}
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Type</Form.Label>
                                                <Form.Control type="text" value={deliveryDetails.orderType || 'No data to display'} readOnly />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Challan Number</Form.Label>
                                                <Form.Control type="text" value={deliveryDetails.challanNumber || 'No data to display'} readOnly />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Challan Date</Form.Label>
                                                <Form.Control type="text" value={deliveryDetails.challanDate ? new Date(deliveryDetails.challanDate).toLocaleDateString() : 'No data to display'} readOnly />
                                            </Col>
                                            {deliveryDetails.barcodeDetails.map((barcode, index) => (
                                                <Col md="12" className="mb-3" key={index}>
                                                    <Form.Label className="font-weight-bold text-muted text-uppercase">
                                                        Material Batch Number - {barcode.productName}
                                                    </Form.Label>
                                                    <Form.Control as="select" multiple readOnly>
                                                        {barcode.codes.map((code) => (
                                                            <option key={code._id} value={code.code}>
                                                                {code.code} - {code.serialNumber}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </Col>
                                            ))}
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Order Delivery</Form.Label>
                                                <Form.Control type="text" value={deliveryDetails.orderDelivery || 'No data to display'} readOnly />
                                            </Col>
                                            <Col md="12" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Delivery Note</Form.Label>
                                                <Form.Control as="textarea" rows={3} value={deliveryDetails.deliveryNote || 'No data to display'} readOnly />
                                            </Col>
                                        </>
                                    ) : (
                                        <Col md="12">
                                            <div>No data to display</div>
                                        </Col>
                                    )}
                                </Form>
                            </div>
                        </div>
                    </Col>

                    {roles === 'accountant' && (
                        <><Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold0 d-flex align-items-center">Add Invoice for {orderid}</h4>
                        </Col>
                            <Col lg="12">
                                <div className="card">
                                    <div className="card-body">
                                        <Form className="row g-3" onSubmit={handleSubmit}>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Invoice Number</Form.Label>
                                                <Form.Control type="text" value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Date</Form.Label>
                                                <Form.Control type="date" value={invoiceDate} onChange={(e) => setInvoiceDate(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Submission Date</Form.Label>
                                                <Form.Control type="date" value={submissionDate} onChange={(e) => setSubmissionDate(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Ref</Form.Label>
                                                <Form.Control type="text" value={invoiceRef} onChange={(e) => setInvoiceRef(e.target.value)} />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Attachment</Form.Label>
                                                <Form.Control type="file" onChange={(e) => setInvoiceAttachment(e.target.files[0])} />
                                            </Col>
                                        </Form>
                                        <div className="d-flex justify-content-start mt-1">
                                            <Button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                                                Add Invoice
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                        </>
                    )}

                </Row>
            </Container >
        </>
    )
}
export default ViewDetails;