import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import Swal from 'sweetalert2';
import { decode } from 'jsonwebtoken';

const ViewInvoice = () => {
    const [invoice, setInvoice] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const { id, orderId } = useParams();

    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [submissionDate, setSubmissionDate] = useState('');
    const [invoiceRef, setInvoiceRef] = useState('');
    const [invoiceAttachment, setInvoiceAttachment] = useState(null);

    const token = localStorage.getItem('token');
    let roles = '';
    if (token) {
        const decodedToken = decode(token);
        roles = decodedToken.role;
    }

    const fetchInvoice = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/invoices/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const invoiceData = response.data.length > 0 ? response.data[0] : null;
            if (invoiceData) {
                setInvoice(invoiceData);
                setInvoiceNumber(invoiceData.invoiceNumber);
                setInvoiceDate(invoiceData.invoiceDate.split('T')[0]);
                setSubmissionDate(invoiceData.submissionDate.split('T')[0]);
                setInvoiceRef(invoiceData.invoiceRef);
                setInvoiceAttachment(invoiceData.invoiceAttachment);
                console.log(invoiceAttachment);
            } else {
                console.error("No invoice data found.");
            }
            setLoading(false);
        } catch (error) {
            if (error.response.data.error === 'Token expired') {
                Swal.fire({
                    title: 'Session expired',
                    text: 'Your session has expired. Please log in again.',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.removeItem('token');
                        history.push('/');
                    }
                }).catch((error) => {
                    console.error("Error during session expiration handling: ", error);
                });
            } else if (error.response.data.error === 'Invalid token') {
                Swal.fire({
                    title: 'Invalid token',
                    text: 'Your token is invalid. Please log in again.',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.removeItem('token');
                        history.push('/');
                    }
                }).catch((error) => {
                    console.error("Error during session expiration handling: ", error);
                });
            } else {
                console.error("Error fetching invoice: ", error);
                Swal.fire(
                    'Error!',
                    'There was an issue fetching the invoice.',
                    'error'
                );
            }
        }
    };
    useEffect(() => {
        fetchInvoice();
    }, [id, history]);

    return (
        <>
            <Col lg="12" className="mb-3 d-flex justify-content-between align-items-center">
                <h4 className="font-weight-bold d-flex align-items-center">View Invoice for {orderId}</h4>
                {roles === 'admin' ? (
                    <Link to="/order" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2">Back</span>
                    </Link>
                ) : (
                    <Link to="/order-list" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2">Back</span>
                    </Link>
                )}
            </Col>
            <Col lg="12">
                <div className="card">
                    <div className="card-body">
                        {invoiceNumber || invoiceDate || submissionDate || invoiceRef || invoiceAttachment ? (
                            <Form className="row g-3">
                                <Col md="6" className="mb-3">
                                    <Form.Label className="font-weight-bold text-muted text-uppercase">Invoice Number</Form.Label>
                                    <Form.Control type="text" value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} />
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Form.Label className="font-weight-bold text-muted text-uppercase">Date</Form.Label>
                                    <Form.Control type="date" value={invoiceDate} onChange={(e) => setInvoiceDate(e.target.value)} />
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Form.Label className="font-weight-bold text-muted text-uppercase">Submission Date</Form.Label>
                                    <Form.Control type="date" value={submissionDate} onChange={(e) => setSubmissionDate(e.target.value)} />
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Form.Label className="font-weight-bold text-muted text-uppercase">Ref</Form.Label>
                                    <Form.Control type="text" value={invoiceRef} onChange={(e) => setInvoiceRef(e.target.value)} />
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Form.Label className="font-weight-bold text-muted text-uppercase">Attachment</Form.Label>
                                    <div className="d-flex align-items-center">
                                        <Button variant="outline-secondary" onClick={() => window.open(`${API_URL}/${invoiceAttachment}`, '_blank')}>
                                            <i className="fas fa-eye"></i> View Attachment
                                        </Button>
                                    </div>
                                </Col>
                            </Form>
                        ) : (
                            <div>No Invoice found for this order</div>
                        )}
                    </div>
                </div>

            </Col>
        </>
    )
}
export default ViewInvoice;

