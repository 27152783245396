import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';
//datepicker
import Datepickers from '../../../components/Datepicker';
import axios from 'axios';
import { API_URL } from '../../../config';
import { Dropdown } from 'react-bootstrap';



const TableAdd = () => {

    const history = useHistory();
    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([{}]);
    const [tableName, setTableName] = useState("");

    const addHeader = () => {
        Swal.fire({
            title: 'Enter header name',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Add',
            showLoaderOnConfirm: true,
            preConfirm: (header) => {
                if (header) {
                    setHeaders(oldHeaders => [...oldHeaders, header]);
                    setRows(oldRows => oldRows.map(row => ({ ...row, [header]: "" })));
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        });
    };

    const deleteHeader = (headerToDelete) => {
        setHeaders(oldHeaders => oldHeaders.filter(header => header !== headerToDelete));
        setRows(oldRows => oldRows.map(row => {
            const { [headerToDelete]: deletedValue, ...remainingRow } = row;
            return remainingRow;
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const tableData = {
            tableName,
            headers,
            rows
        };

        const token = localStorage.getItem('token');
        if (!token) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Session expired. Please login again.',
            });
            history.push('/login');
            return;
        }

        try {
            const response = await axios.post(`${API_URL}/table-add`, tableData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.message === 'Table data saved successfully') {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Table data added successfully!',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message,
                });
            }
        }
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/customer">Table</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Table</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/tables" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold0 d-flex align-items-center">Add New Table</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="12">
                                        <div className="d-flex justify-content-end mb-3">
                                            <Button variant="btn btn-primary btn-sm" onClick={addHeader}>
                                                Add Headers
                                            </Button>
                                        </div>
                                        <Form className="row g-3 date-icon-set-modal" onSubmit={handleSubmit}>
                                            <Form.Group controlId="tableName">
                                                <Form.Label>Table Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter table name" value={tableName} onChange={e => setTableName(e.target.value)} />
                                            </Form.Group>
                                            <div className="table-responsive">
                                                <table className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="text-dark">
                                                            {headers.map((header, index) => (
                                                                <th key={index}>
                                                                    {header}
                                                                    <Button variant="danger" size="sm" onClick={() => deleteHeader(header)} style={{ borderRadius: "70%", padding: "0.1rem 0.25rem", fontSize: "0.7rem", fontWeight: "bold", boxShadow: "0px 0px 5px rgba(0,0,0,0.2)" }}>
                                                                        <span>&times;</span>
                                                                    </Button>
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows.map((row, rowIndex) => (
                                                            <tr className="text-light" key={rowIndex}>
                                                                {headers.map((header, index) => (
                                                                    <td key={index}>
                                                                        <Dropdown onSelect={(e) => {
                                                                            if (row && header) {
                                                                                const newRows = [...rows];
                                                                                newRows[rowIndex][header] = e;
                                                                                setRows(newRows);
                                                                            }
                                                                        }}>
                                                                            <Dropdown.Toggle variant="success" id={`dropdown-basic-${rowIndex}-${index}`}>
                                                                                {row[header] || "Select item"}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                                                                                <Dropdown.Item eventKey="None">None</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Customer Name">Customer Name</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Status">Status</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Material">Material</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Address">Address</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Contact Number">Contact Number</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Location">Location</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Vendor Name">Vendor Name</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Vendor Email">Vendor Email</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Courier Name"> Courier Name</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Delivery Address">Delivery Address</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Date">Date</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Quantity">Quantity</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Delivery Mail">Delivery Mail</Dropdown.Item>
                                                                                <Dropdown.Item eventKey="Vendor Number">Vendor Number</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <Button variant="btn btn-primary" onClick={handleSubmit}>
                                                    Save
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >

        </>
    )
}

export default TableAdd;
