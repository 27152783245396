import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import { connect } from "react-redux";
import { getDarkMode } from '../../../store/mode'
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { decode } from 'jsonwebtoken';
import logo from '../../../assets/images/logo.png'
import darklogo from '../../../assets/images/logo.png'
import { API_URL } from '../../../config';

function mapStateToProps(state) {
   return {
      darkMode: getDarkMode(state)
   };
}



const SignIn = (props) => {
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   let history = useHistory();

   useEffect(() => {
      if (history.location.state?.fromPrivateRoute) {
         alert('Please login to continue');
      }
   }, [history]);

   const handleSubmit = async (event) => {
      event.preventDefault();
      const schema = yup.object().shape({
         email: yup.string().email().required(),
         password: yup.string().min(5).required(),
      });
      try {
         await schema.validate({ email, password });

         const response = await axios.post(`${API_URL}/api/login`, {
            email,
            password
         });

         if (response.status === 200 && response.data.token) {
            localStorage.setItem('token', response.data.token);

            // Decode the token to get the user's roles
            const decodedToken = decode(response.data.token);
            const userRole = decodedToken.role;

            // Check if there's a redirect target in the state
            const { from } = history.location.state || { from: { pathname: '/' } };

            // Redirect the user based on their roles or to the originally requested page
            let redirectPath = from.pathname; // Default to the path from the state if available
            if (userRole === 'admin') {
               redirectPath = '/dashboard';
            } else if (userRole === 'employee') {
               redirectPath = '/employee-dashboard';
            } else if (userRole === 'accountant') {
               redirectPath = '/order-list';
            } else if (userRole === 'delivery') {
               redirectPath = '/delivery-list';
            }

            history.push(redirectPath);
         } else {
            console.error('Login failed');
            Swal.fire('Error', response.data.message, 'error');
         }
      } catch (error) {
         if (error instanceof yup.ValidationError) {
            // Validation error
            Swal.fire('Error', error.message, 'error');
         } else {
            // Other error (e.g., network error)
            console.error('An error occurred while logging in', error);
            Swal.fire('Error', error.response.data.message, 'error');
         }
      }
   };

   return (
      <>
         <section className="login-content">
            <Container className="h-100">
               <Row className="align-items-center justify-content-center h-100">
                  <Col md="5">
                     <Card className="p-3">
                        <Card.Body>
                           <div className="auth-logo">
                              <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} alt="logo" />
                              <img src={darklogo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                           </div>
                           <h3 className="mb-3 font-weight-bold text-center">Sign In</h3>
                           <p className="text-center text-secondary mb-4">Log in to your account to continue</p>
                           <Form onSubmit={handleSubmit}>
                              <Row>
                                 <Col lg="12">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Email</Form.Label>
                                       <Form.Control type="email" placeholder="Enter Email" value={email} onChange={e => setEmail(e.target.value)} />
                                    </Form.Group>
                                 </Col>
                                 <Col lg="12" className="mt-2">
                                    <Form.Group>
                                       <div className="d-flex justify-content-between align-items-center">
                                          <Form.Label className="text-secondary">Password</Form.Label>
                                       </div>
                                       <Form.Control type="password" placeholder="Enter Password" value={password} onChange={e => setPassword(e.target.value)} />
                                    </Form.Group>
                                 </Col>
                              </Row>
                              <Button type="submit" onClick={() => history.push('/')} variant="btn btn-primary btn-block mt-2">Log In</Button>

                           </Form>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </section>
      </>
   )
}

export default connect(mapStateToProps)(SignIn)