import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
//datepicker
import Datepickers from '../../../components/Datepicker';
import axios from 'axios';
import { API_URL } from '../../../config';

const DeliveryDataEdit = () => {
    const [deliveryName, setDeliveryName] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [filename, setFile] = useState(null);
    const history = useHistory();
    const { id } = useParams();
    const fetchDeliveryData = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            Swal.fire({
                icon: 'warning',
                title: 'Unauthorized',
                text: 'You are not authorized to view this data. Please log in.',
            });
            history.push('/login');
            return;
        }

        try {
            const response = await axios.get(`${API_URL}/api/delivery-data/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data) {
                // Assuming the response data has the fields we need to set the state
                setDeliveryName(response.data.deliveryName || '');
                const formattedDate = new Date(response.data.deliveryDate).toISOString().split('T')[0];
                setDeliveryDate(formattedDate);
                setFile(response.data.filename || null);
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/login');
                        }
                    }).catch((error) => {
                        console.error('Error:', error);
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Failed to fetch delivery data.',
                    }).catch((error) => {
                        console.error('Error:', error);
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An unexpected error occurred.',
                }).catch((error) => {
                    console.error('Error:', error);
                });
            }
        }
    };

    useEffect(() => {
        fetchDeliveryData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!deliveryName || !deliveryDate || !filename) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All fields are required',
            });
            return;
        }

        const formData = new FormData();
        formData.append('deliveryName', deliveryName);
        formData.append('deliveryDate', deliveryDate);

        const file = document.getElementById('customFile').files[0];
        formData.append('filename', file);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.put(`${API_URL}/api/delivery-data/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.message === 'Data Updated') {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Delivery data Updated successfully!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        history.push('/delivery');
                    }
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message,
                });
            }
        }

    };

    return (
        <Container fluid>
            <Col lg="12">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb p-0 mb-0">
                                <li className="breadcrumb-item"><Link to="/customer">Delivery Data</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Add Delivery Data</li>
                            </ol>
                        </nav>
                    </div>
                    <Link to="/delivery-list" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2">Back</span>
                    </Link>
                </div>
            </Col>
            <Col lg="12" className="mb-3 d-flex justify-content-between">
                <h4 className="font-weight-bold0 d-flex align-items-center">Add New Data</h4>
            </Col>
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="6" className="mb-3">
                                        <Form.Label className="font-weight-bold text-muted text-uppercase">Delivery Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter delivery name" value={deliveryName} onChange={e => setDeliveryName(e.target.value)} />
                                    </Col>
                                    <Col md="6" className="mb-3">
                                        <Form.Group controlId="deliveryDate">
                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Delivery Date</Form.Label>
                                            <Form.Control type="date" value={deliveryDate} onChange={e => setDeliveryDate(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" className="mb-3">
                                        <Form.Label htmlFor="customFile" className="font-weight-bold text-muted text-uppercase">Upload File</Form.Label>
                                        <Form.File custom className="mb-3">
                                            <Form.File.Input id="customFile" name="filename" onChange={e => setFile(e.target.files[0])} />
                                            <Form.File.Label htmlFor="customFile">Choose file</Form.File.Label>
                                        </Form.File>
                                    </Col>
                                    <Col md="12" className="mb-3">
                                        <Button variant="primary" type="submit">Submit</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default DeliveryDataEdit;
