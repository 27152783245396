import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Modal, Pagination } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

const Product = () => {

	const [Products, setProducts] = React.useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const history = useHistory();
	const [showModal, setShowModal] = useState(false);
	const [refreshData, setRefreshData] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [filter, setFilter] = useState('all');
	const rowsPerPage = 15;
	const totalRows = Products.length;
	const totalPages = Math.ceil(totalRows / rowsPerPage);

	console.log(Products.length);
	const fetchProducts = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/products`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setProducts(response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			}
		}
	};
	React.useEffect(() => {
		fetchProducts();
	}, []);

	const handleDelete = async (id) => {
		const token = localStorage.getItem('token');
		const result = await Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		})

		if (result.isConfirmed) {
			try {
				const response = await axios.delete(`${API_URL}/api/products/${id}`, {
					headers: {
						'Authorization': `Bearer ${token}`
					}
				});

				if (response.status === 200) {
					Swal.fire(
						'Deleted!',
						'The Product has been deleted.',
						'success'
					)
					// Refresh the products list
					fetchProducts();
				} else {
					Swal.fire(
						'Error!',
						'There was an error deleting the Product.',
						'error'
					)
				}
			} catch (error) {
				if (error.response && error.response.status === 403) {
					if (error.response.data.error === 'Token expired') {
						Swal.fire({
							title: 'Session expired',
							text: 'Your session has expired. Please log in again.',
							icon: 'warning',
							confirmButtonText: 'OK'
						}).then((result) => {
							if (result.isConfirmed) {
								localStorage.removeItem('token');
								history.push('/');
							}
						}).catch((error) => {
							console.error("Error during session expiration handling: ", error);
						});
					} else if (error.response.data.error === 'Invalid token') {
						Swal.fire({
							title: 'Invalid token',
							text: 'Your token is invalid. Please log in again.',
							icon: 'warning',
							confirmButtonText: 'OK'
						}).then((result) => {
							if (result.isConfirmed) {
								localStorage.removeItem('token');
								history.push('/');
							}
						}).catch((error) => {
							console.error("Error during session expiration handling: ", error);
						});
					}
				}
			}
		}
	};

	const generateQRCode = (productName, quantity) => {
		const cleanedProductName = productName.replace(/\s/g, '');
		const baseCode = cleanedProductName.substring(0, 4).toUpperCase();
		const codes = [];
		for (let i = 0; i < quantity; i++) {
			const time = new Date().getTime().toString().slice(-3);
			const date = new Date().getDate().toString().padStart(2, '0');
			const code = `${baseCode}${time}${date}${i}`;
			codes.push(code);
		}
		return codes;
	};

	const handleGenerateAndSendBarcodes = async (productName, quantity, productId) => {
		const token = localStorage.getItem('token');
		const codes = generateQRCode(productName, quantity);
		try {
			const response = await fetch(`${API_URL}/api/barcodes`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				},
				body: JSON.stringify({ codes, productId, quantity }),
			});

			const responseData = await response.json();
			if (!response.ok) {
				if (response.status === 403) {
					if (responseData.error === 'Token expired' || responseData.error === 'Invalid token') {
						Swal.fire({
							title: responseData.error === 'Token expired' ? 'Session expired' : 'Invalid token',
							text: `Your ${responseData.error === 'Token expired' ? 'session has expired' : 'token is invalid'}. Please log in again.`,
							icon: 'warning',
							confirmButtonText: 'OK'
						}).then((result) => {
							if (result.isConfirmed) {
								localStorage.removeItem('token');
								history.push('/');
							}
						}).catch((error) => {
							console.error("Error during token expiration or invalid token handling: ", error);
						});
					}
				} else if (response.status === 409) {
					Swal.fire({
						title: 'Conflict',
						text: responseData.message,
						icon: 'error',
						confirmButtonText: 'OK'
					});
				} else {
					throw new Error('Failed to send barcodes to the database due to an error');
				}
			} else {
				setRefreshData(prev => !prev);
				Swal.fire({
					title: 'Success',
					text: responseData.message,
					icon: 'success',
					confirmButtonText: 'OK'
				});
			}
		} catch (error) {
			console.error('Error sending barcodes to the database:', error);
			Swal.fire({
				title: 'Error',
				text: 'There was an error sending the barcodes to the database.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
		}
	};

	const [barcodes, setBarcodes] = useState([]);

	const fetchBarcodes = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/barcodes`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setBarcodes(response.data);
			console.log('Barcodes fetched successfully:', response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.message === 'Forbidden - User not authenticated' || error.response.data.message === 'Forbidden - Insufficient permissions') {
					Swal.fire({
						title: 'Session expired or Invalid Permissions',
						text: 'Your session has expired or you do not have permission to view this data. Please log in again or contact your administrator.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/login');
						}
					}).catch((error) => {
						console.error("Error during session expiration or permission handling: ", error);
					});
				}
			} else {
				console.error('Error fetching barcodes:', error);
			}
		}
	};

	useEffect(() => {
		fetchBarcodes();
	}, []);

	useEffect(() => {
		fetchBarcodes();
	}, [refreshData]);

	const exportTableToExcel = (filename) => {
		const aoa = [
			['Product Name', 'Category', 'Price', 'Stock Status']
		];

		Products.forEach(product => {
			aoa.push([
				product.productName,
				product.category,
				product.price,
				product.stockStatus
			]);
		});

		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(aoa);
		XLSX.utils.book_append_sheet(wb, ws, 'Products');
		XLSX.writeFile(wb, `${filename}.xlsx`);
	};
	return (
		<>
			<Container fluid>
				<Row>

					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">Purchase Inventory</h4>
							</div>
							<div className="create-workform">
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className="modal-product-search d-flex">
										<Form className="mr-3 position-relative">
											<Form.Group className="mb-0">
												<input
													type="text"
													className="form-control"
													id="exampleInputText"
													placeholder="Search Order"
													value={searchTerm}
													onChange={(e) => setSearchTerm(e.target.value)}
												/>
												<Link className="search-link" to="#">
													<svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
													</svg>
												</Link>
											</Form.Group>
										</Form>
										<Link to="/product-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
											<svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
											Add Product
										</Link>
									</div>
								</div>
							</div>
						</div>

						<Row>
							<Col lg="12">
								<Card className="card-block card-stretch">
									<Card.Body className="p-0">
										<div className="d-flex justify-content-between align-items-center p-3">
											<h5 className="font-weight-bold">Purchase Inventory List</h5>
											<div className='d-flex align-items-right justify-content-between'>
												<select
													className="form-control"
													value={filter}
													onChange={(e) => setFilter(e.target.value)}
													style={{ width: 'auto', display: 'inline-block' }}
												>
													<option value="all">All</option>
													<option value="inStock">In Stock</option>
													<option value="preorder">Preorder</option>
												</select>
											</div>
											<div className='d-flex align-items-center justify-content-between'>
												<Button variant="btn btn-secondary btn-sm" onClick={() => exportTableToExcel('Products')}>
													<svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
													</svg>
													Export
												</Button>
											</div>
										</div>
										<div className="table-responsive">
											<table className="table data-table mb-0">
												<thead className="table-color-heading">
													<tr className="text-light">
														<th scope="col">
															<label className="text-muted m-0" >Product Name</label>
															{/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="Search Product">  */}
														</th>
														<th scope="col">
															<label className="text-muted mb-0" >Category</label>

														</th>
														<th scope="col" className="text-right">
															<label className="text-muted mb-0" >Price</label>
															{/* <input type="text" className="form-control mt-2" id="text2" aria-describedby="textHelp" placeholder="Price">  */}
														</th>
														<th scope="col">
															<label className="text-muted mb-0" >Inventory</label>
														</th>
														<th scope="col">
															<label className="text-muted mb-0" >Stock Status</label>
														</th>
														<th scope="col">
															<span className="text-muted">Create Bar Code</span>
														</th>
														<th scope="col">
															<span className="text-muted">Download</span>
														</th>
														<th scope="col">
															<span className="text-muted">Added By</span>
														</th>
														<th scope="col" className="text-center">
															<span className="text-muted">Action</span>
														</th>
													</tr>
												</thead>
												<tbody>
													{Products
														.filter(item => {
															const searchTermMatch = item.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
																item.productCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
																item.productDescription.toLowerCase().includes(searchTerm.toLowerCase());
															if (filter === 'all') return searchTermMatch;
															if (filter === 'inStock') return searchTermMatch && item.stockStatus === 'In Stock';
															if (filter === 'preorder') return searchTermMatch && item.stockStatus === 'Preorder';
															return searchTermMatch;
														}).slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage).map((item) => (
															<tr key={item.Price} className="white-space-no-wrap">
																<td className="">
																	<div className="active-project-1 d-flex align-items-center mt-0 ">
																		<div className="h-avatar is-medium">
																			<img className="avatar rounded" alt="user-icon" src={encodeURI(`${API_URL}/uploads/${item.filename}`)} />
																		</div>
																		<div className="data-content">
																			<div>
																				<span className="font-weight-bold">{item.productName}</span>
																			</div>
																			<p className="m-0 mt-1">
																				{item.des}
																			</p>
																		</div>
																	</div>
																</td>
																<td>{item.category}</td>
																<td className="text-right">
																	{item.price}
																</td>
																<td>
																	<p className={`mb-0 font-weight-bold d-flex justify-content-start align-items-center ${item.quantity === 0 ? 'text-danger' : item.quantity > 0 && item.quantity < 10 ? 'text-warning' : 'text-success'}`}>
																		<small>
																			<svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
																				<circle cx="12" cy="12" r="8" fill={item.quantity === 0 ? "#dc3545" : item.quantity > 0 && item.quantity < 10 ? "#ffc107" : "#28a745"}></circle>
																			</svg>
																		</small>
																		{item.quantity}
																	</p>
																</td>
																<td>
																	<span className={`badge ${item.stockStatus === 'In Stock' ? 'bg-success' : 'bg-danger'}`}>
																		{item.stockStatus}
																	</span>
																</td>
																<td>
																	<Button variant="outline-primary" size="sm" onClick={() => handleGenerateAndSendBarcodes(item.productName, item.quantity, item._id)}>
																		Generate Bar Code
																	</Button>
																</td>
																<td>
																	{barcodes.find(barcode => barcode.productId === item._id) ? (
																		<Button variant="success" size="sm" onClick={() => {
																			const barcode = barcodes.find(barcode => barcode.productId === item._id);
																			if (barcode && barcode.pdfPath) {
																				window.open(`${API_URL}/${barcode.pdfPath}`, '_blank');
																			}
																		}}>
																			Download
																		</Button>
																	) : (
																		<span>Not generated</span>
																	)}
																</td>
																<td>
																	{item.addedByUserName}
																</td>
																<td>
																	<div className="d-flex justify-content-end align-items-center">
																		<Link to={`/assign-barcode/${item._id}`}>
																			<Button variant="outline-secondary" size="sm" onClick={(e) => {
																				e.preventDefault(); // Prevent the link from navigating
																				const barcodeExists = barcodes.some(barcode => barcode.productId === item._id);
																				if (!barcodeExists) {
																					Swal.fire({
																						title: 'No Barcode',
																						text: 'No barcode has been generated for this product. Please generate one first.',
																						icon: 'warning',
																						confirmButtonText: 'OK'
																					});
																				} else {
																					history.push(`/assign-barcode/${item._id}`);
																				}
																			}}>
																				Assign Barcode
																			</Button>
																		</Link>
																		<OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
																			<Link className="" to={`/product-edit/${item._id}`}>
																				<svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
																				</svg>
																			</Link>
																		</OverlayTrigger>
																		<OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
																			<button className="badge bg-danger" onClick={() => handleDelete(item._id)}>
																				<svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
																				</svg>
																			</button>
																		</OverlayTrigger>
																	</div>
																</td>
															</tr>
														))

													}
												</tbody>
											</table>
											<div className="d-flex justify-content-center">
												<Pagination className="mb-0">
													<Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
													{[...Array(totalPages).keys()].map(pageNumber => (
														<Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === currentPage} onClick={() => setCurrentPage(pageNumber + 1)}>
															{pageNumber + 1}
														</Pagination.Item>
													))}
													<Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
												</Pagination>
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default Product;
