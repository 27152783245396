import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ListGroup, Tab, Nav, Table, Modal } from 'react-bootstrap'
import moment from 'moment';// Import the CSS for the DatePicker if needed
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ThreeDots } from 'react-loader-spinner';
// img
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for the DatePicker if needed
import product1 from '../../../assets/images/products/1.jpg'
import product12 from '../../../assets/images/products/12.jpg'
import { API_URL } from '../../../config';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const Report = () => {
    const [showModal, setShowModal] = useState(false);
    const [reportData, setReportData] = useState({
        orders: [],
        installations: [],
        deliveries: []
    });
    const history = useHistory();

    const fetchOrders = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/reports`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setReportData(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            }
        }
    };


    const exportTableToExcel = (filename) => {
        const table = document.querySelector('.table-responsive-md');
        const rows = Array.from(table.querySelectorAll('tr'));
        const aoa = rows.map(row => {
            return Array.from(row.querySelectorAll('th, td')).map(cell => {
                return cell.innerText;
            });
        });

        // Create a workbook and add a worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(aoa);

        // Get range of the worksheet to apply styles
        const range = XLSX.utils.decode_range(ws['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                // If first row, set the font style to bold
                if (R === 0) {
                    const cell_address = { c: C, r: R };
                    const cell_ref = XLSX.utils.encode_cell(cell_address);
                    if (!ws[cell_ref]) continue;
                    ws[cell_ref].s = {
                        font: {
                            bold: true
                        }
                    };
                }
            }
        }

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, filename + '.xlsx');
    };

    React.useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/order">Order Summary</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Order Summary Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Details Summary</h4>
                    </Col>
                </Row>
                <Card>
                    <Card.Body className="p-0">
                        <div className="d-flex justify-content-between align-items-center p-3">
                            <h3>Detail Summary</h3>
                            <div className="ml-auto">
                                <Button variant="btn btn-secondary btn-sm" onClick={() => exportTableToExcel('Report')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                    </svg>
                                    Export
                                </Button>
                            </div>
                        </div>
                        <div id="table" className="table-editable" style={{ overflowX: 'scroll' }}>
                            <table className="table table-bordered table-responsive-md table-striped text-center">
                                <thead className="table-color-heading" style={{ backgroundColor: '#f2f2f2' }}>
                                    <tr style={{ borderColor: '#000000' }}>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Order ID</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Organization Name</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Branch</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>PO Number</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>PO Mailed Date</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Product Name</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Quantity</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Amount</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Vendor Name</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Vendor Phone Number</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Vendor Email Id</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Vendor Whatsapp No</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Delivery Name</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Delivery Email</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Delivery Whatsapp No</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Challan Number</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Ch Date</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>POD Number</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Invoice Number</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Invoice Ref Number</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Invoice</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Delivery Proof</th>
                                        <th style={{ borderColor: '#000000', wordWrap: 'break-word', }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody style={{ borderColor: '#000000' }}>
                                    {reportData && reportData.orders && reportData.orders.length > 0 ? reportData.orders.map((order, index) => (
                                        <tr key={index} dataid={order.orderId} style={{ borderColor: '#000000', wordWrap: 'break-word', backgroundColor: (order.statusInstall === 'pending' || order.statusDelivery === 'pending') ? '#FFCCCB' : (order.statusInstall === 'in_progress' || order.statusDelivery === 'in_progress') ? '#FFFF99' : (order.statusInstall === 'completed' && order.statusDelivery === 'completed') ? '#90EE90' : (order.statusInstall === 'cancelled' || order.statusDelivery === 'cancelled') ? '#D3D3D3' : 'transparent' }}>
                                            <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{order.orderId}</td>
                                            <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{order.customerData ? order.customerData.organizationName : 'N/A'}</td>
                                            <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                                {order.customerData && order.customerData.branches && order.customerData.branches.length > 0 ?
                                                    order.customerData.branches.map(branch => branch.branchAddress).filter(address => address).join(', ')
                                                    : order.customer && order.customer.branch ? order.customer.branch : 'N/A'}
                                            </td>
                                            <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{order.customer ? order.customer.poNo : 'N/A'}</td>
                                            <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{order.customer && order.customer.mailDate ? order.customer.mailDate.split('T')[0] : 'N/A'}</td>
                                            <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                                {order.items && order.items.length > 0 ? order.items.map(item => item.productDetails ? item.productDetails.productName : 'Unknown').join(', ') : 'N/A'}
                                            </td>
                                            <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{order.items && order.items.length > 0 ? order.items.map(item => item._doc.quantity).join(', ') : 'N/A'}</td>
                                            <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{order.totals ? order.totals.total : 'N/A'}</td>
                                            {reportData.installations && reportData.installations.filter(installation => installation.orderNumber === order.orderId).length > 0 ? (
                                                reportData.installations.filter(installation => installation.orderNumber === order.orderId).map(installation => (
                                                    <>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{installation.vendorDetails ? installation.vendorDetails.vendorName : 'N/A'}</td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{installation.vendorDetails ? installation.vendorDetails.vendorPhoneNumber : 'N/A'}</td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{installation.vendorDetails ? installation.vendorDetails.vendorEmailId : 'N/A'}</td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{installation.vendorDetails ? installation.vendorDetails.vendorWhatsappNo : 'N/A'}</td>
                                                    </>
                                                ))
                                            ) : (
                                                <>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                </>
                                            )}

                                            {reportData.deliveries && reportData.deliveries.filter(delivery => delivery.orderNumber === order.orderId).length > 0 ? (
                                                reportData.deliveries.filter(delivery => delivery.orderNumber === order.orderId).map(delivery => (
                                                    <>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{delivery.courierDetails ? delivery.courierDetails.deliveryName : 'N/A'}</td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{delivery.courierDetails ? delivery.courierDetails.deliveryEmailId : 'N/A'}</td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{delivery.courierDetails ? delivery.courierDetails.deliveryWhatsappNo : 'N/A'}</td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{delivery.challanNumber || 'N/A'}</td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{delivery.challanDate ? new Date(delivery.challanDate).toLocaleDateString() : 'N/A'}</td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{delivery.podNumber || 'N/A'}</td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{order.invoiceData && order.invoiceData.invoiceNumber ? order.invoiceData.invoiceNumber : 'N/A'}</td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>{order.invoiceData && order.invoiceData.invoiceRef ? order.invoiceData.invoiceRef : 'N/A'}</td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                                            {order.invoiceData && order.invoiceData.invoiceAttachment ? (
                                                                <button onClick={() => setShowModal(true)}>View</button>
                                                            ) : 'N/A'}
                                                            <Modal show={showModal} onHide={() => setShowModal(false)}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Invoice Attachment</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    {order.invoiceData && order.invoiceData.invoiceAttachment && (
                                                                        <a href={`${API_URL}/${order.invoiceData.invoiceAttachment}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#007bff' }}>Click on this to open file in new tab</a>
                                                                    )}
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                                                                        Close
                                                                    </Button>
                                                                </Modal.Footer>
                                                            </Modal>
                                                        </td>
                                                        <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                                            {order.deliveryData && order.deliveryData.filename ? (
                                                                <button onClick={() => window.open(`${API_URL}/uploads/${order.deliveryData.filename}`, '_blank')}>View</button>
                                                            ) : 'N/A'}
                                                        </td>
                                                    </>
                                                ))
                                            ) : (
                                                <>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                    <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>N/A</td>
                                                </>
                                            )}
                                            <td style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                                <div style={{ width: '100%' }}>
                                                    {order.statusInstall === 'pending' || order.statusDelivery === 'pending' ? 'Pending' :
                                                        order.statusInstall === 'in_progress' || order.statusDelivery === 'in_progress' ? 'In Progress' :
                                                            order.statusInstall === 'completed' && order.statusDelivery === 'completed' ? 'Completed' :
                                                                order.statusInstall === 'cancelled' || order.statusDelivery === 'cancelled' ? 'Cancelled' : 'N/A'}
                                                </div>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                            <td colSpan="23" style={{ borderColor: '#000000', wordWrap: 'break-word', whiteSpace: 'normal' }}>No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </Card.Body>
                </Card >
            </Container >
        </>
    )
}

export default Report;
