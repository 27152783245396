import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { API_URL } from '../../../config';
import Card from '../../../components/Card';

const AssignMultiple = () => {
	const history = useHistory();
	const [employees, setEmployees] = useState([]);
	const [customers, setCustomers] = useState([]);
	const [selectedEmployee, setSelectedEmployee] = useState('');
	const [selectedCompanies, setSelectedCompanies] = useState([]);


	const handleEmployeeChange = (event) => {
		setSelectedEmployee(event.target.value);
	};

	const handleCompanyChange = (event) => {
		const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
		setSelectedCompanies(selectedOptions);
	};
	const fetchEmployees = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/employees`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setEmployees(response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			} else {
				Swal.fire(
					'Error!',
					'There was an error fetching the employees.',
					'error'
				)
			}
		}
	};

	useEffect(() => {
		fetchEmployees();
	}, []);


	const fetchCustomers = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/customers-unassigned`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setCustomers(response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			} else {
				Swal.fire(
					'Error!',
					'There was an error fetching the customers.',
					'error'
				)
			}
		}
	};

	useEffect(() => {
		fetchCustomers();
	}, []);


	const assignCustomerToEmployee = async (customerId, employeeId) => {
		if (selectedCompanies.length === 0) {
			Swal.fire(
				'Validation Error',
				'Please select at least one company.',
				'error'
			);
			return;
		}
		if (!selectedEmployee) {
			Swal.fire(
				'Validation Error',
				'Please select an employee.',
				'error'
			);
			return;
		}
		const token = localStorage.getItem('token');
		try {
			const response = await axios.post(`${API_URL}/api/assign-customers`, {
				customerIds: selectedCompanies,
				employeeId: selectedEmployee
			}, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			Swal.fire(
				'Assigned!',
				'The Company has been assigned.',
				'success'
			);
			await fetchCustomers();
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			} else {
				console.error("Error assigning order: ", error);
				Swal.fire(
					'Error!',
					'There was an issue assigning the order.',
					'error'
				);
			}
		}
	};

	return (
		<Container fluid>
			<Row>
				<Col lg="12">
					<div className="d-flex flex-wrap align-items-center justify-content-between">
						<div className="d-flex align-items-center justify-content-between">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb p-0 mb-0">
									<li className="breadcrumb-item"><Link to="/customer">Customers</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Assign Employee to Companies</li>
								</ol>
							</nav>
						</div>
						<Link to="/customer" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
							</svg>
							<span className="ml-2">Back</span>
						</Link>
					</div>
				</Col>
				<Col lg="12" className="mb-3 d-flex justify-content-between">
					<h4 className="font-weight-bold0 d-flex align-items-center">Assign Employee to Companies</h4>
				</Col>
				<Col lg="12">
					<Card>
						<Card.Body>
							<Form className="row g-3">
								<Col md="12">
									<Form.Group controlId="formEmployeeSelect">
										<Form.Label className="font-weight-bold text-muted text-uppercase">Single Select Employee</Form.Label>
										<Form.Control as="select" value={selectedEmployee} onChange={handleEmployeeChange} className="form-select form-control choicesjs">
											<option value="" hidden disabled>Select an Employee</option>
											{employees.filter(employee => employee.role == 'employee').map(employee => (
												<option key={employee._id} value={employee._id}>{employee.firstName} {employee.lastName}</option>
											))}
										</Form.Control>
									</Form.Group>
								</Col>
								<Col md="12">
									<Form.Group controlId="formCompanySelect">
										<Form.Label className="font-weight-bold text-muted text-uppercase">Multiple Select Companies</Form.Label>
										<Form.Control as="select" multiple value={selectedCompanies} onChange={handleCompanyChange} className="form-select form-control choicesjs">
											{customers.length > 0 ? (
												customers.map(customer => (
													<option key={customer._id} value={customer._id}>{customer.organizationName}</option>
												))
											) : (
												<option disabled>No companies found</option>
											)}
										</Form.Control>
									</Form.Group>
								</Col>
								<Col md="12" className="d-flex justify-content-end mt-3">
									<Button variant="btn btn-primary" onClick={assignCustomerToEmployee}>
										Assign
									</Button>
								</Col>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container >
	);
};

export default AssignMultiple;
