import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Tab, Nav } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link, useParams, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';
import { API_URL } from '../../../../config';
import axios from 'axios';

//img 
import user1 from '../../../../assets/images/user/1.jpg'

const UserProfileEdit = () => {
    const [fullName, setFullName] = useState('');
    const [gender, setGender] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [password, setPassword] = useState('');
    const [bio, setBio] = useState('');
    const [filename, setFile] = useState('');

    const { id } = useParams();
    const history = useHistory();

    const fetchEmployee = async () => {
        if (!id) {
            console.error('No ID provided');
            return;
        }
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/employees/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setFullName(response.data.fullName || '');
            setGender(response.data.gender || '');
            setCompany(response.data.company || '');
            setEmail(response.data.email || '');
            setPhone(response.data.phone || '');
            setCountry(response.data.country || '');
            setState(response.data.state || '');
            setAddress(response.data.address || '');
            setZipcode(response.data.zipcode || '');
            //setPassword(response.data.password || '');
            setBio(response.data.bio || '');
            setFile(response.data.filename || '');
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire(
                    'Error!',
                    'There was an error fetching the employee.',
                    'error'
                )
            }
        }
    };

    useEffect(() => {
        fetchEmployee();
    }, [id]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('fullName', fullName);
        formData.append('gender', gender);
        formData.append('company', company);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('country', country);
        formData.append('state', state);
        formData.append('address', address);
        formData.append('zipcode', zipcode);
        formData.append('password', password);
        //formData.append('address', address);
        //formData.append('filename', filename);
        if (filename) {
            formData.append('filename', filename);
        }

        const token = localStorage.getItem('token');

        try {
            const response = await axios.put(`${API_URL}/api/employees/${id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                Swal.fire(
                    'Updated!',
                    'Employee has been updated.',
                    'success'
                );
                //history.push('/employee');
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire(
                    'Error!',
                    'There was an error updating the employee.',
                    'error'
                )
            }
        }
    }

    return (
        <>
            <Container fluid>
                <Tab.Container defaultActiveKey="personal-information">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body className="p-0">
                                    <div className="mm-edit-list usr-edit">
                                        <Nav variant="pills" className="mm-edit-profile d-flex">
                                            <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="personal-information">Personal Information</Nav.Link>
                                            </li>
                                            <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="chang-pwd">Change Password</Nav.Link>
                                            </li>
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <div className="mm-edit-list-data">
                                <Tab.Content>
                                    <Tab.Pane eventKey="personal-information" role="tabpanel">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Personal Information</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row className="form-group  align-items-center">
                                                    <Col md="12">
                                                        <div className="profile-img-edit">
                                                            <div className="crm-profile-img-edit">
                                                                <img className="crm-profile-pic rounded-circle avatar-100" src={user1} alt="profile-pic" />
                                                                <div className="crm-p-image bg-primary">
                                                                    <i className="las la-pen upload-button"></i>
                                                                    <input className="file-upload" type="file" accept="image/*" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className=" align-items-center">
                                                    <Form className="row g-3 date-icon-set-modal" onSubmit={handleSubmit}>
                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Full Name</Form.Label>
                                                            <Form.Control type="text" id="Text1" placeholder="Enter Full Name" defaultValue={fullName} onChange={e => setFullName(e.target.value)} />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label className="font-weight-bold text-muted text-uppercase">Gender</Form.Label><br />
                                                            <div className="form-check form-check-inline">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <Form.Control type="radio" id="inlineRadio1" name="customRadio-1" className="custom-control-input" value="Male" onChange={e => setGender(e.target.value)} />
                                                                    <Form.Label className="custom-control-label" htmlFor="inlineRadio1"> Male </Form.Label>
                                                                </div>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <Form.Control type="radio" id="inlineRadio2" name="customRadio-1" className="custom-control-input" value="Female" onChange={e => setGender(e.target.value)} />
                                                                    <Form.Label className="custom-control-label" htmlFor="inlineRadio2"> Female </Form.Label>
                                                                </div>
                                                            </div>
                                                            <div className="form-check form-check-inline mt-2">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <Form.Control type="radio" id="inlineRadio3" name="customRadio-1" className="custom-control-input" value="Other" onChange={e => setGender(e.target.value)} />
                                                                    <Form.Label className="custom-control-label" htmlFor="inlineRadio3"> Other </Form.Label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                            <Form.Control type="text" id="Text4" placeholder="Enter Email" defaultValue={email} onChange={e => setEmail(e.target.value)} />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                            <Form.Control type="text" id="Text5" placeholder="Enter Phone" defaultValue={phone} onChange={e => setPhone(e.target.value)} />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label htmlFor="inputcountry" className="font-weight-bold text-muted text-uppercase">Country</Form.Label>
                                                            <select id="inputcountry" className="form-select form-control choicesjs" defaultValue={country} onChange={e => setCountry(e.target.value)} >
                                                                <option value="" hidden disabled>Select Country</option>
                                                                <option value="India">INDIA</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">State/Region</Form.Label>
                                                            <select id="inputState" className="form-select form-control choicesjs" defaultValue={state} onChange={e => setState(e.target.value)}>
                                                                <option value="" hidden disabled>Select State/Region</option>
                                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                                <option value="Assam">Assam</option>
                                                                <option value="Bihar">Bihar</option>
                                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                                <option value="Goa">Goa</option>
                                                                <option value="Gujarat">Gujarat</option>
                                                                <option value="Haryana">Haryana</option>
                                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                                <option value="Jharkhand">Jharkhand</option>
                                                                <option value="Karnataka">Karnataka</option>
                                                                <option value="Kerala">Kerala</option>
                                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                                <option value="Maharashtra">Maharashtra</option>
                                                                <option value="Manipur">Manipur</option>
                                                                <option value="Meghalaya">Meghalaya</option>
                                                                <option value="Mizoram">Mizoram</option>
                                                                <option value="Nagaland">Nagaland</option>
                                                                <option value="Odisha">Odisha</option>
                                                                <option value="Punjab">Punjab</option>
                                                                <option value="Rajasthan">Rajasthan</option>
                                                                <option value="Sikkim">Sikkim</option>
                                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                                <option value="Telangana">Telangana</option>
                                                                <option value="Tripura">Tripura</option>
                                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                                <option value="Uttarakhand">Uttarakhand</option>
                                                                <option value="West Bengal">West Bengal</option>
                                                            </select>
                                                        </div>

                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">Zipcode</Form.Label>
                                                            <Form.Control type="text" id="Text7" placeholder="Enter Zipcode" defaultValue={zipcode} onChange={e => setZipcode(e.target.value)} />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">Password</Form.Label>
                                                            <Form.Control type="password" id="Text7" placeholder="Enter Zipcode" defaultValue={password} onChange={e => setPassword(e.target.value)} />
                                                        </div>

                                                        <div className="col-md-12 mb-3">
                                                            <Form.Label htmlFor="Text9" className="font-weight-bold text-muted text-uppercase">Address</Form.Label>
                                                            <Form.Control as="textarea" id="Text9" rows="2" placeholder="Enter Address" defaultValue={address} onChange={e => setAddress(e.target.value)} />
                                                        </div>

                                                        <div className="col-md-12 mb-3">
                                                            <Form.Label htmlFor="Text6" className="font-weight-bold text-muted text-uppercase">Upload Photo</Form.Label>
                                                            <Form.File custom className="mb-3">
                                                                <Form.File.Input id="customFile" defaultValue={filename} name="filename" onChange={e => setFile(e.target.files[0])} />
                                                                <Form.File.Label htmlFor="customFile">Choose file</Form.File.Label>
                                                            </Form.File>
                                                        </div>
                                                        <div className="d-flex justify-content-end mt-3">
                                                            <Button variant="btn btn-primary" onClick={handleSubmit}>
                                                                Update Employee
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="chang-pwd" role="tabpanel">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Change Password</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group controlId="cpass">
                                                        <Form.Label>Current Password:</Form.Label>
                                                        <Link to="/" onClick={e => e.preventDefault()} className="float-right">Forgot Password</Link>
                                                        <Form.Control type="Password" />
                                                    </Form.Group>
                                                    <Form.Group controlId="npass">
                                                        <Form.Label>New Password:</Form.Label>
                                                        <Form.Control type="Password" />
                                                    </Form.Group>
                                                    <Form.Group controlId="vpass">
                                                        <Form.Label>Verify Password:</Form.Label>
                                                        <Form.Control type="Password" />
                                                    </Form.Group>
                                                    <Button type="reset" variant="outline-primary mr-2">Cancel</Button>
                                                    <Button type="submit" variant="primary">Submit</Button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    )
}

export default UserProfileEdit