import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import { Link, useParams, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';
import { API_URL } from '../../../config';
import axios from 'axios';

//datepicker
import Datepickers from '../../../components/Datepicker';

//circularpro
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Modal, Card, Container, Row, Col, Form, Pagination } from 'react-bootstrap';

// img
import image_url from '../../../assets/images/dashboard/dash.jpeg'



const EmpReport = () => {
    const [Orders, setOrders] = useState([]);
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);

    const rowsPerPage = 10;
    const totalRows = Orders.length;
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    const fetchTables = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/employee-orders`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setOrders(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            }
        }
    };
    useEffect(() => {
        fetchTables();
    }, []);

    return (
        <Container fluid>
            <Row >
                <Col>
                    <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="font-weight-bold">Report </h4>
                        </div>
                        <div className="create-workform">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="modal-product-search d-flex">

                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg="12" md="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h5>Order Summary</h5>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="table-color-heading">
                                        <tr className="text-secondary">
                                            <th scope="col">Company Name</th>
                                            <th scope="col">PO Number</th>
                                            <th scope="col">Branch</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Qty</th>
                                            <th scope="col">ETA</th>
                                            <th scope="col">Stock Status</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Orders.map((order) => (
                                            <tr className="white-space-no-wrap" key={order._id}>
                                                <td>{order.customerData.organizationName || 'NA'}</td>
                                                <td>{order.customer.poNo || 'NA'}</td>
                                                <td>{order.customer.branch ? (order.customerData.branches.find(branch => branch._id === order.customer.branch) || {}).branchAddress || 'NA' : 'NA'}</td>
                                                <td>{order.items.map(item => item.productData.productName).join(', ') || 'NA'}</td>
                                                <td>{order.items.reduce((total, item) => total + item.quantity, 0) || 'NA'}</td>
                                                <td>{order.customer.poMailedDate ? new Date(order.customer.poMailedDate).toLocaleDateString() : 'NA'}</td>
                                                <td>
                                                    {order.items.map((item, index) => (
                                                        <span key={index} className={`badge ${item.productData.stockStatus === 'In Stock' ? 'bg-success' : 'bg-danger'}`}>
                                                            {item.productData.stockStatus}
                                                        </span>
                                                    )).reduce((prev, curr, index) => [prev, <span key={`separator-${index}`}> </span>, curr], []) || 'NA'}
                                                </td>
                                                <td>{order.items.map(item => item.productData.productDescription).join(', ') || 'NA'}</td>
                                                <td>{order.customer.remarks || 'NA'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-center">
                                    <Pagination className="mb-0">
                                        <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
                                        {[...Array(totalPages).keys()].map(pageNumber => (
                                            <Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === currentPage} onClick={() => setCurrentPage(pageNumber + 1)}>
                                                {pageNumber + 1}
                                            </Pagination.Item>
                                        ))}
                                        <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
                                    </Pagination>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>


        </Container >
    )
}

export default EmpReport;

