import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import { Link, useParams, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';
import { API_URL } from '../../../config';
import axios from 'axios';

//datepicker
import Datepickers from '../../../components/Datepicker';

//circularpro
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Modal, Card, Container, Row, Col, Dropdown, Button, Popover, OverlayTrigger } from 'react-bootstrap';

// img
import image_url from '../../../assets/images/dashboard/dash.jpeg'



const Employeedashboard = () => {
    const [Orders, setOrders] = useState([]);
    const history = useHistory();
    const [show, setShow] = useState(false);


    const [dashboardDetails, setDashboardDetails] = useState({
        totalOrders: 0,
        totalEmployees: 0,
        lastFourCompletedOrders: [],
        totalRevenue: 0,
        upcomingInstallations: [],
        detailedUpcomingInstallations: [],
        customerDetailsForUpcomingInstallations: [],
    });

    const fetchTables = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/employee-orders`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setOrders(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            }
        }
    };
    useEffect(() => {
        fetchTables();
    }, []);

    const fetchDashboardDetails = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            Swal.fire({
                title: 'Session expired',
                text: 'Your session has expired. Please log in again.',
                icon: 'warning',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.removeItem('token');
                    history.push('/');
                }
            });
            return;
        }

        try {
            const response = await axios.get(`${API_URL}/api/dashboard-details`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setDashboardDetails(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                console.error("Error fetching dashboard details: ", error);
            }
        }
    };
    useEffect(() => {
        fetchDashboardDetails();
    }, []);

    const updateOrderStatus = async (orderId, newStatus) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to update the status?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const token = localStorage.getItem('token');
                try {
                    const response = await axios.put(`${API_URL}/api/update-order-status-delivery/${orderId}`, {
                        status: newStatus
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (response.status === 200) {
                        setOrders(Orders.map(order => {
                            if (order._id === orderId) {
                                return { ...order, status: newStatus };
                            }
                            return order;
                        }));
                        Swal.fire('Updated!', 'Order status updated successfully.', 'success');
                        await fetchTables();
                    } else {
                        Swal.fire('Error', 'Failed to update order status', 'error');
                    }
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        if (error.response.data.error === 'Token expired') {
                            Swal.fire({
                                title: 'Session expired',
                                text: 'Your session has expired. Please log in again.',
                                icon: 'warning',
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    localStorage.removeItem('token');
                                    history.push('/');
                                }
                            });
                        } else {
                            Swal.fire('Error', 'An error occurred while updating the order status', 'error');
                        }
                    } else {
                        console.error('Error updating order status: ', error);
                        Swal.fire('Error', 'An error occurred while updating the order status', 'error');
                    }
                }
            }
        });
    };

    const updateOrderStatusInstall = async (orderId, newStatus) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to update the status?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const token = localStorage.getItem('token');
                try {
                    const response = await axios.put(`${API_URL}/api/update-order-status-install/${orderId}`, {
                        status: newStatus
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (response.status === 200) {
                        setOrders(Orders.map(order => {
                            if (order._id === orderId) {
                                return { ...order, status: newStatus };
                            }
                            return order;
                        }));
                        Swal.fire('Updated!', 'Order status updated successfully.', 'success');
                        await fetchTables();
                    } else {
                        Swal.fire('Error', 'Failed to update order status', 'error');
                    }
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        if (error.response.data.error === 'Token expired') {
                            Swal.fire({
                                title: 'Session expired',
                                text: 'Your session has expired. Please log in again.',
                                icon: 'warning',
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    localStorage.removeItem('token');
                                    history.push('/');
                                }
                            });
                        } else {
                            Swal.fire('Error', 'An error occurred while updating the order status', 'error');
                        }
                    } else {
                        console.error('Error updating order status: ', error);
                        Swal.fire('Error', 'An error occurred while updating the order status', 'error');
                    }
                }
            }
        });
    };

    return (
        <Container fluid>
            <Row >
                <Col>
                    <div className="d-flex justify-content-between align-items-center p-3">
                        <h3>Dashboard</h3>
                        <div className="create-workform">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">

                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col lg="12" md="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h5>To be Installed</h5>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="table-color-heading">
                                        <tr className="text-secondary">
                                            <th scope="col">
                                                <label className="text-muted m-0" >Order ID</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Organization Name</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Status</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Phone Number</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Email</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">PO Number</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Stock Status</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Description</label>
                                            </th>
                                            <th scope="col">
                                                <span className="text-muted" >Action</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Orders.filter(item => item.customer.needInstallation === true && (item.statusInstall === 'pending' || item.statusInstall === 'inProgress')).map((item) => (
                                                <tr key={item._id} className="white-space-no-wrap">

                                                    <td>
                                                        {item.orderId}
                                                    </td>
                                                    <td>
                                                        {item.customerData ? item.customerData.organizationName : 'N/A'}
                                                    </td>
                                                    <td>
                                                        <select className="form-control" value={item.statusInstall} onChange={(e) => updateOrderStatusInstall(item._id, e.target.value)} style={{ color: item.statusInstall === 'pending' ? 'red' : item.statusInstall === 'inProgress' ? 'orange' : 'green' }}>
                                                            <option value="pending">Pending</option>
                                                            <option value="inProgress">In Progress</option>
                                                            <option value="completed">Completed</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        {item.customerData ? item.customerData.phoneNumber : 'N/A'}
                                                    </td>
                                                    <td>
                                                        {item.customerData ? item.customerData.email : 'N/A'}
                                                    </td>
                                                    <td>
                                                        {item.customer ? item.customer.poNo : 'N/A'}
                                                    </td>
                                                    <td>
                                                        <span className={`badge ${item.items[0].productData.stockStatus === 'In Stock' ? 'bg-success' : 'bg-danger'}`}>
                                                            {item.items[0].productData.stockStatus}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger trigger="click" placement="top" overlay={
                                                            <Popover id={`popover-positioned-top-${item._id}`}>
                                                                <Popover.Content>
                                                                    {item.items[0].productData.productDescription}
                                                                </Popover.Content>
                                                            </Popover>
                                                        }>
                                                            <Button variant="primary mt-2" size="sm">Description</Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <div className="card-header-toolbar d-flex align-items-center">
                                                            <Dropdown >
                                                                <Dropdown.Toggle href="#" variant="text-muted pl-3" id="dropdownMenuButton-event" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ zIndex: 100 }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" stroke="currentColor" strokeWidth="2" aria-hidden="true" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                                                        <g fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="19" cy="12" r="1" />
                                                                            <circle cx="5" cy="12" r="1" /></g>
                                                                    </svg>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu aria-labelledby="dropdownMenuButton-customer" popperConfig={{ strategy: 'fixed' }} >
                                                                    <Dropdown.Item as={Link} to={`/create-installation/${item._id}/${item.orderId}`}>
                                                                        <svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 13h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM5 9h14a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2z" />
                                                                        </svg>
                                                                        Create Installation
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item as={Link} to={`/view-details/${item._id}/${item.orderId}`}>
                                                                        <svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0zm-9 4v.01M12 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
                                                                        </svg>
                                                                        View Details
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))

                                        }
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-end align-items-center border-top-table p-3">
                                    <button className="btn btn-secondary btn-sm">See All</button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col lg="12" md="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h5>To be Delivered</h5>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="table-color-heading">
                                        <tr className="text-secondary">
                                            <th scope="col">
                                                <label className="text-muted m-0" >Order ID</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Organization Name</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Status</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Phone Number</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Email</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">PO Number</label>
                                            </th>
                                            <th scope="col">
                                                <label className="text-muted m-0">Stock Status</label>
                                            </th>
                                            <th scope="col">
                                                <span className="text-muted" >Description</span>
                                            </th>
                                            <th scope="col">
                                                <span className="text-muted" >Action</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Orders.filter(item => item.customer.needDelivery === true && (item.statusDelivery === 'pending' || item.statusDelivery === 'inProgress')).map((item) => (
                                                <tr key={item._id} className="white-space-no-wrap">
                                                    <td>
                                                        {item.orderId}
                                                    </td>
                                                    <td>
                                                        {item.customerData ? item.customerData.organizationName : 'N/A'}
                                                    </td>
                                                    <td>
                                                        <select className="form-control" value={item.statusDelivery} onChange={(e) => updateOrderStatus(item._id, e.target.value)} style={{ color: item.statusDelivery === 'pending' ? 'red' : item.statusDelivery === 'inProgress' ? 'orange' : item.statusDelivery === 'partial' ? 'blue' : 'green' }}>
                                                            <option value="pending" style={{ color: 'red' }}>Pending</option>
                                                            <option value="inProgress" style={{ color: 'orange' }}>In Progress</option>
                                                            <option value="partial" style={{ color: 'blue' }}>Partial</option>
                                                            <option value="completed" style={{ color: 'green' }}>Completed</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        {item.customerData ? item.customerData.phoneNumber : 'N/A'}
                                                    </td>
                                                    <td>
                                                        {item.customerData ? item.customerData.email : 'N/A'}
                                                    </td>
                                                    <td>
                                                        {item.customer ? item.customer.poNo : 'N/A'}
                                                    </td>
                                                    <td>
                                                        <span className={`badge ${item.items[0].productData.stockStatus === 'In Stock' ? 'bg-success' : 'bg-danger'}`}>
                                                            {item.items[0].productData.stockStatus}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger trigger="click" placement="top" overlay={
                                                            <Popover id={`popover-positioned-top-${item._id}`}>
                                                                <Popover.Content>
                                                                    {item.items[0].productData.productDescription}
                                                                </Popover.Content>
                                                            </Popover>
                                                        }>
                                                            <Button variant="primary mt-2" size="sm">Description</Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <div className="card-header-toolbar d-flex">
                                                            <Dropdown >
                                                                <Dropdown.Toggle href="#" variant="text-muted pl-3" id="dropdownMenuButton-event" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ zIndex: '1001' }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" stroke="currentColor" strokeWidth="2" aria-hidden="true" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                                                        <g fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="19" cy="12" r="1" />
                                                                            <circle cx="5" cy="12" r="1" /></g>
                                                                    </svg>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} >

                                                                    <Dropdown.Item as={Link} to={`/create-delivery/${item._id}/${item.orderId}`}>
                                                                        <svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 14v6H3v-6" />
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7v7h18V7a2 2 0 00-2-2H5a2 2 0 00-2 2z" />
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 3l-4 4-4-4" />
                                                                        </svg>
                                                                        Create Delivery
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item as={Link} to={`/view-details/${item._id}/${item.orderId}`}>
                                                                        <svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0zm-9 4v.01M12 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
                                                                        </svg>
                                                                        View Details
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))

                                        }
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-end align-items-center border-top-table p-3">
                                    <button className="btn btn-secondary btn-sm">See All</button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg="12" md="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h5>Order Summary</h5>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="table-color-heading">
                                        <tr className="text-secondary">
                                            <th scope="col">Company Name</th>
                                            <th scope="col">PO Number</th>
                                            <th scope="col">Branch</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Qty</th>
                                            <th scope="col">ETA</th>
                                            <th scope="col">Stock Status</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Orders.map((order) => (
                                            <tr className="white-space-no-wrap" key={order._id}>
                                                <td>{order.customerData ? order.customerData.organizationName : 'NA'}</td>
                                                <td>{order.customer ? order.customer.poNo : 'NA'}</td>
                                                <td>{order.customer && order.customerData && order.customer.branch ? (order.customerData.branches.find(branch => branch._id === order.customer.branch) || {}).branchAddress : 'NA'}</td>
                                                <td>{order.items && order.items.length > 0 ? order.items.map(item => item.productData.productName).join(', ') : 'NA'}</td>
                                                <td>{order.items && order.items.length > 0 ? order.items.reduce((total, item) => total + item.quantity, 0) : 'NA'}</td>
                                                <td>{order.customer && order.customer.poMailedDate ? new Date(order.customer.poMailedDate).toLocaleDateString() : 'NA'}</td>
                                                <td>
                                                    {order.items && order.items.length > 0 ? order.items.map((item, index) => (
                                                        <span key={index} className={`badge ${item.productData.stockStatus === 'In Stock' ? 'bg-success' : 'bg-danger'}`}>
                                                            {item.productData.stockStatus}
                                                        </span>
                                                    )).reduce((prev, curr, index) => [prev, <span key={`separator-${index}`}> </span>, curr], []) : 'NA'}
                                                </td>
                                                <td>{order.items && order.items.length > 0 ? order.items.map(item => item.productData.productDescription).join(', ') : 'NA'}</td>
                                                <td>{order.customer ? order.customer.remarks : 'NA'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-end align-items-center border-top-table p-3">
                                    <button className="btn btn-secondary btn-sm">See All</button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


        </Container >
    )
}

export default Employeedashboard;