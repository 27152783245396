import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Dropdown, Popover, Pagination } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import Swal from 'sweetalert2';

const TobeDelivered = () => {
	const [Orders, setOrders] = useState([]);
	const history = useHistory();
	const [currentPage, setCurrentPage] = useState(1);
	const rowsPerPage = 10;
	const totalRows = Orders.filter(order => order.statusDelivery === "pending" || order.statusDelivery === "inProgress").length;
	const totalPages = Math.ceil(totalRows / rowsPerPage);

	const fetchTables = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/employee-orders`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setOrders(response.data);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			}
		}
	};
	useEffect(() => {
		fetchTables();
	}, []);

	const updateOrderStatus = async (orderId, newStatus) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to update the status?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, update it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				const token = localStorage.getItem('token');
				try {
					const response = await axios.put(`${API_URL}/api/update-order-status-delivery/${orderId}`, {
						status: newStatus
					}, {
						headers: {
							'Authorization': `Bearer ${token}`
						}
					});
					if (response.status === 200) {
						setOrders(Orders.map(order => {
							if (order._id === orderId) {
								return { ...order, status: newStatus };
							}
							return order;
						}));
						Swal.fire('Updated!', 'Order status updated successfully.', 'success');
						await fetchTables();
					} else {
						Swal.fire('Error', 'Failed to update order status', 'error');
					}
				} catch (error) {
					if (error.response && error.response.status === 403) {
						if (error.response.data.error === 'Token expired') {
							Swal.fire({
								title: 'Session expired',
								text: 'Your session has expired. Please log in again.',
								icon: 'warning',
								confirmButtonText: 'OK'
							}).then((result) => {
								if (result.isConfirmed) {
									localStorage.removeItem('token');
									history.push('/');
								}
							});
						} else {
							Swal.fire('Error', 'An error occurred while updating the order status', 'error');
						}
					} else {
						console.error('Error updating order status: ', error);
						Swal.fire('Error', 'An error occurred while updating the order status', 'error');
					}
				}
			}
		});
	};

	return (
		<Container fluid>
			<Row>
				<Col lg="12">
					<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
						<div className="d-flex align-items-center justify-content-between">
							<h4 className="font-weight-bold">To be Delivered</h4>
						</div>
						<div className="create-workform">
							<div className="d-flex flex-wrap align-items-center justify-content-between">
								<div className="modal-product-search d-flex">
									<Form className="mr-3 position-relative">
										<Form.Group className="mb-0">
											<input type="text" className="form-control" id="exampleInputText" placeholder="Search Order" />
											<Link className="search-link" to="#">
												<svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
												</svg>
											</Link>
										</Form.Group>
									</Form>

								</div>
							</div>
						</div>
					</div>
				</Col>
				<Col lg="12" md="12">
					<Card>
						<Card.Header className="d-flex justify-content-between">
							<div className="header-title">
								<h5>To be Delivered</h5>
							</div>
						</Card.Header>
						<Card.Body className="p-0">
							<div className="table-responsive">
								<table className="table mb-0">
									<thead className="table-color-heading">
										<tr className="text-secondary">
											<th scope="col">
												<label className="text-muted m-0" >Order ID</label>
											</th>
											<th scope="col">
												<label className="text-muted m-0">Organization Name</label>
											</th>
											<th scope="col">
												<label className="text-muted m-0">Status</label>
											</th>
											<th scope="col">
												<label className="text-muted m-0">PO Number</label>
											</th>
											<th scope="col">
												<label className="text-muted m-0">Stock Status</label>
											</th>
											<th scope="col">
												<span className="text-muted" >Description</span>
											</th>
											<th scope="col">
												<span className="text-muted" >Challan</span>
											</th>
											<th scope="col">
												<span className="text-muted" >Action</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{
											Orders.filter(item => item.customer.needDelivery === true && (item.statusDelivery === 'pending' || item.statusDelivery === 'shipped')).slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage).map((item) => (
												<tr key={item._id} className="white-space-no-wrap">
													<td>
														{item.orderId}
													</td>
													<td>
														{item.customerData.organizationName}
													</td>
													<td>
														{item.statusDelivery}
													</td>

													{/* <td>
														<select className="form-control" value={item.statusDelivery} onChange={(e) => updateOrderStatus(item._id, e.target.value)} style={{ color: item.statusDelivery === 'pending' ? 'red' : item.statusDelivery === 'inProgress' ? 'orange' : item.statusDelivery === 'partial' ? 'blue' : 'green' }}>
															<option value="pending" style={{ color: 'red' }}>Pending</option>
															<option value="inProgress" style={{ color: 'orange' }}>In Progress</option>
															<option value="partial" style={{ color: 'blue' }}>Partial</option>
															<option value="completed" style={{ color: 'green' }}>Completed</option>
														</select>
													</td> */}
													<td>
														{item.customer.poNo}
													</td>
													<td>
														<span className={`badge ${item.items[0].productData.stockStatus === 'In Stock' ? 'bg-success' : 'bg-danger'}`}>
															{item.items[0].productData.stockStatus}
														</span>
													</td>
													<td>
														<OverlayTrigger trigger="click" placement="top" overlay={
															<Popover id={`popover-positioned-top-${item._id}`}>
																<Popover.Content>
																	{item.items[0].productData.productDescription}
																</Popover.Content>
															</Popover>
														}>
															<Button variant="primary mt-2" size="sm">Description</Button>
														</OverlayTrigger>
													</td>
													<td>
														{item.pdfPath ?
															<span className="badge bg-success">
																<a href={`${API_URL}/${item.pdfPath}`} target="_blank" rel="noopener noreferrer" className="text-white" style={{ textDecoration: 'none' }}>View Challan</a>
															</span>
															:
															<span className="badge bg-danger">
																Not Generated
															</span>
														}
													</td>
													<td>
														<div className="card-header-toolbar d-flex">
															<Dropdown >
																<Dropdown.Toggle href="#" variant="text-muted pl-3" id="dropdownMenuButton-event" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ zIndex: '1001' }}>
																	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" stroke="currentColor" strokeWidth="2" aria-hidden="true" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
																		<g fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
																			<circle cx="12" cy="12" r="1" />
																			<circle cx="19" cy="12" r="1" />
																			<circle cx="5" cy="12" r="1" /></g>
																	</svg>
																</Dropdown.Toggle>
																<Dropdown.Menu popperConfig={{ strategy: 'fixed' }} >

																	<Dropdown.Item as={Link} to={`/create-delivery/${item._id}/${item.orderId}`}>
																		<svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 14v6H3v-6" />
																			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7v7h18V7a2 2 0 00-2-2H5a2 2 0 00-2 2z" />
																			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 3l-4 4-4-4" />
																		</svg>
																		Create Delivery
																	</Dropdown.Item>
																	<Dropdown.Item as={Link} to={`/view-details/${item._id}/${item.orderId}`}>
																		<svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0zm-9 4v.01M12 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
																		</svg>
																		View Details
																	</Dropdown.Item>

																</Dropdown.Menu>
															</Dropdown>
														</div>
													</td>
												</tr>
											))

										}
									</tbody>
								</table>
								<div className="d-flex justify-content-center">
									<Pagination className="mb-0">
										<Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
										{[...Array(totalPages).keys()].map(pageNumber => (
											<Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === currentPage} onClick={() => setCurrentPage(pageNumber + 1)}>
												{pageNumber + 1}
											</Pagination.Item>
										))}
										<Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
									</Pagination>
								</div>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}
export default TobeDelivered;

