import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ListGroup, Tab, Nav, Table } from 'react-bootstrap'
import moment from 'moment';// Import the CSS for the DatePicker if needed
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ThreeDots } from 'react-loader-spinner';
// img
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for the DatePicker if needed
import product1 from '../../../assets/images/products/1.jpg'
import product12 from '../../../assets/images/products/12.jpg'
import { API_URL } from '../../../config';



const Ordernew = () => {
    const [tables, setTables] = useState([]);
    const history = useHistory();
    const [displayValues, setDisplayValues] = useState({});
    const [editableCell, setEditableCell] = useState(null);
    const [originalTables, setOriginalTables] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [defaultRow, setDefaultRow] = useState({});
    useEffect(() => {
        const fetchTables = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${API_URL}/api/tables`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log('Response', response.data);
                const fetchedTables = response.data.map(table => {
                    if (!table.headers.includes('Mail')) {
                        return { ...table, headers: [...table.headers, 'Mail'] };
                    }
                    return table;
                });
                if (response.data.length > 0 && response.data[0].rows.length > 0) {
                    setDefaultRow(response.data[0].rows[0]);
                }
                setTables(fetchedTables);
                setOriginalTables(fetchedTables);
                const dropdownHeaders = inferDropdownHeaders(tables);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    if (error.response.data.error === 'Token expired') {
                        Swal.fire({
                            title: 'Session expired',
                            text: 'Your session has expired. Please log in again.',
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                localStorage.removeItem('token');
                                history.push('/');
                            }
                        }).catch((error) => {
                            console.error("Error during session expiration handling: ", error);
                        });
                    } else if (error.response.data.error === 'Invalid token') {
                        Swal.fire({
                            title: 'Invalid token',
                            text: 'Your token is invalid. Please log in again.',
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                localStorage.removeItem('token');
                                history.push('/');
                            }
                        }).catch((error) => {
                            console.error("Error during session expiration handling: ", error);
                        });
                    }
                }
            }
        };

        fetchTables();
    }, []);
    const [companyNames, setCompanyNames] = useState([]);
    useEffect(() => {
        const fetchCompanyNames = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${API_URL}/api/customers`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setCompanyNames(response.data);
                console.log(setCompanyNames(response.data)); // Assuming the response data is an array of company names
            } catch (error) {
                console.error('Error fetching company names:', error);
                // Handle error (e.g., show notification to the user)
            }
        };
        fetchCompanyNames();
    }, []); // Empty dependency array to run only once on component mount


    const [installations, setInstallations] = useState([]);
    useEffect(() => {
        const fetchInstallations = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${API_URL}/api/installations`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setInstallations(response.data);
                console.log(setInstallations(response.data)); // Assuming the response data is an array of installations
            } catch (error) {
                console.error('Error fetching installations:', error);
                // Handle error (e.g., show notification to the user)
            }
        };
        fetchInstallations();
    }, []); // Empty dependency array to run only once on component mount

    const [deliveries, setDeliveries] = useState([]);
    useEffect(() => {
        const fetchDeliveries = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${API_URL}/api/deliveries`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setDeliveries(response.data);
                console.log(setDeliveries(response.data)); // Assuming the response data is an array of deliveries
            } catch (error) {
                console.error('Error fetching deliveries:', error);
                // Handle error (e.g., show notification to the user)
            }
        };
        fetchDeliveries();
    }, []); // Empty dependency array to run only once on component mount


    const [products, setProducts] = useState([]);
    useEffect(() => {
        const fetchProducts = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${API_URL}/api/products`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setProducts(response.data);
                console.log(setProducts(response.data)); // Assuming the response data is an array of products
            } catch (error) {
                console.error('Error fetching products:', error);
                // Handle error (e.g., show notification to the user)
            }
        };
        fetchProducts();
    }, []); // Empty dependency array to run only once on component mount

    const fetchAndUpdateTables = async () => {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/save-tables`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            // No need to await on response again, it's already awaited with axios.get
            const fetchedTables = response.data.map(table => ({
                ...table,
                rows: table.rows.map(row => {
                    let rowObject = {};
                    table.headers.forEach((header, index) => {
                        rowObject[header] = row[index];
                    });
                    return rowObject;
                })
            }));

            setTables(fetchedTables); // Update the state to re-render the tables
            const newDisplayValues = {};
            fetchedTables.forEach((table, tableIndex) => {
                table.rows.forEach((row, rowIndex) => {
                    table.headers.forEach((header) => {
                        const cellKey = `${tableIndex}-${rowIndex}-${header}`;
                        newDisplayValues[cellKey] = row[header];
                    });
                });
            });
            setDisplayValues(newDisplayValues);
            setOriginalTables(fetchedTables);
        } catch (error) {
            console.error('Error fetching tables:', error);
            setError(error.message || 'An error occurred while fetching data.');
        } finally {
            setLoading(false);
        }
    };

    const addRow = (tableIndex) => {
        const newTables = [...tables];
        // Clone the defaultRow to ensure we don't mutate the state directly
        const newRow = { ...defaultRow };
        newTables[tableIndex].rows.push(newRow);
        setTables(newTables);
    };

    const handleSelect = (eventKey, tableIndex, rowIndex, header) => {
        const newDisplayValues = { ...displayValues };
        newDisplayValues[`${tableIndex}-${rowIndex}-${header}`] = eventKey;
        setDisplayValues(newDisplayValues);
    };
    // Define the function before using it
    const inferDropdownHeaders = (tables) => {
        const headersWithDropdowns = {};
        if (tables.length > 0) {
            const firstRow = tables[0].rows[0];
            tables[0].headers.forEach(header => {
                headersWithDropdowns[header] = firstRow[header] == "None";
            });
        }
        return headersWithDropdowns;
    };

    const renderDropdown = (row, header, rowIndex, tableIndex) => {
        // console.log(`Rendering dropdown for ${header} at row ${rowIndex}, table ${tableIndex}`);

        const rows = tables[tableIndex].rows;
        const cellKey = `${tableIndex}-${rowIndex}-${header}`;


        if (header === 'Customer Name') {
            return (
                <Dropdown key={rows[rowIndex][header]} onSelect={(eventKey) => {
                    const newDisplayValues = { ...displayValues };
                    newDisplayValues[`${tableIndex}-${rowIndex}-${header}`] = eventKey;
                    setDisplayValues(newDisplayValues);
                }}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header]}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {companyNames
                            .map((company, index) => (
                                <Dropdown.Item eventKey={company.organizationName} key={company._id}>
                                    {company.organizationName}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        } else if (header === 'Status') {
            return (
                <Dropdown key={rows[rowIndex][header]} onSelect={(eventKey) => {
                    const newDisplayValues = { ...displayValues };
                    newDisplayValues[`${tableIndex}-${rowIndex}-${header}`] = eventKey;
                    setDisplayValues(newDisplayValues);
                }}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header] || "Select"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                        <Dropdown.Item eventKey="Completed">Completed</Dropdown.Item>
                        <Dropdown.Item eventKey="In Progress">In Progress</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            );
        } else if (header === 'Address') {
            return (
                <Dropdown onSelect={(eventKey) => handleSelect(eventKey, tableIndex, rowIndex, header)}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header] || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {companyNames
                            .map((company, index) => (
                                <Dropdown.Item eventKey={company.registeredOfficeAddress} key={company._id}>
                                    {company.registeredOfficeAddress}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        } else if (header === 'Contact Number') {
            return (
                <Dropdown onSelect={(eventKey) => handleSelect(eventKey, tableIndex, rowIndex, header)}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header] || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {companyNames
                            .map((company, index) => (
                                <Dropdown.Item eventKey={company.phoneNumber} key={company._id}>
                                    {company.phoneNumber}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        } else if (header === 'Location') {
            return (
                <Dropdown onSelect={(eventKey) => handleSelect(eventKey, tableIndex, rowIndex, header)}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header] || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {companyNames
                            .map((company, index) => (
                                <Dropdown.Item eventKey={company.registeredOfficeAddress} key={company._id}>
                                    {company.registeredOfficeAddress}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        } else if (header === 'Vendor Name') {
            return (
                <Dropdown onSelect={(eventKey) => handleSelect(eventKey, tableIndex, rowIndex, header)}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header] || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {installations
                            .map((installation, index) => (
                                <Dropdown.Item eventKey={installation.vendorName} key={installation._id}>
                                    {installation.vendorName}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        } else if (header === 'Vendor Email') {
            return (
                <Dropdown onSelect={(eventKey) => handleSelect(eventKey, tableIndex, rowIndex, header)}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || row[header] || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {installations
                            .map((installation, index) => (
                                <Dropdown.Item eventKey={installation.vendorEmailId} key={installation._id}>
                                    {installation.vendorEmailId}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        }
        else if (header === 'Vendor Number') {
            return (
                <Dropdown onSelect={(eventKey) => handleSelect(eventKey, tableIndex, rowIndex, header)}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header] || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {installations
                            .map((installation, index) => (
                                <Dropdown.Item eventKey={installation.vendorPhoneNumber} key={installation._id}>
                                    {installation.vendorPhoneNumber}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        }
        else if (header === 'Delivery Mail') {
            return (
                <Dropdown onSelect={(eventKey) => handleSelect(eventKey, tableIndex, rowIndex, header)}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header] || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {deliveries
                            .map((delivery, index) => (
                                <Dropdown.Item eventKey={delivery.deliveryEmailId} key={delivery._id}>
                                    {delivery.deliveryEmailId}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        }
        else if (header === 'Quantity') {
            return (
                <Dropdown onSelect={(eventKey) => handleSelect(eventKey, tableIndex, rowIndex, header)}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header] || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {[...Array(10).keys()].map((value, index) => (
                            <Dropdown.Item eventKey={value + 1} key={index}>
                                {value + 1}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        }
        else if (header === 'Date') {
            // Parse the date using moment.js to ensure it's valid
            const dateValue = displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header];
            const validDate = moment(dateValue, "MM/DD/YYYY", true).isValid() ? moment(dateValue).toDate() : new Date();

            return (
                <DatePicker
                    selected={validDate}
                    onChange={(date) => handleSelect(date, tableIndex, rowIndex, header)}
                    dateFormat="MM/dd/yyyy"
                    style={{ width: '100%' }}
                />
            );
        }
        else if (header === 'Courier Name') {
            return (
                <Dropdown onSelect={(eventKey) => handleSelect(eventKey, tableIndex, rowIndex, header)}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header] || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {deliveries
                            .map((delivery, index) => (
                                <Dropdown.Item eventKey={delivery.deliveryName} key={delivery._id}>
                                    {delivery.deliveryName}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        } else if (header === 'Delivery Address') {
            return (
                <Dropdown onSelect={(eventKey) => handleSelect(eventKey, tableIndex, rowIndex, header)}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header] || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {deliveries
                            .map((delivery, index) => (
                                <Dropdown.Item eventKey={delivery.deliveryAddress} key={delivery._id}>
                                    {delivery.deliveryAddress}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        } else if (header === 'Material') {
            return (
                <Dropdown onSelect={(eventKey) => handleSelect(eventKey, tableIndex, rowIndex, header)}>
                    <Dropdown.Toggle variant="" style={{ backgroundColor: 'transparent', color: 'black', width: '100%' }}>
                        {displayValues[`${tableIndex}-${rowIndex}-${header}`] || rows[rowIndex][header] || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} style={{ zIndex: 99999 }}>
                        <Dropdown.Item eventKey="NA">NA</Dropdown.Item>
                        {products
                            .map((product, index) => (
                                <Dropdown.Item eventKey={product.productName} key={product._id}>
                                    {product.productName}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        } else {
            //console.log("Debug: row[header] is not in items and is not 'Item 2'");
            return row[header];
        }
    };


    const saveData = async () => {
        console.log('saveData function called');
        const token = localStorage.getItem('token');
        try {
            const formattedTables = originalTables.map((table, tableIndex) => ({
                _id: table._id,
                tableName: table.tableName,
                headers: table.headers,
                rows: table.rows.map((row, rowIndex) => {
                    const updatedRow = { ...row };
                    table.headers.forEach((header) => {
                        const cellKey = `${tableIndex}-${rowIndex}-${header}`;
                        // Update the row with either the new value or the existing value
                        updatedRow[header] = displayValues[cellKey] !== undefined ? displayValues[cellKey] : row[header];
                    });
                    return updatedRow;
                })
            }));

            const response = await axios.post(`${API_URL}/api/save-tables`, { tables: formattedTables }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                if (response.data.partialSuccess) {
                    Swal.fire('Success', 'Data Saved and mails sent successfully', 'success');
                } else {
                    Swal.fire('Warning', 'Data saved but some mails failed to send', 'warning');
                }
            } else {
                Swal.fire('Error', 'Failed to save data', 'error');
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                console.error('Error saving data', error);
                Swal.fire('Error', 'Failed to save data', 'error');
            }
        }
        await fetchAndUpdateTables();
    };

    useEffect(() => {
        const newOriginalTables = [...tables];
        for (const [key, value] of Object.entries(displayValues)) {
            const [tableIndex, rowIndex, header] = key.split('-'); // Convert to numbers if necessary
            if (newOriginalTables[tableIndex] && newOriginalTables[tableIndex].rows[rowIndex]) {
                newOriginalTables[tableIndex].rows[rowIndex][header] = value;
                //console.log('tableIndex:', tableIndex, 'rowIndex:', rowIndex);
            } else {
                //console.error('Data is undefined', tableIndex, rowIndex);
            }
        }
        setOriginalTables(newOriginalTables);
    }, [displayValues, tables]); // Include tables in the dependency array if it's state or props


    useEffect(() => {
        fetchAndUpdateTables();
    }, []);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <ThreeDots
                    color="#00BFFF"
                    height={100}
                    width={100}
                />
            </div>
        );
    }

    const handleCellChange = (event, tableIndex, rowIndex, header) => {
        const newTables = [...tables];
        const newValue = event.target.innerText.trim(); // Get the new value from the cell
        newTables[tableIndex].rows[rowIndex][header] = newValue; // Update the cell's value in the row
        setTables(newTables); // Update the state with the new tables array
        console.log('newValue', newTables[tableIndex].rows[rowIndex][header]);
        console.log('newValue', newTables);
    };

    const shouldCellBeEditable = (header, row, tableIndex, rowIndex) => {
        const cellKey = `${tableIndex}-${rowIndex}-${header}`;
        console.log('CellKey', cellKey);
        return displayValues.hasOwnProperty(cellKey);
    };
    const toggleMail = (tableIndex, rowIndex, header) => {
        const cellKey = `${tableIndex}-${rowIndex}-${header}`;
        // Check if the value exists in displayValues, otherwise use the value from the row data
        const currentValue = displayValues[cellKey] !== undefined ? displayValues[cellKey] : tables[tableIndex].rows[rowIndex][header];
        console.log('Current Value', currentValue);
        const newValue = currentValue === 'Yes' ? 'No' : 'Yes';

        setDisplayValues({
            ...displayValues,
            [cellKey]: newValue,
        });
    };
    const isCellEditable = (header, value) => {
        // If the header is not listed as having a dropdown, or the value is "None", the cell should be editable
        return !inferDropdownHeaders[header] || value === "None";
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/order">Orders</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Order</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/order" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">New Order</h4>
                    </Col>
                </Row>
                <Card>
                    <Card.Body className="p-0">
                        <Tab.Container defaultActiveKey={tables.length > 0 ? tables[0].tableName : ''}>
                            <Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">
                                {tables.map((table, index) => (
                                    <Nav.Item as="li" className="pb-2 mb-0 nav-item" key={index}>
                                        <Nav.Link eventKey={table.tableName} bsPrefix="font-weight-bold text-uppercase px-5 py-2">
                                            {table.tableName}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>

                            <Tab.Content>
                                {tables.map((table, tableIndex) => (
                                    <Tab.Pane eventKey={table.tableName} key={tableIndex}>
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5>{table.tableName} List</h5>
                                            <div className="ml-auto">
                                                <Button onClick={() => addRow(tableIndex)} className="mr-2">Add Row</Button>

                                                <Button onClick={saveData}>Save</Button>
                                            </div>
                                        </div>
                                        <div id="table" className="table-editable" style={{ overflowX: 'scroll' }}>
                                            <table className="table table-bordered table-responsive-md table-striped text-center">
                                                <thead className="table-color-heading" style={{ backgroundColor: '#f2f2f2' }}>
                                                    <tr>
                                                        {table.headers.map((header, headerIndex) => (
                                                            <th key={headerIndex} style={{ borderColor: '#000000' }}>{header}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {table.rows.map((row, rowIndex) => (
                                                        <tr key={rowIndex} dataid={table._id} style={{
                                                            borderColor: '#000000',
                                                            wordWrap: 'break-word',
                                                            backgroundColor: displayValues[`${tableIndex}-${rowIndex}-Status`] ?
                                                                (displayValues[`${tableIndex}-${rowIndex}-Status`] === 'Completed' ? '#90ee90' :
                                                                    displayValues[`${tableIndex}-${rowIndex}-Status`] === 'Pending' ? '#ffff99' : '#ffcccb') : '#ffffff'
                                                        }}>
                                                            {table.headers.map((header, headerIndex) => {
                                                                const cellKey = `${tableIndex}-${rowIndex}-${header}`;
                                                                //const isEditable = shouldCellBeEditable(header, row, tableIndex, rowIndex);
                                                                const cellValue = displayValues[cellKey] !== undefined ? displayValues[cellKey] : row[header];
                                                                console.log('displayValues[cellKey]', cellValue);
                                                                if (header === 'Mail') {
                                                                    const isMailEnabled = cellValue === 'Yes';
                                                                    return (
                                                                        <td key={headerIndex} style={{
                                                                            borderColor: '#000000',
                                                                            wordWrap: 'break-word',
                                                                            whiteSpace: 'normal'
                                                                        }}>
                                                                            <Button
                                                                                variant={isMailEnabled ? 'success' : 'secondary'}
                                                                                onClick={() => toggleMail(tableIndex, rowIndex, header)}
                                                                            >
                                                                                {isMailEnabled ? 'Yes' : 'No'}
                                                                            </Button>
                                                                        </td>
                                                                    );
                                                                } else {
                                                                    console.log('Row:', row[header]);
                                                                    return (
                                                                        <td
                                                                            style={{
                                                                                borderColor: '#000000',
                                                                                wordWrap: 'break-word',
                                                                                whiteSpace: 'normal'
                                                                            }}
                                                                            key={headerIndex}
                                                                            contentEditable={isCellEditable(header, row[header])}
                                                                            suppressContentEditableWarning={true}
                                                                            onBlur={isCellEditable(header, row[header]) ? (event) => handleCellChange(event, tableIndex, rowIndex, header) : undefined}
                                                                        >
                                                                            {renderDropdown(row, header, rowIndex, tableIndex)}
                                                                        </td>
                                                                    );
                                                                }
                                                            })}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Tab.Container>
                    </Card.Body>
                </Card >
            </Container >
        </>
    )
}

export default Ordernew;
