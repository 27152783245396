import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Dropdown, Popover, Pagination } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import Swal from 'sweetalert2';
import { decode } from 'jsonwebtoken';

const PartialOrders = () => {
	const [employees, setEmployees] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [Orders, setOrders] = useState([]);
	const history = useHistory();
	const [currentPage, setCurrentPage] = useState(1);
	const rowsPerPage = 10;
	const totalRows = Orders.filter(partialDeliveries => partialDeliveries.statusInstall === "pending" || partialDeliveries.statusInstall === "inProgress" || partialDeliveries.statusDelivery === "partial").length;
	const totalPages = Math.ceil(totalRows / rowsPerPage);

	const token = localStorage.getItem('token');
	let roles = '';
	if (token) {
		const decodedToken = decode(token);
		roles = decodedToken.role;
	}

	const fetchOrders = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/partial-deliveries`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setOrders(response.data.partialDeliveries);
			console.log("partial orders", response.data.partialDeliveries);
		} catch (error) {
			if (error.response && error.response.status === 403) {
				if (error.response.data.error === 'Token expired') {
					Swal.fire({
						title: 'Session expired',
						text: 'Your session has expired. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				} else if (error.response.data.error === 'Invalid token') {
					Swal.fire({
						title: 'Invalid token',
						text: 'Your token is invalid. Please log in again.',
						icon: 'warning',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.removeItem('token');
							history.push('/');
						}
					}).catch((error) => {
						console.error("Error during session expiration handling: ", error);
					});
				}
			}
		}
	};
	useEffect(() => {
		fetchOrders();
	}, []);

	const handleDelete = async (itemId) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to delete this order?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				const token = localStorage.getItem('token');
				try {
					await axios.delete(`${API_URL}/api/order-delete/${itemId}`, {
						headers: {
							'Authorization': `Bearer ${token}`
						}
					});
					Swal.fire(
						'Deleted!',
						'The order has been deleted.',
						'success'
					);
					await fetchOrders(); // Assuming fetchOrders is a function to refresh the orders list
				} catch (error) {
					console.error("Error deleting order: ", error);
					Swal.fire(
						'Error!',
						error.response.data.message,
						'error'
					);
				}
			}
		});
	};
	return (
		<>

			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">All Orders</h4>
							</div>
							<div className="create-workform">
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className="modal-product-search d-flex">
										<Form className="mr-3 position-relative">
											<Form.Group className="mb-0">
												<input
													type="text"
													className="form-control"
													id="exampleInputText"
													placeholder="Search Order"
													value={searchTerm}
													onChange={(e) => setSearchTerm(e.target.value)}
												/>
												<Link className="search-link" to="#">
													<svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
													</svg>
												</Link>
											</Form.Group>
										</Form>
										<Link to="/create-order" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
											<svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
											Add Order
										</Link>
									</div>
								</div>
							</div>
						</div>

						<Row>
							<Col lg="12">
								<Card className="card-block card-stretch">
									<Card.Body style={{ overflow: 'visible' }}>
										<div className="d-flex justify-content-between align-items-center p-3">
											<h5 className="font-weight-bold">Partial Orders List</h5>
											<div>
												<Button variant="btn btn-secondary btn-sm">
													<svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
													</svg>
													Export
												</Button>
											</div>
										</div>
										<div className="table-responsive" style={{ overflowX: 'scroll' }}>
											<table id="datatable" className="table data-table table-striped table-bordered">
												<thead className="table-color-heading">
													<tr className="text-light">
														<th scope="col" className="w-01 pr-0">
															<div className="d-flex justify-content-start align-items-end mb-1">
																<div className="custom-control custom-checkbox custom-control-inline">
																	<input type="checkbox" className="custom-control-input m-0" id="customCheck" />
																	<label className="custom-control-label" htmlFor="customCheck"></label>
																</div>
															</div>
														</th>
														<th scope="col">
															<label className="text-muted m-0" >Order ID</label>
														</th>
														<th scope="col">
															<label className="text-muted m-0">Phone Number</label>
														</th>
														<th scope="col">
															<label className="text-muted m-0">Install Status</label>
														</th>
														<th scope="col">
															<label className="text-muted m-0">Delivery Status</label>
														</th>
														<th scope="col">
															<label className="text-muted m-0">PO Number</label>
														</th>
														<th scope="col">
															<label className="text-muted m-0">Installation required</label>
														</th>

														<th scope="col">
															<label className="text-muted m-0">Delivery Required</label>
														</th>
														{roles === 'admin' && (
															<th scope="col">
																<label className="text-muted m-0">Created By</label>
															</th>
														)}
														<th scope="col">
															<span className="text-muted" >Action</span>
															{/* <p className="text-muted"></p>  */}
														</th>
													</tr>
												</thead>
												<tbody>
													{
														Orders && Orders.filter(item =>
															(item.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
																item.customer.organizationName.toLowerCase().includes(searchTerm.toLowerCase())) &&
															(item.statusInstall === 'pending' || item.statusInstall === 'inProgress' ||
																item.statusDelivery === 'partial')
														).slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage).map((item) => (
															<tr key={item._id} className="white-space-no-wrap">
																<td className="pr-0">
																	<div className="custom-control custom-checkbox custom-control-inline">
																		<input type="checkbox" className="custom-control-input m-0" id="customCheck1" />
																		<label className="custom-control-label" htmlFor="customCheck1"></label>
																	</div>
																</td>

																<td>
																	{item.orderId}
																</td>
																<td>
																	{item.customer.phoneNumber}
																</td>
																<td>
																	<strong style={{ color: item.statusInstall === 'pending' ? 'red' : item.statusInstall === 'inProgress' ? 'orange' : item.statusInstall === 'completed' && !item.customer.needInstallation ? 'black' : 'green' }}>
																		{item.statusInstall === 'pending' ? 'Pending' : item.statusInstall === 'inProgress' ? 'In Progress' : item.statusInstall === 'completed' && !item.customer.needInstallation ? 'NA' : item.statusInstall}
																	</strong>
																</td>
																<td>
																	<strong style={{ color: item.customer.needDelivery === false ? 'black' : item.statusDelivery === 'pending' ? 'red' : item.statusDelivery === 'inProgress' ? 'orange' : 'green' }}>
																		{item.customer.needDelivery === false ? 'NA' : item.statusDelivery === 'pending' ? 'Pending' : item.statusDelivery === 'inProgress' ? 'In Progress' : item.statusDelivery}
																	</strong>
																</td>

																<td>
																	{item.customer.poNo}
																</td>

																<td>
																	{item.customer.needInstallation ? 'Yes' : 'No'}
																</td>
																<td>
																	{item.customer.needDelivery ? 'Yes' : 'No'}
																</td>
																{roles === 'admin' && (
																	<td>
																		{item.employeeDetails.firstName} {item.employeeDetails.lastName}
																	</td>
																)}

																<td>
																	<div className="card-header-toolbar d-flex">
																		<Dropdown>
																			<Dropdown.Toggle href="#" variant="text-muted pl-3" id="dropdownMenuButton-event" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ zIndex: '1001' }}>
																				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" stroke="currentColor" strokeWidth="2" aria-hidden="true" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
																					<g fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
																						<circle cx="12" cy="12" r="1" />
																						<circle cx="19" cy="12" r="1" />
																						<circle cx="5" cy="12" r="1" /></g>
																				</svg>
																			</Dropdown.Toggle>
																			<Dropdown.Menu popperConfig={{ strategy: 'fixed' }}>
																				<Dropdown.Item as={Link} to={`/create-delivery/${item._id}/${item.orderId}`}>
																					<svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 14v6H3v-6" />
																						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7v7h18V7a2 2 0 00-2-2H5a2 2 0 00-2 2z" />
																						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 3l-4 4-4-4" />
																					</svg>
																					Create Delivery
																				</Dropdown.Item>
																				<Dropdown.Item as={Link} to={`/view-details/${item._id}/${item.orderId}`}>
																					<svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0zm-9 4v.01M12 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
																					</svg>
																					View Details
																				</Dropdown.Item>
																				<Dropdown.Item as={Link} to={`/view-invoice/${item._id}/${item.orderId}`}>
																					<svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14v-4m0 0V8a4 4 0 013.464-3.95 4 4 0 00-7.928 0A4 4 0 0112 8v2zm4 4h-1v4h-2v-4H8a4 4 0 118 0z" />
																					</svg>
																					View Invoice
																				</Dropdown.Item>
																			</Dropdown.Menu>
																		</Dropdown>
																	</div>
																</td>
															</tr>
														))

													}
												</tbody>
											</table>
											<div className="d-flex justify-content-center">
												<Pagination className="mb-0">
													<Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
													{[...Array(totalPages).keys()].map(pageNumber => (
														<Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === currentPage} onClick={() => setCurrentPage(pageNumber + 1)}>
															{pageNumber + 1}
														</Pagination.Item>
													))}
													<Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
												</Pagination>
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row >
			</Container >
		</>
	)
}
export default PartialOrders;
