import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Pagination } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import Swal from 'sweetalert2';
// img


const Employee = () => {
    const [employees, setEmployees] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const history = useHistory();

    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const totalRows = employees.length;
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    const fetchEmployees = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/employees`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setEmployees(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.error === 'Token expired') {
                    Swal.fire({
                        title: 'Session expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                } else if (error.response.data.error === 'Invalid token') {
                    Swal.fire({
                        title: 'Invalid token',
                        text: 'Your token is invalid. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('token');
                            history.push('/');
                        }
                    }).catch((error) => {
                        console.error("Error during session expiration handling: ", error);
                    });
                }
            } else {
                Swal.fire(
                    'Error!',
                    'There was an error fetching the employees.',
                    'error'
                )
            }
        }
    };

    useEffect(() => {
        fetchEmployees();
    }, []);

    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (result.isConfirmed) {
            // Call the API to delete the employee

            const response = await axios.delete(`${API_URL}/api/employees/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                Swal.fire(
                    'Deleted!',
                    'The employee has been deleted.',
                    'success'
                )
                // Refresh the employees list
                fetchEmployees();
            } else {
                Swal.fire(
                    'Error!',
                    'There was an error deleting the employee.',
                    'error'
                )
            }
        }
    }
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Employees</h4>
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">
                                        <Form className="mr-3 position-relative">
                                            <Form.Group className="mb-0">
                                                <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search Employee" />
                                                <Link to="#" className="search-link">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                    </svg>
                                                </Link>
                                            </Form.Group>
                                        </Form>
                                        <Link to="/employee-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Add Employee
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Employee List</h5>
                                            <div>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search..."
                                                    className="mr-3"
                                                    style={{ width: '200px', display: 'inline-block' }}
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                                <Button variant="btn btn-secondary btn-sm">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                                    </svg>
                                                    Export
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table data-table mb-0">
                                                <thead className="table-color-heading">
                                                    <tr className="">
                                                        <th scope="col" className="pr-0 w-01">
                                                            <div className="d-flex justify-content-start align-items-end mb-1 ">
                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                    <input type="checkbox" className="custom-control-input m-0" id="customCheck1" />
                                                                    <label className="custom-control-label" htmlFor="customCheck1"></label>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            Name
                                                        </th>
                                                        <th scope="col">
                                                            Email
                                                        </th>
                                                        <th scope="col">
                                                            Country
                                                        </th>
                                                        <th scope="col">
                                                            State
                                                        </th>
                                                        <th scope="col">
                                                            Phone
                                                        </th>
                                                        <th scope="col" className="text-right">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        employees
                                                            .filter(item =>
                                                                item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                                item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                                item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                                item.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                                item.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                                item.phone.toLowerCase().includes(searchTerm.toLowerCase())
                                                            )
                                                            .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
                                                            .map((item) => (
                                                                <tr key={item.des} className="white-space-no-wrap">
                                                                    <td className="pr-0 ">
                                                                        <div className="custom-control custom-checkbox custom-control-inline">
                                                                            <input type="checkbox" className="custom-control-input m-0" id="customCheck" />
                                                                            <label className="custom-control-label" htmlFor="customCheck"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td className="">
                                                                        <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                            <div className="h-avatar is-medium">
                                                                                <img className="avatar rounded-circle" alt="user-icon" src={encodeURI(`${API_URL}/uploads/${item.filename}`)} />
                                                                            </div>
                                                                            <div className="data-content">
                                                                                <div>
                                                                                    <span className="font-weight-bold">{item.firstName}  {item.lastName}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item.email}</td>
                                                                    <td>
                                                                        {item.country}
                                                                    </td>
                                                                    <td>
                                                                        {item.state}
                                                                    </td>
                                                                    <td>
                                                                        {item.phone}
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex justify-content-end align-items-center">
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                                <Link className="" to={`/employee-edit/${item._id}`}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                                                    </svg>
                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                                                                <button className="badge bg-danger" onClick={() => handleDelete(item._id)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                    </svg>
                                                                                </button>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))

                                                    }
                                                </tbody>
                                            </table>
                                            <div className="d-flex justify-content-center">
                                                <Pagination className="mb-0">
                                                    <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
                                                    {[...Array(totalPages).keys()].map(pageNumber => (
                                                        <Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === currentPage} onClick={() => setCurrentPage(pageNumber + 1)}>
                                                            {pageNumber + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
                                                </Pagination>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </Container >

        </>

    )
}
export default Employee;